import React, { Component } from 'react';
import { Button, Spinner, Modal, Row, Col, Form, FloatingLabel, InputGroup } from 'react-bootstrap';
import { Formik } from 'formik';
import fetch from 'isomorphic-fetch';
import * as Yup from 'yup';
import Select from 'react-select';
import { withOktaAuth, useOktaAuth } from '@okta/okta-react';

export default  withOktaAuth( class AddCustomerScheduleModal extends Component {

  constructor(props) {
    super(props);
    this.state = {
      show: false,
      close: false,
      saveButtonDisabled: false,
      validated: false,
      setValidated: false,
      submutButtonDisabled: false,
      selectedOption: null,
      customerData: null,
      customerName:null,
    };

    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChange = this.handleChange.bind(this);
    


    this.schema = Yup.object().shape({
      // name: Yup.string().min(2, 'Name must be longer than 1 character').required(),
      // number: Yup.string().min(12, 'Number must be 12 characters with dashes (111-111-1111)')
      //   .max(12, 'Number must be 12 characters with dashes (111-111-1111)')
      //   .matches(/^\d{3}-\d{3}-\d{4}$/, "Must have numbers and dashes. Ex: (111-111-1111)"),
      // address: Yup.string().required(),
      // crew: Yup.string().required("Please Enter a Crew"),
      //date: Yup.string().required(),
      // weekType: Yup.string().required("Please Enter a Week Type"),
      // dayOfWeek: Yup.string().matches(/^\b((monday|Monday|tuesday|Tuesday|wednesday|Wednesday|thursday|Thursday|friday|Friday|saturday|Saturday|sunday|Sunday)(day)?)\b/, 
      // "Must be full name of the day of the week").required("Please Enter a Day of the Week"),
      // monthlyPrice: Yup.number(),
      // notes: Yup.string(),
      // notes: Yup.string().required(),
      // terms: Yup.bool().required().oneOf([true], 'Terms must be accepted'),
    });



  }

  // async componentDidMount() {
  //   getCustomerNames();
  // }

  handleChange = (selectedOption) => {
    this.setState({ selectedOption });
    // this.props.updateCustomerName(selectedOption)
    this.setState({customerName: selectedOption});
  };
  

  handleSubmit = (event) => {
    const form = event.currentTarget;
    // if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
      let customerName = this.state.customerName?.value;

      if(this.state.customerName != null){
        this.submitEstimateTrack(customerName);
      } else{
        alert("Please select a customer to add an estimate for");
      }


    // }

    // setValidated(true);
  };

  async getCustomerNames(){
    const { REACT_APP_SERVER_URL } = process.env;
    //let serverPort = REACT_APP_SERVER_PORT

    try{
      if(this.state.customerData === null){
        const response = await fetch(`${REACT_APP_SERVER_URL}/Tracking/get/Customers`, {
          method: 'GET',
          headers: {
              'Content-Type': 'application/json',
              Authorization: 'Bearer ' + this.props.authState.accessToken.accessToken,
              'data-request': 'read'
          },
        });

        if(response.status === 401){
          throw new Error("Your login time has expired, please log in again");
          this.setState({submitButtonDisabled: false});
        }

        let customerData = await response.json();

        let editedCustomerData = []
        for(let customer of customerData){
          editedCustomerData.push({"label": customer.name, "value": customer.name})
          // editedCustomerData["label"] = customer.name; 
          // editedCustomerData["value"] = customer.name; 
        }
        this.setState({customerData: editedCustomerData})
      }



    } catch (err) {
      // handle error as needed
      alert(err);
    }
  }

  async submitEstimateTrack(customerName) {
    let userInfo = await this.props.oktaAuth.token.getUserInfo();
    this.setState({submutButtonDisabled: true});
    let addCustomerData ={};
    addCustomerData["userName"] = userInfo.name;
    addCustomerData["customerName"] = customerName;
    // addCustomerData["activeOption"] = this.state.customerStatusValue || true;

    try {
      const { REACT_APP_SERVER_URL } = process.env;
      const { REACT_APP_SERVER_PORT } = process.env;
      let serverPort = REACT_APP_SERVER_PORT
      const response = await fetch(`${REACT_APP_SERVER_URL}/Estimates/addCustomers`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + this.props.authState.accessToken.accessToken,
            'data-request': 'read'
        },
        body: JSON.stringify({
            // Validation data coming from a form usually
            customerData: addCustomerData
        }) 
      })
      if(response.status === 401){
        throw new Error("Your login time has expired, please log in again");
      }
      else if(response.status === 200){
        alert(`Customer ${customerName} Added`);
      }
      else if(response.status <= 402){
        alert(`Error adding ${customerName}, error code ${response.status}`)
      }
      let test = await response.json();

      this.setState({submutButtonDisabled: false});
      // window.location.reload(false);
    } catch (error) {
      // handle error as needed
      alert(error);
      this.setState({submutButtonDisabled: false});
    } 
  }


  


  render() {
    const { selectedOption } = this.state;
    return (
      <div>
        <Button
          data-cy={"add-customer-estimates-button"} 
          variant="success"
          onClick={() => {
            this.setState({ show: true }); 
            this.getCustomerNames();
          }}
          //disabled={this.props.submitButtonDisabled}
        >
          Add Customer for Estimates
        </Button>
        <Modal
          show={this.state.show}
          onHide={() => this.setState({ show: false })}
          animation={true}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          className=""
          shadow-lg="border">
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              Add Customer to Track
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>
              <h1>Add Estimate</h1>
              <Formik
                validationSchema={this.schema}
                initialValues={{
                  crew: '',
                  weekType: '',
                  dayOfWeek: ''
                }}
              >
                {({
                  handleSubmit,
                  handleChange,
                  handleBlur,
                  values,
                  touched,
                  isValid,
                  errors,
                }) => (
                  <Form onSubmit={this.handleSubmit}>
                    <Row className="mb-3">
                     <Select
                      data-cy={"add-customer-estimates-select-customer"} 
                      inputId={"add-customer-estimates-select-customer"}
                      classNamePrefix={"react-select"}
                      value={selectedOption}
                      onChange={this.handleChange}
                      options={this.state.customerData}
                      placeholder={"Customer Name"}
                    />
                    </Row>
                    <Button
                      data-cy={"add-customer-estimates-submit-button"}
                      disabled={this.state.submutButtonDisabled}
                      type="submit"
                    >
                      {
                        this.state.submutButtonDisabled ?
                          <div>Submitting
                            <Spinner animation="border" size="sm" ></Spinner>
                          </div> :
                          'Submit Estimate'
                      }
                    </Button>
                  </Form>
                )}
              </Formik>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button 
              data-cy={"add-customer-estimates-close-button"}
              onClick={() => this.setState({ show: false })}>Close</Button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
});
