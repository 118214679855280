import fetch from 'isomorphic-fetch';
import React, { Component } from 'react';
import { withOktaAuth, useOktaAuth } from '@okta/okta-react';
import { Button, Spinner, Container, Row, Col } from 'react-bootstrap'
// import TrackTable from '../Components/TrackTable';
import AddInvoiceModal from '../Components/Modals/AddInvoiceModal';
import {submitInvoice} from '../helpers/Invoices/invoices';
import TableComponent from '../Components/TableComponent';
import invoiceColumns from '../Components/Table/Columns/Invoices';

import { getTableSettings } from '../helpers/Table/tableSettings';

let page;

export default withOktaAuth(class MessageList extends Component {


  constructor(props) {
    
    super(props)
    this.getCleaningCrews = this.getCleaningCrews.bind(this);
    this.editCleaningCrewData = this.editCleaningCrewData.bind(this);
    this.deleteCleaningCrewData = this.deleteCleaningCrewData.bind(this);
    this.updateSaveButton = this.updateSaveButton.bind(this);
    this.updateRowSelected = this.updateRowSelected.bind(this);
    this.showAddCustomerModel = this.showAddCustomerModel.bind(this);
    this.updateCustomerName = this.updateCustomerName.bind(this);

    const { REACT_APP_SERVER_PORT } = process.env;
    this.serverPort = REACT_APP_SERVER_PORT
    
    this.state = {
      messages: null,
      landscapingData: null,
      userInfo: null,
      startDate: '',
      endDate: '',
      userGroups: null,
      showLandscaping: false,
      showCleaningCrew: false,
      landscapingDataIsLoading: false,
      crewDataIsLoading: false,
      submitButtonDisabled: false,
      saveButtonDisabledLoading: false,
      showCustomerModel: true,
      customerName: '',
      crewData: null,
      columnsToHide: ['true'],
    }

    this.userGroups = this.props.authState.accessToken.claims["Add-Groups"];

    this.adminGroup = "Admin";
    this.adminReadGroup = "Admin-Read";
    this.userGroup = "Users"
    this.adminCleanGroup = "Cleaning-Admin";
    this.lawnLeadersGroup = "Lawn-Leader";


    this.showCustomerModel = false;

    this.state = {value: ''};

    this.handleChangeStartDate = this.handleChangeStartDate.bind(this);
    this.handleChangeEndDate = this.handleChangeEndDate.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleChangeStartDate(event) {
  this.setState({startDate: event.target.value});
}

  handleChangeEndDate(event) {
  this.setState({endDate: event.target.value});
  }

  handleSubmit(event) {
    alert(this.state.startDate + ' ' + this.state.endDate);
    event.preventDefault();
  }

  showAddCustomerModel(){
    this.showCustomerModel = !this.showCustomerModel
    this.setState({showAddCustomerModel:this.showCustomerModel});
  }

  async componentDidMount() {
    let tableSettingsUser = 'Invoice Table';
    let tableName = 'invoices';
    let authToken = this.props.authState.accessToken.accessToken;
    let columnsToHide = await getTableSettings(tableName, tableSettingsUser, authToken) || [];
    this.setState({columnsToHide: columnsToHide});

    this.setState({saveButtonDisabled: true});
    this.setState({showAddCustomerModel: false});
    if(this.userGroups.includes("Admin") ||
    this.userGroups.includes(this.adminCleanGroup)){
      
    }
  }


  async getCleaningCrews(){
      this.setState({showCleaningCrew: false});
      this.setState({crewDataIsLoading: true});
      try {
          // const { REACT_APP_SERVER_PORT } = process.env;
          // let serverPort = REACT_APP_SERVER_PORT
          
          let userInfo = await this.props.oktaAuth.token.getUserInfo();
          this.setState({ userInfo: userInfo });
          const { REACT_APP_SERVER_URL } = process.env;
          const response = await fetch(`${REACT_APP_SERVER_URL}/Invoices/getAllInvoices`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + this.props.authState.accessToken.accessToken,
                'data-request': 'read'
            },
            body: JSON.stringify({
                // Validation data coming from a form usually
                startDate: this.state.startDate,
                endDate: this.state.endDate
            }) 
          })

          let invoiceData = await response.json();

          for(let row of invoiceData){
              for(let value in row){

              if((row[value] !== null && row[value] !== "undefined")){
                if(value === 'submission_timestamp'){
                  row[value] = new Date(row[value]).toLocaleDateString("en-US", {timeZone: "America/New_York"}) || "";
                }

              } else {
                row[value] = "";
              }
            }
          }

          this.setState({ crewData: invoiceData });
          this.setState({showCleaningCrew: true});
          this.setState({crewDataIsLoading: false});

        } catch (err) {
          console.log(err);
          alert(err);
          this.setState({crewDataIsLoading: false});
          
        }
  }


  async editCleaningCrewData(editData){
    try {
      const { REACT_APP_SERVER_URL } = process.env;
      const { REACT_APP_SERVER_PORT } = process.env;
      let serverPort = REACT_APP_SERVER_PORT
      let userInfo = await this.props.oktaAuth.token.getUserInfo();
      this.setState({ userInfo: userInfo });
      this.setState({saveButtonDisabledLoading: true});

      const updatedDataArray = editData.map(item => {
        // Check if invoice_number is an empty string, set it to null
        if (item.invoice_number === "") {
          return { ...item, invoice_number: null };
        }
        // If not, leave it unchanged
        return item;
      });


      const response = await fetch(`${REACT_APP_SERVER_URL}/Invoices/editInvoices`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + this.props.authState.accessToken.accessToken,
            'data-request': 'read'
        },
        body: JSON.stringify({
            // Validation data coming from a form usually
            editData: updatedDataArray,
            'user': this.state.userInfo.name
        }) 
      })
      if(response.status === 401){
        throw new Error("Your login time has expired, please log in again");
      }

      if(response.status === 200){
        alert('Data Has Updated');
      }
      if(response.status > 401){
        alert(`error: ${response.statusText}`);
      }
      let test = await response.json();
      this.setState({ messages: test });
      this.setState({saveButtonDisabledLoading: false});
      await this.getCleaningCrews();
    } catch (error) {
      // handle error as needed
      console.log(error);
      alert(error);
      this.setState({saveButtonDisabledLoading: false});
    }
  }

  async deleteCleaningCrewData(editData){
    try {
      const { REACT_APP_SERVER_URL } = process.env;
      const { REACT_APP_SERVER_PORT } = process.env;
      let serverPort = REACT_APP_SERVER_PORT

      let userInfo = await this.props.oktaAuth.token.getUserInfo();
      this.setState({ userInfo: userInfo });
      this.setState({saveButtonDisabledLoading: true});
      const response = await fetch(`${REACT_APP_SERVER_URL}/Invoices/deleteInvoices`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + this.props.authState.accessToken.accessToken,
            'data-request': 'read'
        },
        body: JSON.stringify({
            // Validation data coming from a form usually
            editData: editData,
            'user': this.state.userInfo.name
        }) 
      })
      if(response.status === 401){
        throw new Error("Your login time has expired, please log in again");
      }

      if(response.status === 204){
        alert('Data was Deleted');
      }
      if(response.status > 401){
        alert(`error: ${response.statusText}`);
      }
      this.setState({saveButtonDisabledLoading: false});
      await this.getCleaningCrews();

    } catch (error) {
      // handle error as needed
      console.log(error);
      alert(error);
      this.setState({saveButtonDisabledLoading: false});
    }
  }


  updateSaveButton(buttonState){
    let allowedGroups = 
      (this.userGroups.includes("Admin") || 
      this.userGroups.includes(this.adminCleanGroup) ||
      this.userGroups.includes(this.lawnLeadersGroup));
    if(allowedGroups){
      this.setState({saveButtonDisabled:buttonState});
    }

  }

  updateCustomerName(customerName){
    this.setState({customerName: customerName.value});
  }


  updateRowSelected(isRowSelected){
    this.setState({rowSelected:isRowSelected})
  }

  render() {
    let content;
    if (!(typeof this.state.crewData === 'undefined') && (this.state.showCleaningCrew)){ 
        content = 
                  <TableComponent 
                    tableColumns = {invoiceColumns}
                    tableData = {this.state.crewData}
                    saveButtonDisabled={this.state.saveButtonDisabled}
                    saveButtonDisabledLoading={this.state.saveButtonDisabledLoading}
                    rowSelected={this.state.rowSelected}
                    editData={this.editCleaningCrewData}
                    deleteData={this.deleteCleaningCrewData}
                    updateSaveButton={this.updateSaveButton}
                    columnsToHide={this.state.columnsToHide}
                    showLegend={false}
                    printFilename={`Invoice_Report_${this.state.startDate}_${this.state.endDate}`}
                  />;
    } 

    return <div>
          <h1 
            data-cy="invoices-reports-title"
          >
            Invoices 
          </h1>
        { (this.userGroups.includes(this.adminGroup) || 
          this.userGroups.includes(this.adminReadGroup) || 
          this.userGroups.includes(this.adminCleanGroup) ||
          this.userGroups.includes(this.lawnLeadersGroup)) &&
        <div>

            <form onSubmit={this.handleSubmit}>
              <label style = {{ "marginBottom": "20px", "fontWeight": "bold"}}>
              Start Date:
              </label>
              <input 
                data-cy={"invoice-start-date"}
                type="date" 
                startdate={this.state.startDate} 
                onChange={this.handleChangeStartDate}
                style = {{"marginRight": "20px"}}
              />
              <label style = {{ "fontWeight": "bold"}}>
              End Date:
              </label>
              <input 
                data-cy={"invoice-end-date"}
                type="date" 
                enddate={this.state.endDate} 
                onChange={this.handleChangeEndDate}
              />
          </form>
          { (this.userGroups.includes(this.adminGroup) ||
             this.userGroups.includes(this.adminCleanGroup) ||
            this.userGroups.includes(this.lawnLeadersGroup)
           ) &&
          <Button 
            data-cy="get-invoices-data-button"
            variant="success" 
            onClick={this.getCleaningCrews}  
            style = {{"marginRight": "20px", "marginBottom": "20px"}}
            disabled={this.state.crewDataIsLoading}
            >
            {
              this.state.crewDataIsLoading ? 
              <div>Loading 
                <Spinner animation="border" size="sm" ></Spinner>
              </div> : 
              'Get Invoice Data'
            }
          </Button>
          }
      </div>
        }
      { ((this.userGroups.includes(this.adminGroup) || 
          this.userGroups.includes(this.adminCleanGroup) ||
          this.userGroups.includes(this.lawnLeadersGroup)) ) &&
        <AddInvoiceModal
          onHide={this.showAddCustomerModel}
          updateCustomerName = {this.updateCustomerName}
          getCustomerNames = {this.getCustomerNames}
          show={this.state.showAddCustomerModel}
          userGroups={this.userGroups}
          userInfo={this.state.userInfo}
          customerName = {this.state.customerName}
          crewData = {this.state.crewData}
          submitButtonDisabled = {this.state.submitButtonDisabled}
          buttonName = {"Add Invoice"}
          variant = {"secondary"}
          submitInvoice = {submitInvoice}
          cleanType =  {"Power Washing"}
        />
    }
        {content}
    </div>  
  }
});