import React, { Component } from 'react';
import { Button, Spinner, Modal, Row, Col, Form, FloatingLabel, InputGroup } from 'react-bootstrap';
import { Formik, Field } from 'formik';
import fetch from 'isomorphic-fetch';
import * as Yup from 'yup';
import Select from 'react-select';
import { withOktaAuth, useOktaAuth } from '@okta/okta-react';

export default withOktaAuth( class AddCleaningCrewModal extends Component {

  constructor(props) {
    super(props);
    this.state = {
      show: false,
      close: false,
      submitButtonDisabled: false,
      saveButtonDisabled: false,
      validated: false,
      setValidated: false,
      addButtonDisabled: false,
      selectedCommunityNameOption: null,
      selectedCleanNumberOption: null,
      selectedCrewOption: null,
      selectedStopNumberOption: null,
    };

    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleCommunityChange = this.handleCommunityChange.bind(this);
    this.handleCleanNumberSelectChange = this.handleCleanNumberSelectChange.bind(this);
    this.handleStopNumberSelectChange = this.handleStopNumberSelectChange.bind(this);
    this.handleCrewChange = this.handleCrewChange.bind(this);
    this.handleStopNumberSelectChange = this.handleStopNumberSelectChange.bind(this);

    this.schema = Yup.object().shape({
      crewName: Yup.string().min(2, 'Name must be longer than 1 character').required(),
      // lotNumber: Yup.number().typeError('Lot must be a number').nullable().required("Please Enter a Lot Number"),
      // stopNumber: Yup.number().typeError('Stop Number must be a Number').nullable(),//.required("Please Enter a Stop Number"),
      // invoiceNumber: Yup.string().nullable(),//.typeError('Invoice Number must be a Number'),//.required("Please Enter a Stop Number"),
      payRate: Yup.number().typeError('Pay Rate must be a Number').nullable(),//.required("Please Enter the Job Revanue"),
    });



  }

  handleCommunityChange = (selectedCommunity) => {
    this.setState({ selectedCommunity});
    // this.props.updateCustomerName(selectedOption)
    this.setState({selectedCommunityNameOption: selectedCommunity.value});
  };

  handleCrewChange = (selectedCrew) => {
    this.setState({ selectedCrew });
    //change this to updateComminityName
    this.setState({selectedCrewOption: selectedCrew.value});
  };

  handleCleanNumberSelectChange = (selectedCleanNumber) => {
    this.setState({ selectedCleanNumber });
    // this.props.updateCustomerName(selectedOption)
    this.setState({selectedCleanNumberOption: selectedCleanNumber.value});
  };

  handleStopNumberSelectChange = (selectedStopNumber) => {
    this.setState({ selectedStopNumber });
    // this.props.updateCustomerName(selectedOption)
    this.setState({selectedStopNumberOption: selectedStopNumber.value});
  };
  
  handleSubmit = async (formData, resetForm) => {
    this.setState({submitButtonDisabled: true});

    let userInfo =  await this.props.oktaAuth.token.getUserInfo();

    let userName = userInfo.name;
    formData = this.replaceEmptyStringWithNull(formData);

    let accessToken = this.props.authState.accessToken.accessToken;

    await this.props.submitCleaningCrew(formData, resetForm, this.resetState, userName, accessToken);
      this.setState({submitButtonDisabled: false});
  };

  replaceEmptyStringWithNull(obj) {
    for (const key in obj) {
      if (obj.hasOwnProperty(key) && typeof obj[key] === 'string' && obj[key].trim() === '') {
        obj[key] = null;
      }
    }
    return obj;
  }

  resetState = () => {
    this.setState({selectedCommunity: ''});
    this.setState({selectedCleanNumber: ''})
    this.setState({selectedCrew: ''})
    this.setState({selectedStopNumber: ''});
  }


  render() {
    const { selectedOption } = this.state;
    const { selectedCrew } = this.state;
    const { selectedCleanNumber } = this.state;
    const { selectedCommunity } = this.state;
    const {selectedStopNumber} = this.state;
    
    return (
      <div>
        <Button
          data-cy={"add-crew-button"}
          variant={this.props.variant} 
          onClick={() => {
            this.setState({ show: true }); 
            // this.props.getCustomerNames();
          }}
          //disabled={this.props.submitButtonDisabled}
        >
          { this.props.buttonName}
        </Button>
        <Modal
          show={this.state.show}
          onHide={() => this.setState({ show: false })}
          animation={true}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          className=""
          shadow-lg="border">
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              Add Crew
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>
              <h1>Add Crew</h1>
              <Formik
                validationSchema={this.schema}
                initialValues={{
                  crewName: '',
                  payRate: '',
                  active: false,
                  notes: '',
                  startDate: '',
                }}
                onSubmit={(values, { resetForm }) =>{
                  this.handleSubmit(values, resetForm);
                }

                }
              >
                {({
                  handleSubmit,
                  handleChange,
                  handleBlur,
                  resetForm,
                  values,
                  touched,
                  isValid,
                  errors,
                }) => (
                  <Form onSubmit={handleSubmit}>
                    <Row className="mb-3">
                      <Form.Group as={Col} controlId="dob" md="4">
                        <Form.Label>Crew Name </Form.Label>
                        <Form.Control 
                          data-cy={"crew-modal-crew-name"}
                          type="text" 
                          name="crewName" 
                          value={values.crewName}
                          onChange={handleChange}
                          isValid={touched.crewName && !errors.crewName}
                          isInvalid={touched.crewName && !!errors.crewName}
                          placeholder="Name" 
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.crewName}
                        </Form.Control.Feedback>
                        <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                      </Form.Group>
                    </Row>
                    <Row className="mb-3">
                      <Form.Group as={Col} md="3" controlId="validationFormik04">
                      <br/>
                        <Form.Label>Pay Rate</Form.Label>
                        <Form.Control
                          data-cy={"crew-modal-pay-rate"}
                          type="text"
                          placeholder="Pay Rate"
                          name="payRate"
                          value={values.payRate}
                          onChange={handleChange}
                          isValid={touched.payRate && !errors.payRate}
                          isInvalid={touched.payRate && !!errors.payRate}
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.payRate}
                        </Form.Control.Feedback>
                        <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                      </Form.Group>
                    </Row>
                    <Row className="mb-3">
                      <Form.Group as={Col} controlId="active" md="4"> 
                        <Form.Label>Active</Form.Label> 
                        <Form.Check
                          data-cy={"crew-modal-active"}
                          label="Active"
                          type="checkbox"
                          value={values.active}
                          onChange={handleChange}
                          isInvalid={!!errors.active}
                        />
                      </Form.Group>
                    </Row> 
                    <Row className="mb-3">
                    <Form.Group as={Col} controlId="dob" md="4">
                        <Form.Label>Start Date</Form.Label>
                        <Form.Control
                          data-cy={"crew-modal-start-date"}
                          type="date"
                          name="startDate"
                          placeholder="Start Date"
                          value={values.startDate}
                          onChange={handleChange}
                          isValid={touched.startDate && !errors.startDate}
                          isInvalid={touched.startDate && !!errors.startDate}
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.startDate}
                        </Form.Control.Feedback>
                        <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                      </Form.Group>
                  </Row>
                    <Row className="mb-3">
                      <Form.Group as={Col} md="6" controlId="validationFormik06">
                      <Form.Label>Notes</Form.Label>
                      <Form.Control
                        data-cy={"crew-modal-notes"}
                        type="textarea"
                        style={{ height: '100px' }}
                        placeholder="Notes Here"
                        name="notes"
                        value={values.notes}
                        onChange={handleChange}
                        isValid={touched.notes && !errors.notes}
                      />
                      <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                  </Form.Group>
                    </Row>
                    
                    <Button
                      data-cy={"crew-modal-submit-button"}
                      onClick={() =>{
                      }}
                      disabled={this.state.submitButtonDisabled}
                      type="submit"
                    >
                      {
                        this.state.submitButtonDisabled ?
                          <div>Submitting
                            <Spinner animation="border" size="sm" ></Spinner>
                          </div> :
                          'Submit form'
                      }
                    </Button>
                  </Form>
                )}
              </Formik>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button 
              data-cy={"crew-modal-close-button"}
              onClick={() => this.setState({ show: false })}
            >
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
})
