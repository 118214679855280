import fetch from 'isomorphic-fetch';
import React, { Component } from 'react';
import {Prompt} from 'react-router';
import { withOktaAuth, useOktaAuth } from '@okta/okta-react';
import { Button, Spinner } from 'react-bootstrap'
import DataTable from './Components/Table';
import LandscapingDataTable from './Components/LandscapingTable';
import TableComponent from './Components/TableComponent';
import {makeData} from './Components/makeData';
import turfMaintenanceColumns from './Components/Table/Columns/TurfMaintenance';
import landscapingColumns from './Components/Table/Columns/Landscaping';
import turfTreatmentColumns from './Components/Table/Columns/TurfTreatment'

let page;

export default withOktaAuth(class MessageList extends Component {


  constructor(props) {
    
    super(props)
    this.getAllTurfMaintenanceData = this.getAllTurfMaintenanceData.bind(this);
    this.getAllTurfMaintenanceDataByUser = this.getAllTurfMaintenanceDataByUser.bind(this);
    this.getAllLandscapingData = this.getAllLandscapingData.bind(this);
    this.getAllLandscapingDataByUser = this.getAllLandscapingDataByUser.bind(this);
    this.getAllTurfTreatmentData = this.getAllTurfTreatmentData.bind(this);
    this.editTurfMaintenanceData = this.editTurfMaintenanceData.bind(this);
    this.deleteTurfMaintenanceData = this.deleteTurfMaintenanceData.bind(this);
    this.editLandscapingData = this.editLandscapingData.bind(this);
    this.deleteLandscapingData = this.deleteLandscapingData.bind(this);
    this.editTurfTreatmentData = this.editTurfTreatmentData.bind(this);
    this.deleteTurfTreatmentData = this.deleteTurfTreatmentData.bind(this);
    this.updateSaveButton = this.updateSaveButton.bind(this);
    this.updateRowSelected = this.updateRowSelected.bind(this);
    this.handleChangeStartDate = this.handleChangeStartDate.bind(this);
    this.handleChangeEndDate = this.handleChangeEndDate.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleBlockNavigation = this.handleBlockNavigation.bind(this);

    const { REACT_APP_SERVER_PORT } = process.env;
    this.serverPort = REACT_APP_SERVER_PORT
    
    this.state = {
      messages: null,
      turfMaintenaceData: null,
      landscapingData: null,
      userInfo: null,
      startDate: '',
      endDate: '',
      userGroups: null,
      showLandscaping: false,
      showTurfMaintenance: true,
      showTurfTreatment: true,
      landscapingDataIsLoading: false,
      turfMaintenanceDataIsLoading: false,
      turfTreatmentDataIsLoading: false,
      saveButtonDisabled: true,
      saveButtonDisabledLoading: false,
      confirmDelete:false,
    }

    this.userGroups = this.props.authState.accessToken.claims["Add-Groups"];

    this.adminGroup = "Admin";
    this.adminReadGroup = "Admin-Read";
    this.userGroup = "Users";
    this.lawnLeadersGroup = "Lawn-Leader";

    this.state = {value: ''};

    this.readDataRequest = "read";
    this.writeDataRequest = "write";
    this.editDataRequest = "edit";
    this.deleteDataRequest = "delete";


  }

  handleChangeStartDate(event) {
  this.setState({startDate: event.target.value});
}

  handleChangeEndDate(event) {
  this.setState({endDate: event.target.value});
  }

  handleSubmit(event) {
    alert(this.state.startDate + ' ' + this.state.endDate);
    event.preventDefault();
  }

  handleBlockNavigation(shouldBlock){
    this.setState({shouldBlockNavigation: shouldBlock})
  }

  async componentDidMount() {
    this.setState({saveButtonDisabled: true});
  }

  async getAllTurfMaintenanceData(){
    if((this.state.endDate !== undefined) && (this.state.startDate !== undefined)){
      this.setState({showTurfMaintenance: false});
      this.setState({turfMaintenanceDataIsLoading: true});
      try {
          // const { REACT_APP_SERVER_PORT } = process.env;
          // let serverPort = REACT_APP_SERVER_PORT
          
          let userInfo = await this.props.oktaAuth.token.getUserInfo();
          this.setState({ userInfo: userInfo });
          const { REACT_APP_SERVER_URL } = process.env;
          const response = await fetch(`${REACT_APP_SERVER_URL}/TurfMaintenance/`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + this.props.authState.accessToken.accessToken,
                'data-request': this.readDataRequest
            },
            body: JSON.stringify({
                // Validation data coming from a form usually
                startDate: this.state.startDate,
                endDate: this.state.endDate
            }) 
          })

          let getResponse = await response.json();

          this.setState({turfMaintenaceData: getResponse });
          this.setState({showTurfTreatment: false});
          this.setState({showLandscaping: false});
          this.setState({showTurfMaintenance: true});

          this.setState({turfMaintenanceDataIsLoading: false});

        } catch (err) {
          console.log(err);
          alert(err);
          this.setState({turfMaintenanceDataIsLoading: false});
          
        }
    } else{
      alert("Please Select a Start and End Date");
    }

  }

  async getAllTurfMaintenanceDataByUser(){
    if(((this.state.endDate !== undefined) && (this.state.startDate !== undefined))){
      this.setState({showTurfMaintenance: false});
      this.setState({turfMaintenanceDataIsLoading: true});
      try {
          //let serverPort = 3000;
          const { REACT_APP_SERVER_URL } = process.env;
          let userInfo = await this.props.oktaAuth.token.getUserInfo();
          this.setState({ userInfo: userInfo });

          const response = await fetch(`${REACT_APP_SERVER_URL}/TurfMaintenance/byUser`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + this.props.authState.accessToken.accessToken,
                'data-request': this.readDataRequest,
            },
            body: JSON.stringify({
                // Validation data coming from a form usually
                startDate: this.state.startDate,
                endDate: this.state.endDate,
                'user': this.state.userInfo.name
            }) 
          })

          let test = await response.json();
          this.setState({ turfMaintenaceData: test });
          this.setState({showTurfMaintenance: true});
          this.setState({showLandscaping: false});
          this.setState({turfMaintenanceDataIsLoading: false});
        //   this.setState({ messages: data.messages });
        } catch (err) {
          // handle error as needed
          console.log(err);
          this.setState({turfMaintenanceDataIsLoading: false});
        }
    } else{
      alert("Please Select a Start and End Date");
    }
  }

  async getAllLandscapingData(){
    if((this.state.endDate !== undefined) && (this.state.startDate !== undefined)){
      this.setState({showLandscaping: false});
      this.setState({landscapingDataIsLoading: true});
      try {
          //let serverPort = 3000;
          let userInfo = await this.props.oktaAuth.token.getUserInfo();
          this.setState({ userInfo: userInfo });
          const { REACT_APP_SERVER_URL } = process.env;
          const response = await fetch(`${REACT_APP_SERVER_URL}/Landscaping/`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + this.props.authState.accessToken.accessToken,
                'data-request': this.readDataRequest
            },
            body: JSON.stringify({
                // Validation data coming from a form usually
                startDate: this.state.startDate,
                endDate: this.state.endDate
            }) 
          })

          let test = await response.json();
          this.setState({ landscapingData: test });
          this.setState({showTurfMaintenance: false});
          this.setState({showTurfTreatment: false});
          this.setState({showLandscaping: true});
          this.setState({landscapingDataIsLoading: false})
          
        } catch (err) {
          console.log(err);
          this.setState({landscapingDataIsLoading: false})
        }
    } else{
      alert("Please Select a Start and End Date");
    }

  }

  async getAllLandscapingDataByUser(){
    if(((this.state.endDate !== undefined) && (this.state.startDate !== undefined))){
      this.setState({showLandscaping: false});
      this.setState({landscapingDataIsLoading: true});
      try {
          //let serverPort = 3000;
          let userInfo = await this.props.oktaAuth.token.getUserInfo();
          this.setState({ userInfo: userInfo });
          const { REACT_APP_SERVER_URL } = process.env;

          const response = await fetch(`${REACT_APP_SERVER_URL}/Landscaping/byUser`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + this.props.authState.accessToken.accessToken,
                'data-request': this.readDataRequest,
            },
            body: JSON.stringify({
                // Validation data coming from a form usually
                startDate: this.state.startDate,
                endDate: this.state.endDate,
                'user': this.state.userInfo.name
            }) 
          })

          let test = await response.json();
          this.setState({ landscapingData: test });
          this.setState({showTurfMaintenance: false});
          this.setState({showLandscaping: true});
          this.setState({landscapingDataIsLoading: false});
        } catch (err) {
          console.log(err);
          alert(err);
          this.setState({landscapingDataIsLoading: false});
        }
    } else{
      alert("Please Select a Start and End Date");
    }
  }

  async getAllTurfTreatmentData(){
    if((this.state.endDate !== undefined) && (this.state.startDate !== undefined)){
      this.setState({showTurfTreatment: false});
      this.setState({turfTreatmentDataIsLoading: true});
      try {
          // const { REACT_APP_SERVER_PORT } = process.env;
          // let serverPort = REACT_APP_SERVER_PORT
          
          let userInfo = await this.props.oktaAuth.token.getUserInfo();
          this.setState({ userInfo: userInfo });
          const { REACT_APP_SERVER_URL } = process.env;
          const response = await fetch(`${REACT_APP_SERVER_URL}/TurfTreatment/`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + this.props.authState.accessToken.accessToken,
                'data-request': this.readDataRequest
            },
            body: JSON.stringify({
                // Validation data coming from a form usually
                startDate: this.state.startDate,
                endDate: this.state.endDate
            }) 
          })

          let getResponse = await response.json();

          this.setState({turfTreatmentData: getResponse });
          this.setState({showLandscaping: false});
          this.setState({showTurfMaintenance: false});
          this.setState({showTurfTreatment: true});
          this.setState({turfTreatmentDataIsLoading: false});

        } catch (err) {
          console.log(err);
          alert(err);
          this.setState({turfTreatmentDataIsLoading: false});
          
        }
    } else{
      alert("Please Select a Start and End Date");
    }

  }

  async editTurfMaintenanceData(editData){
    try {
      const { REACT_APP_SERVER_URL } = process.env;
      const { REACT_APP_SERVER_PORT } = process.env;
      let serverPort = REACT_APP_SERVER_PORT
      let userInfo = await this.props.oktaAuth.token.getUserInfo();
      this.setState({ userInfo: userInfo });
      this.setState({saveButtonDisabledLoading: true});
      

      const response = await fetch(`${REACT_APP_SERVER_URL}/TurfMaintenance/editJobs`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + this.props.authState.accessToken.accessToken,
            'data-request': this.readDataRequest
        },
        body: JSON.stringify({
            // Validation data coming from a form usually
            editData: editData,
            'user': this.state.userInfo.name
        }) 
      })
      if(response.status === 401){
        throw new Error("Your login time has expired, please log in again");
      }

      if(response.status === 200){
        alert('Data Has Updated');
      }
      if(response.status > 401){
        console.log(response);
        alert(`error: ${response.statusText}`);
      }

      await this.getAllTurfMaintenanceData();
      this.setState({saveButtonDisabledLoading: false});
    } catch (error) {
      // handle error as needed
      console.log(error);
      alert(error);
      this.setState({saveButtonDisabledLoading: false});
    }
  }

  async editLandscapingData(editData){
    try {
      const { REACT_APP_SERVER_URL } = process.env;
      const { REACT_APP_SERVER_PORT } = process.env;
      let serverPort = REACT_APP_SERVER_PORT
      let userInfo = await this.props.oktaAuth.token.getUserInfo();
      this.setState({ userInfo: userInfo });
      this.setState({saveButtonDisabledLoading: true});

      const response = await fetch(`${REACT_APP_SERVER_URL}/Landscaping/editJobs`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + this.props.authState.accessToken.accessToken,
            'data-request': this.editDataRequest
        },
        body: JSON.stringify({
            // Validation data coming from a form usually
            editData: editData
        }) 
      })
      if(response.status === 401){
        throw new Error("Your login time has expired, please log in again");
      }

      if(response.status === 200){
        alert('Data Has Updated');
      }
      if(response.status > 401){
        console.log(response);
        alert(`error: ${response.statusText}`);
      }
      
      await this.getAllLandscapingData();

      this.setState({saveButtonDisabledLoading: false});
    } catch (error) {
      // handle error as needed
      console.log(error);
      alert(error);
      this.setState({saveButtonDisabledLoading: false});
    }
  }

  async editTurfTreatmentData(editData){
    try {
      const { REACT_APP_SERVER_URL } = process.env;
      const { REACT_APP_SERVER_PORT } = process.env;
      let serverPort = REACT_APP_SERVER_PORT
      let userInfo = await this.props.oktaAuth.token.getUserInfo();
      this.setState({ userInfo: userInfo });
      this.setState({saveButtonDisabledLoading: true});
      

      const response = await fetch(`${REACT_APP_SERVER_URL}/TurfTreatment/editJobs`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + this.props.authState.accessToken.accessToken,
            'data-request': this.readDataRequest
        },
        body: JSON.stringify({
            // Validation data coming from a form usually
            editData: editData,
            'user': this.state.userInfo.name
        }) 
      })
      if(response.status === 401){
        throw new Error("Your login time has expired, please log in again");
      }

      if(response.status === 200){
        alert('Data Has Updated');
      }
      if(response.status > 401){
        console.log(response);
        alert(`error: ${response.statusText}`);
      }

      await this.getAllTurfTreatmentData();
      this.setState({saveButtonDisabledLoading: false});
    } catch (error) {
      // handle error as needed
      console.log(error);
      alert(error);
      this.setState({saveButtonDisabledLoading: false});
    }
  }

  updateSaveButton(buttonState){
    if(this.userGroups.includes(this.adminGroup) ||
        this.userGroups.includes(this.lawnLeadersGroup)) {
      this.setState({saveButtonDisabled:buttonState});
    }

  }

  updateRowSelected(isRowSelected){
    this.setState({rowSelected:isRowSelected})
  }

  async deleteTurfMaintenanceData(editData){
    try {
      const { REACT_APP_SERVER_URL } = process.env;
      const { REACT_APP_SERVER_PORT } = process.env;
      let serverPort = REACT_APP_SERVER_PORT


      let userInfo = await this.props.oktaAuth.token.getUserInfo();
      this.setState({ userInfo: userInfo });
      this.setState({saveButtonDisabledLoading: true});
      const response = await fetch(`${REACT_APP_SERVER_URL}/TurfMaintenance/deleteJobs`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + this.props.authState.accessToken.accessToken,
            'data-request': this.deleteDataRequest
        },
        body: JSON.stringify({
            // Validation data coming from a form usually
            editData: editData,
            'user': this.state.userInfo.name
        }) 
      })
      if(response.status === 401){
        throw new Error("Your login time has expired, please log in again");
      }

      if(response.status === 200){
        alert('Data was Deleted');
      }
      if(response.status > 401){
        alert(`error: ${response.statusText}`);
      }

      this.setState({saveButtonDisabledLoading: false});
      await this.getAllTurfMaintenanceData();
      

    } catch (error) {
      // handle error as needed
      console.log(error);
      alert(error);
      this.setState({saveButtonDisabledLoading: false});
    }
  }

  async deleteLandscapingData(editData){
    try {
      const { REACT_APP_SERVER_URL } = process.env;
      const { REACT_APP_SERVER_PORT } = process.env;
      let serverPort = REACT_APP_SERVER_PORT


      let userInfo = await this.props.oktaAuth.token.getUserInfo();
      this.setState({ userInfo: userInfo });
      this.setState({saveButtonDisabledLoading: true});
      const response = await fetch(`${REACT_APP_SERVER_URL}/Landscaping/deleteJobs`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + this.props.authState.accessToken.accessToken,
            'data-request': this.deleteDataRequest
        },
        body: JSON.stringify({
            // Validation data coming from a form usually
            editData: editData,
            'user': this.state.userInfo.name
        }) 
      })
      if(response.status === 401){
        throw new Error("Your login time has expired, please log in again");
      }

      if(response.status === 200){
        alert('Data was Deleted');
      }
      if(response.status > 401){
        console.log(response);
        alert(`error: ${response.statusText}`);
      }

      await this.getAllLandscapingData();
      this.setState({saveButtonDisabledLoading: false});
      

    } catch (error) {
      // handle error as needed
      console.log(error);
      alert(error);
      this.setState({saveButtonDisabledLoading: false});
    }
  }

  async deleteTurfTreatmentData(editData){
    try {
      const { REACT_APP_SERVER_URL } = process.env;
      const { REACT_APP_SERVER_PORT } = process.env;
      let serverPort = REACT_APP_SERVER_PORT


      let userInfo = await this.props.oktaAuth.token.getUserInfo();
      this.setState({ userInfo: userInfo });
      this.setState({saveButtonDisabledLoading: true});
      const response = await fetch(`${REACT_APP_SERVER_URL}/TurfTreatment/deleteJobs`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + this.props.authState.accessToken.accessToken,
            'data-request': this.deleteDataRequest
        },
        body: JSON.stringify({
            // Validation data coming from a form usually
            editData: editData,
            'user': this.state.userInfo.name
        }) 
      })
      if(response.status === 401){
        throw new Error("Your login time has expired, please log in again");
      }

      if(response.status === 200){
        alert('Data was Deleted');
      }
      if(response.status > 401){
        console.log(response);
        alert(`error: ${response.statusText}`);
      }

      await this.getAllTurfTreatmentData();
      this.setState({saveButtonDisabledLoading: false});
      

    } catch (error) {
      // handle error as needed
      console.log(error);
      alert(error);
      this.setState({saveButtonDisabledLoading: false});
    }
  }

  render() {
    let content;
    if (!(typeof this.state.turfMaintenaceData === 'undefined') && (this.state.showTurfMaintenance)){ 
      content = 
      <TableComponent
        tableColumns = {turfMaintenanceColumns}
        tableData = {this.state.turfMaintenaceData}
        saveButtonDisabled={this.state.saveButtonDisabled}
        saveButtonDisabledLoading={this.state.saveButtonDisabledLoading}
        rowSelected={this.state.rowSelected}
        editData={this.editTurfMaintenanceData}
        deleteData={this.deleteTurfMaintenanceData}
        updateSaveButton={this.updateSaveButton}
        // columnsToHide={this.state.columnsToHide}
        showLegend={false}
        cumulativeRow={false}
        printFilename={`Turf_Maintenance_Report_${this.state.startDate}_${this.state.endDate}`}
      />
    } 
    else if(!(typeof this.state.landscapingData === 'undefined') && (this.state.showLandscaping)){
      content = 
      <TableComponent
        tableColumns = {landscapingColumns}
        tableData = {this.state.landscapingData}
        saveButtonDisabled={this.state.saveButtonDisabled}
        saveButtonDisabledLoading={this.state.saveButtonDisabledLoading}
        rowSelected={this.state.rowSelected}
        editData={this.editLandscapingData}
        deleteData={this.deleteLandscapingData}
        updateSaveButton={this.updateSaveButton}
        // columnsToHide={this.state.columnsToHide}
        showLegend={false}
        cumulativeRow={false}
        printFilename={`Landscaping_Report_${this.state.startDate}_${this.state.endDate}`}
      />
    }

    else if(!(typeof this.state.turfTreatmentData === 'undefined') && (this.state.showTurfTreatment)){
      content = 
      <TableComponent
        tableColumns = {turfTreatmentColumns}
        tableData = {this.state.turfTreatmentData}
        saveButtonDisabled={this.state.saveButtonDisabled}
        saveButtonDisabledLoading={this.state.saveButtonDisabledLoading}
        rowSelected={this.state.rowSelected}
        editData={this.editTurfTreatmentData}
        deleteData={this.deleteTurfTreatmentData}
        updateSaveButton={this.updateSaveButton}
        // columnsToHide={this.state.columnsToHide}
        showLegend={false}
        cumulativeRow={false}
        printFilename={`Turf_Treatment_Report_${this.state.startDate}_${this.state.endDate}`}
      />
    }
    else{
        //content = <p>Select Dates Above</p>
    }
    return <div>
          <h1 data-cy={"turf-landscaping-schedule-title"}>Lawn and Landscaping Work</h1>
          <Prompt
            when={!this.state.saveButtonDisabled}
            message='Are you sure you want to leave the page? You may have unsaved changes'
          />

          <form onSubmit={this.handleSubmit}>
            <label style = {{ "marginBottom": "20px", "fontWeight": "bold"}}>
            Start Date:
            </label>
            <input type="date" startdate={this.state.startDate} onChange={this.handleChangeStartDate}
             style = {{"marginRight": "20px"}}/>
            <label style = {{ "fontWeight": "bold"}}>
            End Date:
            </label>
            <input type="date" enddate={this.state.endDate} onChange={this.handleChangeEndDate}/>
        </form>
        { (this.userGroups.includes(this.adminGroup) || 
          this.userGroups.includes(this.adminReadGroup) ||
          this.userGroups.includes(this.lawnLeadersGroup)) &&
        <Button 
          variant="success" 
          onClick={this.getAllTurfMaintenanceData}  
          style = {{"marginRight": "20px", "marginBottom": "20px"}}
          disabled={this.state.turfMaintenanceDataIsLoading}
        >
          {
            this.state.turfMaintenanceDataIsLoading ? 
            <div>Loading 
              <Spinner animation="border" size="sm" ></Spinner>
            </div> : 
            'Get Lawn Maintaince Data'
          }
        </Button>
        }
        { (this.userGroups.includes(this.adminGroup) || 
          this.userGroups.includes(this.adminReadGroup)) &&
        <Button 
          variant="secondary"  
          onClick={this.getAllLandscapingData} 
          style = {{"marginRight": "20px", "marginBottom": "20px"}}
          disabled={this.state.landscapingDataIsLoading}
        >
        {
          this.state.landscapingDataIsLoading ? 
          <div>Loading 
            <Spinner animation="border" size="sm" ></Spinner>
          </div> : 
          'Get Landscaping Data'
        }
        </Button>
        }
        { (this.userGroups.includes(this.adminGroup) || 
          this.userGroups.includes(this.adminReadGroup)) &&
        <Button 
          variant="success"  
          onClick={this.getAllTurfTreatmentData} 
          style = {{"marginBottom": "20px"}}
          disabled={this.state.turfTreatmentDataIsLoading}
        >
        {
          this.state.turfTreatmentDataIsLoading ? 
          <div>Loading 
            <Spinner animation="border" size="sm" ></Spinner>
          </div> : 
          'Get Turf Treatment Data'
        }
        </Button>
        }
        { this.userGroups.includes(this.userGroup) &&
        <Button 
          variant="success" 
          onClick={this.getAllTurfMaintenanceDataByUser}  
          style = {{"marginRight": "20px", "marginBottom": "20px"}}
          disabled={this.state.turfMaintenanceDataIsLoading}
        >
            Get Turf Maintaince Data
        </Button>
        }

        { this.userGroups.includes(this.userGroup) &&
        <Button 
          variant="secondary" 
          onClick={this.getAllLandscapingDataByUser} 
          style = {{"marginBottom": "20px"}}
          disabled={this.state.landscapingDataIsLoading}
        >
        {
          this.state.landscapingDataIsLoading ? 
          <div>Loading 
            <Spinner animation="border" size="sm" ></Spinner>
          </div> : 
          'Get Landscaping Data'
        }
        </Button>
        }
        {content}
    </div>  
  }
});