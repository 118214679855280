import fetch from 'isomorphic-fetch';
import React, { Component } from 'react';
import {Prompt} from 'react-router';
import { withOktaAuth, useOktaAuth } from '@okta/okta-react';
import { Button, Spinner } from 'react-bootstrap'
import TrackTable from './Components/TrackTable';
import LandscapingDataTable from './Components/LandscapingTable';
import AddCustomerScheduleModal from "./Components/AddCustomerScheduleModal";
import {makeData} from './Components/makeData';

let page;

export default withOktaAuth(class MessageList extends Component {


  constructor(props) {
    
    super(props)
    this.getAllTrackingDataByDate = this.getAllTrackingDataByDate.bind(this);
    this.editTurfMaintenanceTrackingData = this.editTurfMaintenanceTrackingData.bind(this);
    this.deleteTurfMaintenanceTrackingData = this.deleteTurfMaintenanceTrackingData.bind(this);
    this.updateSaveButton = this.updateSaveButton.bind(this);
    this.updateRowSelected = this.updateRowSelected.bind(this);
    this.showAddCustomerModel = this.showAddCustomerModel.bind(this);
    this.submitCustomerTrackingData = this.submitCustomerTrackingData.bind(this);
    this.updateCustomerName = this.updateCustomerName.bind(this);

    const { REACT_APP_SERVER_PORT } = process.env;
    this.serverPort = REACT_APP_SERVER_PORT
    
    this.state = {
      messages: null,
      landscapingData: null,
      userInfo: null,
      startDate: '',
      endDate: '',
      userGroups: null,
      showLandscaping: false,
      showTurfMaintenance: true,
      landscapingDataIsLoading: false,
      trackingDataIsLoading: false,
      submitButtonDisabled: false,
      saveButtonDisabledLoading: false,
      showCustomerModel: true,
      customerName: '',
      customerData: null
    }

    this.userGroups = this.props.authState.accessToken.claims["Add-Groups"];

    this.adminGroup = "Admin";
    this.adminReadGroup = "Admin-Read";
    this.userGroup = "Users";

    this.showCustomerModel = false;

    this.state = {value: ''};

    this.handleChangeStartDate = this.handleChangeStartDate.bind(this);
    this.handleChangeEndDate = this.handleChangeEndDate.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.getCustomerNames = this.getCustomerNames.bind(this);
  }

  handleChangeStartDate(event) {
  this.setState({startDate: event.target.value});
}

  handleChangeEndDate(event) {
  this.setState({endDate: event.target.value});
  }

  handleSubmit(event) {
    alert(this.state.startDate + ' ' + this.state.endDate);
    event.preventDefault();
  }

  showAddCustomerModel(){
    this.showCustomerModel = !this.showCustomerModel
    this.setState({showAddCustomerModel:this.showCustomerModel});
  }

  async componentDidMount() {
    this.setState({saveButtonDisabled: true});
    this.setState({showAddCustomerModel: false})
    //this.test = await this.props.oktaAuth.token.getUserInfo();
  }

  async getCustomerNames(){
    const { REACT_APP_SERVER_URL } = process.env;
    //let serverPort = REACT_APP_SERVER_PORT

    try{
      if(this.state.customerData === undefined){
        const response = await fetch(`${REACT_APP_SERVER_URL}/Tracking/TurfMaintenance`, {
          method: 'GET',
          headers: {
              'Content-Type': 'application/json',
              Authorization: 'Bearer ' + this.props.authState.accessToken.accessToken,
              'data-request': 'read'
          },
        });

        if(response.status === 401){
          throw new Error("Your login time has expired, please log in again");
          this.setState({submitButtonDisabled: false});
        }

        let customerData = await response.json();

        let editedCustomerData = []
        for(let customer of customerData){
          editedCustomerData.push({"label": customer.name, "value": customer.name})
          // editedCustomerData["label"] = customer.name; 
          // editedCustomerData["value"] = customer.name; 
        }


        this.setState({customerData: editedCustomerData})
      }



    } catch (err) {
      // handle error as needed
      console.log(err);
      alert(err);
    }
  }

  async getAllTrackingDataByDate(){
    if((this.state.endDate !== undefined) && (this.state.startDate !== undefined)){
      this.setState({showTurfMaintenance: false});
      this.setState({trackingDataIsLoading: true});
      try {
          // const { REACT_APP_SERVER_PORT } = process.env;
          // let serverPort = REACT_APP_SERVER_PORT
          
          let userInfo = await this.props.oktaAuth.token.getUserInfo();
          this.setState({ userInfo: userInfo });
          const { REACT_APP_SERVER_URL } = process.env;
          const response = await fetch(`${REACT_APP_SERVER_URL}/Tracking/TurfMaintenance/`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + this.props.authState.accessToken.accessToken,
                'data-request': 'read'
            },
            body: JSON.stringify({
                // Validation data coming from a form usually
                startDate: this.state.startDate,
                endDate: this.state.endDate
            }) 
          })

          let test = await response.json();

          this.setState({ messages: test });
          this.setState({showTurfMaintenance: true});
          this.setState({showLandscaping: false});
          this.setState({trackingDataIsLoading: false});

        } catch (err) {
          console.log(err);
          alert(err);
          this.setState({trackingDataIsLoading: false});
          
        }
    } else{
      alert("Please Select a Start and End Date");
    }

  }


  async editTurfMaintenanceTrackingData(editData){
    try {
      const { REACT_APP_SERVER_URL } = process.env;
      const { REACT_APP_SERVER_PORT } = process.env;
      let serverPort = REACT_APP_SERVER_PORT
      let userInfo = await this.props.oktaAuth.token.getUserInfo();
      this.setState({ userInfo: userInfo });
      this.setState({saveButtonDisabledLoading: true});
      

      const response = await fetch(`${REACT_APP_SERVER_URL}/Tracking/edit/TurfMaintenance`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + this.props.authState.accessToken.accessToken,
            'data-request': 'read'
        },
        body: JSON.stringify({
            // Validation data coming from a form usually
            editData: editData,
            'user': this.state.userInfo.name
        }) 
      })
      if(response.status === 401){
        throw new Error("Your login time has expired, please log in again");
      }

      if(response.status === 200){
        alert('Data Has Updated');
      }
      if(response.status > 401){
        console.log(response);
        alert(`error: ${response.statusText}`);
      }
      let test = await response.json();
      this.setState({ messages: test });
      this.setState({saveButtonDisabledLoading: false});
    } catch (error) {
      // handle error as needed
      console.log(error);
      alert(error);
      this.setState({saveButtonDisabledLoading: false});
    }
  }

  async deleteTurfMaintenanceTrackingData(editData){
    try {
      const { REACT_APP_SERVER_URL } = process.env;
      const { REACT_APP_SERVER_PORT } = process.env;
      let serverPort = REACT_APP_SERVER_PORT


      let response = prompt("Type \"yes\ to continue delete", "");
      if(response === 'yes'){
        let userInfo = await this.props.oktaAuth.token.getUserInfo();
        this.setState({ userInfo: userInfo });
        this.setState({saveButtonDisabledLoading: true});
        const response = await fetch(`${REACT_APP_SERVER_URL}/Tracking/delete/TurfMaintenance`, {
          method: 'POST',
          headers: {
              'Content-Type': 'application/json',
              Authorization: 'Bearer ' + this.props.authState.accessToken.accessToken,
              'data-request': 'read'
          },
          body: JSON.stringify({
              // Validation data coming from a form usually
              editData: editData,
              'user': this.state.userInfo.name
          }) 
        })
        if(response.status === 401){
          throw new Error("Your login time has expired, please log in again");
        }

        if(response.status === 200){
          alert('Data was Deleted');
        }
        if(response.status > 401){
          console.log(response);
          alert(`error: ${response.statusText}`);
        }
        let test = await response.json();
        this.setState({ messages: test });
        this.setState({saveButtonDisabledLoading: false});
      } else{
        alert("Reponse was not yes, canceling delete");
      }

    } catch (error) {
      // handle error as needed
      console.log(error);
      alert(error);
      this.setState({saveButtonDisabledLoading: false});
    }
  }


  updateSaveButton(buttonState){
    if(this.userGroups.includes("Admin")){
      this.setState({saveButtonDisabled:buttonState});
    }

  }

  updateCustomerName(customerName){
    this.setState({customerName: customerName.value});
  }

  async submitCustomerTrackingData(addCustomerData) {
    console.log(addCustomerData);
    let userInfo = await this.props.oktaAuth.token.getUserInfo();
    this.setState({submitButtonDisabled: true});
    console.log("SUBMIT");
    addCustomerData["userName"] = userInfo.name;
    addCustomerData["name"] = this.state.customerName;

    try {
      const { REACT_APP_SERVER_URL } = process.env;
      const { REACT_APP_SERVER_PORT } = process.env;
      let serverPort = REACT_APP_SERVER_PORT
      // let userInfo = await this.props.oktaAuth.token.getUserInfo();
      // // this.setState({ userInfo: userInfo });

      const response = await fetch(`${REACT_APP_SERVER_URL}/Tracking/add/TurfMaintenance`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + this.props.authState.accessToken.accessToken,
          'data-request': 'read'
        },
        body: JSON.stringify({
          // Validation data coming from a form usually
          customerData: addCustomerData
        })
      })
      if (response.status === 401) {
        throw new Error("Your login time has expired, please log in again");
      }
      if (response.status === 200) {
        alert(`Customer ${addCustomerData.name} Added to Schedule`);
      }
      let test = await response.json();

      this.setState({submitButtonDisabled: false});
      window.location.reload(false);
    } catch (error) {
      // handle error as needed
      console.log(error);
      alert(error);
      this.setState({submitButtonDisabled: false});
    }
  }


  updateRowSelected(isRowSelected){
    console.log("Called");
    this.setState({rowSelected:isRowSelected})
  }

  render() {
    let content;
    if (!(typeof this.state.messages === 'undefined') && (this.state.showTurfMaintenance) && (this.userGroups.includes("Admin")) ){ 
        content = <TrackTable 
                   tableData = {this.state.messages}
                   saveButtonDisabled={this.state.saveButtonDisabled}
                   saveButtonDisabledLoading={this.state.saveButtonDisabledLoading}
                   rowSelected={this.state.rowSelected}
                   editTurfMaintenanceTrackingData = {this.editTurfMaintenanceTrackingData}
                   deleteTurfMaintenanceTrackingData = {this.deleteTurfMaintenanceTrackingData}
                   updateSaveButton={this.updateSaveButton}
                   />;
    } 
    else{
        //content = <p>Select Dates Above</p>
    }
    return <div>
      <h1 data-cy={"turf-landscaping-schedule-title"}>Turf and Landscaping Schedule</h1>
      {/* <Prompt
        when={!this.state.saveButtonDisabled}
        message='Are you sure you want to leave the page? You may have unsaved changes'
      /> */}
          <form onSubmit={this.handleSubmit}>
            <label style = {{ "marginBottom": "20px", "fontWeight": "bold"}}>
            Start Date:
            </label>
            <input type="date" startdate={this.state.startDate} onChange={this.handleChangeStartDate}
             style = {{"marginRight": "20px"}}/>
            <label style = {{ "fontWeight": "bold"}}>
            End Date:
            </label>
            <input type="date" enddate={this.state.endDate} onChange={this.handleChangeEndDate}/>
        </form>
        { (this.userGroups.includes(this.adminGroup) || this.userGroups.includes(this.adminReadGroup)) &&
        <div>
        <Button 
          variant="success" 
          onClick={this.getAllTrackingDataByDate}  
          style = {{"marginRight": "20px", "marginBottom": "20px"}}
          disabled={this.state.trackingDataIsLoading}
          >
          {
            this.state.trackingDataIsLoading ? 
            <div>Loading 
              <Spinner animation="border" size="sm" ></Spinner>
            </div> : 
            'Get Work Schedule Data'
          }
        </Button>
      </div>
        }
      { this.userGroups.includes(this.adminGroup) &&
        <AddCustomerScheduleModal

          onHide={this.showAddCustomerModel}
          submitCustomerTrackingData = {this.submitCustomerTrackingData}
          updateCustomerName = {this.updateCustomerName}
          getCustomerNames = {this.getCustomerNames}
          show={this.state.showAddCustomerModel}
          userGroups={this.userGroups}
          userInfo={this.state.userInfo}
          customerName = {this.state.customerName}
          customerData = {this.state.customerData}
          submitButtonDisabled = {this.state.submitButtonDisabled}
        />
    }
        {content}
    </div>  
  }
});