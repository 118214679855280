import fetch from 'isomorphic-fetch';
import React, { Component } from 'react';
import { withOktaAuth, useOktaAuth } from '@okta/okta-react';
import { Formik } from 'formik';
import { Button, Spinner, Container, Row, Col, Form, FloatingLabel, InputGroup} from 'react-bootstrap'
import DataTable from './Components/Table';
import CustomerTable from './Components/EditCustomerTable';
import {makeData} from './Components/makeData';
import Select from 'react-select';
import * as Yup from 'yup';

let page;

export default withOktaAuth(class AddCustomers extends Component {


  constructor(props) {
    
    super(props)
    // this.getAllData = this.getAllData.bind(this);
    // this.getAllDataByUser = this.getAllDataByUser.bind(this);
    this.editCustomerData = this.editCustomerData.bind(this);
    this.submitCustomerData = this.submitCustomerData.bind(this);
    this.state = {
      messages: null,
      userInfo: null,
      startDate: '',
      endDate: '',
      userGroups: null,
      saveButtonDisabled: false,
      validated: false,
      setValidated: false,
      addButtonDisabled: true,
      customerActiveOption: true

    }

    this.userGroups = this.props.authState.accessToken.claims["Add-Groups"];

    this.adminGroup = "Admin";
    this.adminReadGroup = "Admin-Read";
    this.userGroup = "Users"

    this.state = {value: ''};
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChange = this.handleChange.bind(this);

    this.schema = Yup.object().shape({
      name: Yup.string().min(2, 'Name must be longer than 1 character').required("Please Enter a Name"),
      number: Yup.string().min(12, 'Number must be 12 characters with dashes (111-111-1111)')
                          .max(12, 'Number must be 12 characters with dashes (111-111-1111)')
                          .matches(/^\d{3}-\d{3}-\d{4}$/, "Must have numbers and dashes. Ex: (111-111-1111)"),
      address: Yup.string().required("Please Enter an Address"),
      crew: Yup.string().required("Please Enter a Crew"),
      monthlyPrice: Yup.number(),
      notes: Yup.string(),
    });
  }

  handleChange = (customerActiveOption) => {
    console.log(customerActiveOption);
    let customerStatusValue = customerActiveOption.value;
    this.setState({ customerStatusValue });
    // this.props.updateCustomerName(selectedOption)
  };


    async submitCustomerData(addCustomerData) {
      let userInfo = await this.props.oktaAuth.token.getUserInfo();
      this.setState({addButtonDisabled: true});
      addCustomerData["userName"] = userInfo.name;
      addCustomerData["activeOption"] = this.state.customerStatusValue || true;

      try {
        const { REACT_APP_SERVER_URL } = process.env;
        const { REACT_APP_SERVER_PORT } = process.env;
        let serverPort = REACT_APP_SERVER_PORT
        const response = await fetch(`${REACT_APP_SERVER_URL}/Customers/addCustomers`, {
          method: 'POST',
          headers: {
              'Content-Type': 'application/json',
              Authorization: 'Bearer ' + this.props.authState.accessToken.accessToken,
              'data-request': 'read'
          },
          body: JSON.stringify({
              // Validation data coming from a form usually
              customerData: addCustomerData
          }) 
        })
        if(response.status === 401){
          throw new Error("Your login time has expired, please log in again");
        }
        else if(response.status === 200){
          alert(`Customer ${addCustomerData.name} Added`);
        }
        else if(response.status <= 402){
          alert(`Error adding ${addCustomerData.name}, error code ${response.status}`)
        }
        let test = await response.json();

        this.setState({addButtonDisabled: false});
        window.location.reload(false);
      } catch (error) {
        // handle error as needed
        console.log(error);
        alert(error);
        this.setState({addButtonDisabled: false});
      } 
    }

  async componentDidMount() {
    const { REACT_APP_SERVER_URL } = process.env;
    //let serverPort = REACT_APP_SERVER_PORT
    this.setState({saveButtonDisabled: false});

    try{

    } catch (err) {
      // handle error as needed
      console.log(err);
      alert(err);
    }
  }

  async editCustomerData(editData){
    try {
      const { REACT_APP_SERVER_URL } = process.env;
      const { REACT_APP_SERVER_PORT } = process.env;
      let serverPort = REACT_APP_SERVER_PORT
      let userInfo = await this.props.oktaAuth.token.getUserInfo();
      this.setState({ userInfo: userInfo });

      const response = await fetch(`${REACT_APP_SERVER_URL}/Customers/editCustomers`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + this.props.authState.accessToken.accessToken,
            'data-request': 'read'
        },
        body: JSON.stringify({
            // Validation data coming from a form usually
            editData: editData
        }) 
      })
      if(response.status === 401){
        throw new Error("Your login time has expired, please log in again");
      }
      let test = await response.json();
      this.setState({ messages: test });
    } catch (error) {
      // handle error as needed
      console.log(error);
      alert(error);
    }
  }

  handleSubmit = (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }

   // setValidated(true);
  };

  

  render() {
    let activeOptions = [
      { value: "true",  label: "Active" },
      { value: "false",  label: "Inactive" },
    ]
    return (this.props.authState.isAuthenticated && this.userGroups.includes("Admin"))? 
          <div>
            <h1>Add Customer</h1>
            <Formik
              validationSchema={this.schema}
              initialValues={{
                name: '',
                number: '',
                address: '',
                crew: '',
                monthlyPrice: 0,
                activeOption: '',
                notes: ''
                // zip: '',
                // terms: false,
              }}
              onSubmit={ ///() => {
                this.submitCustomerData
                //this.submitCustomerData(values)
              }//}
            >
            {({
              handleSubmit,
              handleChange,
              handleBlur,
              values,
              touched,
              isValid,
              errors,
            }) => (
              <Form onSubmit={handleSubmit}>
                <Row className="mb-3">
                  <Form.Group as={Col} md="4" controlId="validationFormik01">
                    <Form.Label>Name</Form.Label>
                    <Form.Control
                      data-cy={"add-customer-name-field"}
                      type="text"
                      placeholder="Name"
                      name="name"
                      value={values.name}
                      onChange={handleChange}
                      isValid={touched.name & !errors.name}
                      isInvalid={touched.name && errors.name}
                    />
                    <Form.Control.Feedback 
                      data-cy={"add-customer-name-field-invalid"}
                      type="invalid"
                    >
                      {errors.name}
                    </Form.Control.Feedback>
                    <Form.Control.Feedback
                      data-cy={"add-customer-name-field-valid"}
                    >
                        Looks good!
                    </Form.Control.Feedback>
                  </Form.Group>

                  <Form.Group as={Col} md="4" controlId="validationFormik02">
                    <Form.Label>Phone Number</Form.Label>
                    <InputGroup hasValidation>
                    <Form.Control
                      data-cy={"add-customer-phone-number-field"}
                      type="text"
                      placeholder="Phone Number"
                      name="number"
                      value={values.number}
                      onChange={handleChange}
                      isValid={touched.number && !errors.number}
                      isInvalid={touched.number && errors.number}
                    />
                    <Form.Control.Feedback 
                      data-cy={"add-customer-phone-number-field-invalid"}
                      type="invalid"
                    >
                      {errors.number}
                    </Form.Control.Feedback>
                    <Form.Control.Feedback
                      data-cy={"add-customer-phone-number-field-valid"}
                    >
                      Looks good!
                    </Form.Control.Feedback>
                    </InputGroup>
                  </Form.Group>

                </Row>
                <Row className="mb-3">
                  <Form.Group as={Col} md="6" controlId="validationFormik03">
                    <Form.Label>Address</Form.Label>
                    <Form.Control
                      data-cy={"add-customer-address-field"}
                      type="text"
                      placeholder="Address"
                      name="address"
                      value={values.address}
                      onChange={handleChange}
                      isValid={touched.address && !errors.address}
                      isInvalid={touched.address && !!errors.address}
                    />
                    <Form.Control.Feedback 
                      data-cy={"add-customer-address-field-invalid"}
                      type="invalid"
                    >
                      {errors.address}
                    </Form.Control.Feedback>
                    <Form.Control.Feedback
                      data-cy={"add-customer-address-field-valid"}
                    >
                      Looks good!
                    </Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group as={Col} md="3" controlId="validationFormik04">
                    <Form.Label>Crew</Form.Label>
                    <Form.Control
                      data-cy={"add-customer-crew-field"}
                      type="text"
                      placeholder="Crew"
                      name="crew"
                      value={values.crew}
                      onChange={handleChange}
                      isValid={touched.crew && !errors.crew}
                      isInvalid={touched.crew && !!errors.crew}
                    />
                    <Form.Control.Feedback 
                      data-cy={"add-customer-crew-field-invalid"}
                      type="invalid">
                      {errors.crew}
                    </Form.Control.Feedback>
                    <Form.Control.Feedback
                      data-cy={"add-customer-crew-field-valid"}
                    >
                      Looks good!
                    </Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group as={Col} md="4" controlId="validationFormikUsername">
                    <Form.Label>Monthly Price</Form.Label>
                      <Form.Control
                        data-cy={"add-customer-monthly-price-field"}
                        type="text"
                        placeholder="Monthy Price"
                        aria-describedby="inputGroupPrepend"
                        name="monthlyPrice"
                        value={values.monthlyPrice}
                        onChange={handleChange}
                        isValid={touched.monthlyPrice && !errors.monthlyPrice}
                        isInvalid={touched.monthlyPrice && !!errors.monthlyPrice}
                      />
                      <Form.Control.Feedback 
                        data-cy={"add-customer-monthly-price-field-invalid"}
                        type="invalid"
                      >
                      <Form.Control.Feedback
                       data-cy={"add-customer-monthly-price-field-valid"}
                      >
                        Looks good!
                      </Form.Control.Feedback>
                        {errors.monthlyPrice}
                      </Form.Control.Feedback>

                    {/* </InputGroup> */}
                  </Form.Group>
                  <Form.Group as={Col} md="4" controlId="validationFormik05">
                    <Form.Label>Customer Status</Form.Label>
                    <Select
                      data-cy={"add-customer-customer-status-field"}
                      value={this.state.customerActiveOption}
                      onChange={this.handleChange}
                      options={activeOptions}
                      placeholder="Active"
                      name="activeOption"
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.activeOption}
                    </Form.Control.Feedback>
                    <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                    </Form.Group>
                </Row>
                <Form.Group as={Col} md="6" controlId="validationFormik06">
                    <Form.Label>Notes</Form.Label>
                    <Form.Control
                      data-cy={"add-customer-notes-field"}
                      type="textarea"
                      style={{ height: '100px' }}
                      placeholder="Notes Here"
                      name="notes"
                      value={values.notes}
                      onChange={handleChange}
                      isValid={touched.notes && !errors.notes}
                    />
                    <Form.Control.Feedback
                      data-cy={"add-customer-notes-field-valid"}
                    >
                      Looks good!
                    </Form.Control.Feedback>
                  </Form.Group>
                <Button 
                  data-cy={"add-customer-estimates-button"}
                  disabled={this.state.addButtonDisabled}
                  type="submit"
                  >
                  {
                    this.state.addButtonDisabled ? 
                    <div>Loading 
                      <Spinner animation="border" size="sm" ></Spinner>
                    </div> : 
                    'Submit form'
                  }
                </Button>
              </Form>
            )}
          </Formik>
          </div>  :
          <div>
          <p>loading</p>
         </div> 
  }
});