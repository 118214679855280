import React, { Component } from 'react';
import { Button, Spinner, Modal, Row, Col, Form, FloatingLabel, InputGroup } from 'react-bootstrap';
import { Formik, Field } from 'formik';
import fetch from 'isomorphic-fetch';
import * as Yup from 'yup';
import Select from 'react-select';
import { withOktaAuth, useOktaAuth } from '@okta/okta-react';

export default withOktaAuth( class AddCleaningJobModal extends Component {

  constructor(props) {
    super(props);
    this.state = {
      show: false,
      close: false,
      submitButtonDisabled: false,
      saveButtonDisabled: false,
      validated: false,
      setValidated: false,
      addButtonDisabled: false,
      selectedCommunityNameOption: null,
      selectedCleanNumberOption: null,
      // selectedCleaningNumberOption: null,
      selectedCrewOption: null,
      selectedStopNumberOption: null,
    };

    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleCommunityChange = this.handleCommunityChange.bind(this);
    this.handleCleanNumberSelectChange = this.handleCleanNumberSelectChange.bind(this);
    this.handleStopNumberSelectChange = this.handleStopNumberSelectChange.bind(this);
    this.handleCrewChange = this.handleCrewChange.bind(this);
    this.handleStopNumberSelectChange = this.handleStopNumberSelectChange.bind(this);

    // this.cleanOptions = this.cleanNumberOptions;

    this.stopNumberOptions = [...Array(20).keys()].map((number) => ({
      value: `${number + 1}`,
      label: `${number + 1}`,
    }));

    this.communityOptions = this.props.communityOptions?.map((option) => ({
      value: option['name'],
      label: option['name']
    }));

    this.crews = this.props.crewsData?.map((option) => ({
      value: option['name'],
      label: option['name']
    }))

    const dateRegex = /^\d{4}-\d{2}-\d{2}$/;

    this.schema = Yup.object().shape({
      // name: Yup.string().min(2, 'Name must be longer than 1 character').required(),
      // number: Yup.string().min(12, 'Number must be 12 characters with dashes (111-111-1111)')
      //   .max(12, 'Number must be 12 characters with dashes (111-111-1111)')
      //   .matches(/^\d{3}-\d{3}-\d{4}$/, "Must have numbers and dashes. Ex: (111-111-1111)"),
      // crew: Yup.string().required("Crew"),
      // community: Yup.string().required("Please select a community"),
      lotNumber: Yup.number().typeError('Lot must be a number').nullable().required("Please Enter a Lot Number"),
      // cleanNumber: Yup.number().typeError('Clean Number must be a Number').required("Please Enter a Clean Number"),
      stopNumber: Yup.number().typeError('Stop Number must be a Number').nullable(),//.required("Please Enter a Stop Number"),
      invoiceNumber: Yup.string().nullable(),//.typeError('Invoice Number must be a Number'),//.required("Please Enter a Stop Number"),
      // date: Yup.string().matches(dateRegex, 'Invalid date format'),
      //projectManager: Yup.string().required("Please select a Project Manager"),
      //purchaseOrderNumber: Yup.string().typeError('Invoice Number must be a Number').nullable(),
      jobRevanue: Yup.number().typeError('Revanue must be a Number').nullable(),//.required("Please Enter the Job Revanue"),
      jobCost: Yup.number().typeError('Job Cost must be a Number').nullable(),//.required("Please Enter the Job Cost"),
      // inspectedBy: Yup.string(),//.typeError('Revanue must be a Number'),//.required("Please Enter the Job Revanue"),
      inspectedCost: Yup.number().typeError('Inspected Cost must be a Number').nullable(),//.required("Please Enter the Job Cost"),
      jobDate: Yup.string().required("Please select a Job Date").matches(dateRegex, 'Invalid date format'),
      
      // dayOfWeesk: Yup.string().matches(/^\b((monday|Monday|tuesday|Tuesday|wednesday|Wednesday|thursday|Thursday|friday|Friday|saturday|Saturday|sunday|Sunday)(day)?)\b/, 
      // "Must be full name of the day of the week").required("Please Enter a Day of the Week"),
      // monthlyPrice: Yup.number(),
      // notes: Yup.string(),
      // notes: Yup.string().required(),
      // terms: Yup.bool().required().oneOf([true], 'Terms must be accepted'),
    });



  }

  handleCommunityChange = (selectedCommunity) => {
    this.setState({ selectedCommunity});
    // this.props.updateCustomerName(selectedOption)
    this.setState({selectedCommunityNameOption: selectedCommunity.value});
  };

  handleCrewChange = (selectedCrew) => {
    this.setState({ selectedCrew });
    //change this to updateComminityName
    this.setState({selectedCrewOption: selectedCrew.value});
  };

  handleCleanNumberSelectChange = (selectedCleanNumber) => {
    this.setState({ selectedCleanNumber });
    // this.props.updateCustomerName(selectedOption)
    this.setState({selectedCleanNumberOption: selectedCleanNumber.value});
  };

  handleStopNumberSelectChange = (selectedStopNumber) => {
    this.setState({ selectedStopNumber });
    // this.props.updateCustomerName(selectedOption)
    this.setState({selectedStopNumberOption: selectedStopNumber.value});
  };
  
  handleSubmit = async (formData, resetForm) => {
      if(this.state.selectedCommunityNameOption == null ){
        alert("Please select Community");
        // event.preventDefault();
        // event.stopPropagation();
      } 
      // else if(this.state.selectedCrewOption == null){
      //     alert("Please select a Crew");
      //     // event.preventDefault();
      //     // event.stopPropagation();
      // } 
      else if(this.state.selectedCleanNumberOption == null){

          alert("Please select a Clean Number");
      } else {
        this.setState({submitButtonDisabled: true});

        let userInfo =  await this.props.oktaAuth.token.getUserInfo();

        let userName = userInfo.name;
        formData = this.replaceEmptyStringWithNull(formData);

        let accessToken = this.props.authState.accessToken.accessToken;
        formData["cleanNumber"] = this.state.selectedCleanNumberOption;
        formData["stopNumber"] = this.state.selectedStopNumberOption;
        formData["community"] = this.state.selectedCommunityNameOption;
        formData["crew"] = this.state.selectedCrewOption;

        await this.props.submitCleaningJob(formData, resetForm, this.resetState, userName, accessToken);
        this.setState({selectedCrewOption: null});
      }
      this.setState({submitButtonDisabled: false});
  };

  replaceEmptyStringWithNull(obj) {
    for (const key in obj) {
      if (obj.hasOwnProperty(key) && typeof obj[key] === 'string' && obj[key].trim() === '') {
        obj[key] = null;
      }
    }
    return obj;
  }

  resetState = () => {
    this.setState({selectedCommunity: ''});
    this.setState({selectedCleanNumber: ''})
    this.setState({selectedCrew: ''})
    this.setState({selectedStopNumber: ''});

        // // this.handleCommunityChange('');
        // this.setState({selectedCommunityNameOption: ''});
        // this.setState({selectedCrewOption: ''});
        // // this.handleCrewChange('')
        // this.setState({selectedCleanNumberOption: ''});
        // // this.handleCleanNumberSelectChange('');
        // this.setState({selectedStopNumberOption: ''});
        // // this.handleStopNumberSelectChange('');
  }


  render() {
    const { selectedOption } = this.state;
    const { selectedCrew } = this.state;
    const { selectedCleanNumber } = this.state;
    const { selectedCommunity } = this.state;
    const {selectedStopNumber} = this.state;
    
    return (
      <div>
        <Button
          data-cy={this.props.testPropName}
          variant={this.props.variant} 
          onClick={() => {
            this.setState({ show: true }); 
          }}
          //disabled={this.props.submitButtonDisabled}
        >
          { this.props.buttonName}
        </Button>
        <Modal
          show={this.state.show}
          onHide={() => this.setState({ show: false })}
          animation={true}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          className=""
          shadow-lg="border">
          <Modal.Header closeButton>
            <Modal.Title 
              data-cy={`add-${this.props.testModalType}-title`}
              id="contained-modal-title-vcenter"
            >
              Add {this.props.cleanType} Job
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>
              <h1 data-cy={`add-${this.props.cleanType}-header`} >Add {this.props.cleanType} Job</h1>
              <Formik
                validationSchema={this.schema}
                initialValues={{
                  // name: '',
                  // number: '',
                  // address: '',
                  lotNumber: '',
                  //date: '',
                  cleanNumber: '',
                  projectManager: '',
                  stopNumber: '',
                  invoiceNumber: '',
                  purchaseOrderNumber: '',
                  jobPaid: false,
                  crew: '',
                  community: '',
                  jobRevanue: '',
                  jobCost: '',
                  jobDate: '',
                  inspectedCost: '',
                  inspectedBy: '',
                  notes: '',
                  // zip: '',
                  // terms: false,
                }}
                onSubmit={(values, { resetForm }) =>{
                  this.handleSubmit(values, resetForm);
                }

                }
              >
                {({
                  handleSubmit,
                  handleChange,
                  handleBlur,
                  resetForm,
                  values,
                  touched,
                  isValid,
                  errors,
                }) => (
                  <Form onSubmit={handleSubmit}>
                    <Row className="mb-3">
                      <Form.Group as={Col} controlId="dob" md="4">
                        <Form.Label>Purchase Order Number </Form.Label>
                        <Form.Control 
                        data-cy={`add-${this.props.testModalType}-modal-purchase-order-number-field`}
                        type="text" 
                        name="purchaseOrderNumber" 
                        value={values.purchaseOrderNumber}
                        onChange={handleChange}
                        isValid={touched.purchaseOrderNumber && !errors.purchaseOrderNumber}
                        isInvalid={touched.purchaseOrderNumber && !!errors.purchaseOrderNumber}
                        placeholder="Purchase Order" />
                        <Form.Control.Feedback type="invalid">
                          {errors.purchaseOrderNumber}
                        </Form.Control.Feedback>
                        <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                      </Form.Group>
                    </Row>
                    <Row className="mb-3">
                      <Form.Group as={Col} md="6" controlId="validationFormik04">
                      <Form.Label>Community</Form.Label>
                        <Select
                          data-cy={`add-${this.props.testModalType}-modal-community-field`}
                          inputId={`add-${this.props.testModalType}-modal-community-field`}
                          name="community"
                          value={selectedCommunity}
                          onChange={this.handleCommunityChange}
                          options={this.communityOptions}
                          placeholder={"Community Name"}
                          />
                        <Form.Control.Feedback type="invalid">
                          {errors.community}
                        </Form.Control.Feedback>
                        <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                      </Form.Group>
                    </Row>
                    <Row className="mb-3">
                      <Form.Group as={Col} md="3" controlId="validationFormik04">
                      <br/>
                        <Form.Label>Lot</Form.Label>
                        <Form.Control
                          data-cy={`add-${this.props.testModalType}-modal-lot-number-field`}
                          type="text"
                          placeholder="Lot number: 10, 250, etc"
                          name="lotNumber"
                          value={values.lotNumber}
                          onChange={handleChange}
                          isValid={touched.lotNumber && !errors.lotNumber}
                          isInvalid={touched.lotNumber && !!errors.lotNumber}
                        />
                        <Form.Control.Feedback 
                          data-cy={`add-${this.props.testModalType}-modal-lot-number-valid`}
                          type="invalid">
                          {errors.lotNumber}
                        </Form.Control.Feedback>
                        <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                      </Form.Group>

                      <Form.Group as={Col} controlId="dob" md="4">
                      <br/>
                        <Form.Label>Clean Number </Form.Label>
                        <Select
                          data-cy={`add-${this.props.testModalType}-modal-clean-number-field`}
                          inputId={`add-${this.props.testModalType}-modal-clean-number-field`}
                          name="cleanNumber"
                          value={selectedCleanNumber}
                          onChange={this.handleCleanNumberSelectChange}
                          options={this.props.cleanOptions}
                          placeholder={"Clean Number"}
                        />
                        {/* <Form.Control 
                        type="text" 
                        name="cleanNumber" 
                        value={values.cleanNumber}
                        onChange={handleChange}
                        isValid={touched.cleanNumber && !errors.cleanNumber}
                        isInvalid={touched.cleanNumber && !!errors.cleanNumber}
                        placeholder="Clean Number" /> */}
                        <Form.Control.Feedback type="invalid">
                          {errors.cleanNumber}
                        </Form.Control.Feedback>
                        <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                      </Form.Group>
                    </Row>
                    <Row className="mb-3">
                      <Form.Group as={Col} md="3" controlId="validationFormik04">
                      <br/>
                        <Form.Label>Job Revenue</Form.Label>
                        <Form.Control
                          data-cy={`add-${this.props.testModalType}-modal-job-revanue-field`}
                          type="text"
                          placeholder="Job Revenue"
                          name="jobRevanue"
                          value={values.jobRevanue}
                          onChange={handleChange}
                          isValid={touched.jobRevanue && !errors.jobRevanue}
                          isInvalid={touched.jobRevanue && !!errors.jobRevanue}
                        />
                        <Form.Control.Feedback 
                          data-cy={`add-${this.props.testModalType}-modal-job-revanue-valid`}
                          type="invalid"
                        >
                          {errors.jobRevanue}
                        </Form.Control.Feedback>
                        <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                      </Form.Group>

                      <Form.Group as={Col} controlId="dob" md="4">
                      <br/>
                        <Form.Label>Job Cost</Form.Label>
                        <Form.Control 
                          data-cy={`add-${this.props.testModalType}-modal-job-cost-field`}
                          type="text" 
                          name="jobCost" 
                          value={values.jobCost}
                          onChange={handleChange}
                          isValid={touched.jobCost && !errors.jobCost}
                          isInvalid={touched.jobCost && !!errors.jobCost}
                          placeholder="Job Cost" 
                        />
                        <Form.Control.Feedback 
                          data-cy={`add-${this.props.testModalType}-modal-job-cost-valid`}
                          type="invalid"
                        >
                          {errors.jobCost}
                        </Form.Control.Feedback>
                        <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                      </Form.Group>
                    </Row>
                    <Row className="mb-3">
                    <Form.Group as={Col} controlId="dob" md="4">
                        <Form.Label>Job Date</Form.Label>
                        <Form.Control
                          data-cy={`add-${this.props.testModalType}-modal-job-date-field`}
                          type="date"
                          name="jobDate"
                          placeholder="Job Date"
                          value={values.jobDate}
                          onChange={handleChange}
                          isValid={touched.jobDate && !errors.jobDate}
                          isInvalid={touched.jobDate && !!errors.jobDate}
                        />
                        <Form.Control.Feedback 
                          data-cy={`add-${this.props.testModalType}-modal-job-date-valid`}
                          type="invalid"
                        >
                          {errors.jobDate}
                        </Form.Control.Feedback>
                        <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                      </Form.Group>
                      {/* <Form.Group as={Col} controlId="dob" md="4">
                        <Form.Label>Project Manager</Form.Label>
                        <Form.Control 
                        type="text" 
                        name="projectManager" 
                        value={values.projectManager}
                        onChange={handleChange}
                        isValid={touched.projectManager && !errors.projectManager}
                        isInvalid={touched.projectManager && !!errors.projectManager}
                        placeholder="Project Manager" />
                        <Form.Control.Feedback type="invalid">
                          {errors.projectManager}
                        </Form.Control.Feedback>
                        <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                      </Form.Group> */}
                  </Row>
                    {/* <Row className="mb-3">
                      <Form.Group as={Col} controlId="dob" md="4">
                        <Form.Label>Select Date</Form.Label>
                        <Form.Control 
                        type="date" 
                        name="date" 
                        value={values.date}
                        onChange={handleChange}
                        isValid={touched.date && !errors.date}
                        isInvalid={touched.date && !!errors.date}
                        placeholder="Date of Birth" />
                        <Form.Control.Feedback type="invalid">
                          {errors.date}
                        </Form.Control.Feedback>
                        <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                      </Form.Group>
                    </Row> */}
                  <Row className="mb-3">
                    <Form.Group as={Col} md="6" controlId="validationFormik04">
                      <Form.Label>Crew</Form.Label>
                        <Select
                          data-cy={`add-${this.props.testModalType}-modal-crew-field`}
                          inputId={`add-${this.props.testModalType}-modal-crew-field`}
                          name="crew"
                          value={selectedCrew}
                          onChange={this.handleCrewChange}
                          options={this.crews}
                          placeholder={"Crew Name"}
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.crew}
                        </Form.Control.Feedback>
                        <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                      </Form.Group> 
                     </Row>

                    <Row className="mb-3">
                      <Form.Group as={Col} md="6" controlId="validationFormik06">
                      <Form.Label>Notes</Form.Label>
                      <Form.Control
                        data-cy={`add-${this.props.testModalType}-modal-notes-field`}
                        type="textarea"
                        style={{ height: '100px' }}
                        placeholder="Notes Here"
                        name="notes"
                        value={values.notes}
                        onChange={handleChange}
                        isValid={touched.notes && !errors.notes}
                      />
                      <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                  </Form.Group>
                    </Row>
                    
                    <Button
                      data-cy={`add-${this.props.testModalType}-modal-submit-button`}
                      onClick={() =>{
                      }}
                      disabled={this.state.submitButtonDisabled}
                      type="submit"
                    >
                      {
                        this.state.submitButtonDisabled ?
                          <div>Submitting
                            <Spinner animation="border" size="sm" ></Spinner>
                          </div> :
                          'Submit form'
                      }
                    </Button>
                  </Form>
                )}
              </Formik>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button 
              data-cy={`add-${this.props.testModalType}-modal-close-button`}
              onClick={() => this.setState({ show: false })}
            >
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
})
