import React from "react";
import styled from "styled-components";
import { Button, Spinner } from 'react-bootstrap'
import {
  useTable,
  usePagination,
  useSortBy,
  useFilters,
  useGroupBy,
  useExpanded,
  useRowSelect
} from "react-table";
import { matchSorter } from "match-sorter";

import {
    SelectColumnFilter
} from '../TableFilters';

import {
    EditableDropDownJobCell,
    EditableDropDownCell,
    EditableCellTime,
    EditableCheckboxCell,
    EditableDateCell,
    DropDownCleanNumberCell,
    DropDownCommunityCell,
    DropDownCrewCell,
    DropDownStopNumberCell,
    EditableNotesCell

} from '../TableCells';

const createCleaningColumns = (crews, communities)=>{


  const cleaningColumns = [
    {
      Header: "Customer Information",
      columns: [
        {
          Header: "Community Name",
          accessor: "community_name",
          Filter: SelectColumnFilter,
          filter: "includes",
          Cell: (props) => <DropDownCommunityCell {...props} communities={communities} />,
          enableMultiSort: true
        },
        {
          Header: "Lot",
          accessor: "lot_number",
          aggregate: "count",
          Aggregated: ({ value }) => `${value} Names`,
          isVisible: false,
          toggleHidden: true
        },
        {
          Header: "Clean Number",
          accessor: "clean_number",
          Filter: SelectColumnFilter,
          filter: "includes",
          Cell: DropDownCleanNumberCell
        },
        {
          Header: "Project Manager",
          accessor: "project_manager",
          aggregate: "count",
          Aggregated: ({ value }) => `${value} Names`
        },
        {
          Header: "SupplyPro Date",
          accessor: "job_date",
          Filter: SelectColumnFilter,
          filter: "includes",
          Cell: EditableDateCell,

        },
        {
          Header: "Actual Job Date",
          accessor: "actual_job_date",
          Filter: SelectColumnFilter,
          filter: "includes",
          Cell: EditableDateCell,
        },
        {
          Header: "Stop Number",
          accessor: "stop_number",
          Filter: SelectColumnFilter,
          filter: "includes",
          Cell: DropDownStopNumberCell
        },
        {
          Header: "Invoice Number",
          accessor: "invoice_number",
          aggregate: "count",
          Aggregated: ({ value }) => `${value} Names`,
          isVisible: false,
          toggleHidden: true
          // Aggregated: ({ value }) => `${value} Names`,
          // Cell: EditableCellTime,
        },
        {
          Header: "Purchase Order",
          accessor: "purchase_order",
          aggregate: "count",
          Aggregated: ({ value }) => `${value} Names`,
          isVisible: false,
          toggleHidden: true
          // Filter: SelectColumnFilter,
          // filter: "includes",
          // Cell: EditableDropDownCell,
        },
        {
          Header: "Job Paid",
          accessor: "job_paid",
          Filter: SelectColumnFilter,
          filter: "includes",
          Cell: EditableCheckboxCell,
        },
        {
          Header: "Notes",
          accessor: "notes",
          aggregate: "count",
          Aggregated: ({ value }) => `${value} Names`,
          Cell: EditableNotesCell,
        },
        {
          Header: "Office Notes",
          accessor: "office_notes",
          aggregate: "count",
          Aggregated: ({ value }) => `${value} Names`,
          Cell: EditableNotesCell,
        },
        {
          Header: "Crew",
          accessor: "cleaning_crew",
          Filter: SelectColumnFilter,
          filter: "includes",
          Cell: (props) => <DropDownCrewCell {...props} crews={crews} />,
        },
        {
          Header: "Cleaner Paid",
          accessor: "cleaner_paid",
          Filter: SelectColumnFilter,
          filter: "includes",
          Cell: EditableCheckboxCell,
        },
        {
          Header: "Cleaning Revanue",
          accessor: "revenue",
          aggregate: "count",
          Aggregated: ({ value }) => `${value} Names`,
          isVisible: false,
          toggleHidden: true
        },
        {
          Header: "Cleaning Cost",
          accessor: "job_cost",
          aggregate: "count",
          Aggregated: ({ value }) => `${value} Names`,
          isVisible: false,
          toggleHidden: true
        },
        {
          Header: "Inspected By",
          accessor: "inspected_by",
          Filter: SelectColumnFilter,
          filter: "includes",
          Cell: (props) => <DropDownCrewCell {...props} crews={crews} />,
        },
        {
          Header: "Inspected Cost",
          accessor: "inspected_cost",
          aggregate: "count",
          Aggregated: ({ value }) => `${value} Names`,
          isVisible: false,
          toggleHidden: true
        },
        {
          Header: "Profit",
          accessor: "profit",
          aggregate: "count",
          Aggregated: ({ value }) => `${value} Names`,
          isVisible: false,
          toggleHidden: true
        },
      ]
    },
    {
      Header: "Submission Info",
      columns: [
            {
              Header: "Submitted By",
              accessor: "submitted_by",
              aggregate: "count",
              Aggregated: ({ value }) => `${value} Names`,
              isVisible: false,
              toggleHidden: true
            },
            {
              Header: "Submission Time",
              accessor: "timestamp_column",
              aggregate: "count",
              Aggregated: ({ value }) => `${value} Names`,
              Cell: EditableCellTime,
            },
            {
              Header: "Last Modified",
              accessor: "last_modified",
              aggregate: "count",
              Aggregated: ({ value }) => `${value} Names`,
              Cell: EditableCellTime,
            },
            {
              Header: "Last Modified By",
              accessor: "last_modified_by",
              aggregate: "count",
              Aggregated: ({ value }) => `${value} Names`,
              isVisible: false,
              toggleHidden: true
            },
      ]
    }
  ]

  return cleaningColumns;

}

export default createCleaningColumns;
