import fetch from 'isomorphic-fetch';
import React, { Component } from 'react';
import { withOktaAuth, useOktaAuth } from '@okta/okta-react';
import { Button, Spinner, Container, Row, Col } from 'react-bootstrap'
import TrackTable from './Components/TrackTable';
import AddCleaningCommunitiesModal from './Components/AddCleaningCommunitiesModal';
import {submitCleaningCommunity, getCleaningCommunities} from './helpers/Cleaning/cleaningCommunities';
import {makeData} from './Components/makeData';
import TableComponent from './Components/TableComponent';
import cleaningCommunitiesColumns from './Components/Table/Columns/CleaningCommunities';

let page;

export default withOktaAuth(class MessageList extends Component {


  constructor(props) {
    
    super(props)
    this.editCleaningCommunitiesData = this.editCleaningCommunitiesData.bind(this);
    this.deleteCleaningCommunitiesData = this.deleteCleaningCommunitiesData.bind(this);
    this.updateSaveButton = this.updateSaveButton.bind(this);
    this.updateRowSelected = this.updateRowSelected.bind(this);
    this.showAddCustomerModel = this.showAddCustomerModel.bind(this);
    this.updateCustomerName = this.updateCustomerName.bind(this);

    const { REACT_APP_SERVER_PORT } = process.env;
    this.serverPort = REACT_APP_SERVER_PORT
    
    this.state = {
      messages: null,
      landscapingData: null,
      userInfo: null,
      startDate: '',
      endDate: '',
      userGroups: null,
      showLandscaping: false,
      showCleaningCommunities: false,
      landscapingDataIsLoading: false,
      communitiesDataIsLoading: false,
      submitButtonDisabled: false,
      saveButtonDisabledLoading: false,
      showCustomerModel: true,
      customerName: '',
      communitiesData: null
    }

    this.userGroups = this.props.authState.accessToken.claims["Add-Groups"];

    this.adminGroup = "Admin";
    this.adminReadGroup = "Admin-Read";
    this.userGroup = "Users";
    this.adminCleanGroup = "Cleaning-Admin";

    this.showCustomerModel = false;

    this.state = {value: ''};

    this.handleChangeStartDate = this.handleChangeStartDate.bind(this);
    this.handleChangeEndDate = this.handleChangeEndDate.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleChangeStartDate(event) {
  this.setState({startDate: event.target.value});
}

  handleChangeEndDate(event) {
  this.setState({endDate: event.target.value});
  }

  handleSubmit(event) {
    alert(this.state.startDate + ' ' + this.state.endDate);
    event.preventDefault();
  }

  showAddCustomerModel(){
    this.showCustomerModel = !this.showCustomerModel
    this.setState({showAddCustomerModel:this.showCustomerModel});
  }

  async componentDidMount() {
    this.setState({saveButtonDisabled: true});
    this.setState({showAddCustomerModel: false});
    if(this.userGroups.includes("Admin") ||
      this.userGroups.includes(this.adminCleanGroup)){
      this.getCleaningCommunities();
    }
  }

  async getCleaningCommunities(){
    this.setState({show: false});
    this.setState({communitiesDataIsLoading: true});
    try {
        // const { REACT_APP_SERVER_PORT } = process.env;
        // let serverPort = REACT_APP_SERVER_PORT
        
        let userInfo = await this.props.oktaAuth.token.getUserInfo();
        this.setState({ userInfo: userInfo });
        const { REACT_APP_SERVER_URL } = process.env;
        const response = await fetch(`${REACT_APP_SERVER_URL}/CleaningCommunities`, {
          method: 'GET',
          headers: {
              'Content-Type': 'application/json',
              Authorization: 'Bearer ' + this.props.authState.accessToken.accessToken,
              'data-request': 'read'
          },
        })

        let communities = await response.json();

        this.setState({ communitiesData: communities });
        this.setState({showCleaningCommunities: true});
        this.setState({communitiesDataIsLoading: false});

      } catch (err) {
        console.log(err);
        alert(err);
        this.setState({communitiesDataIsLoading: false});
        
      }
}



  async editCleaningCommunitiesData(editData){
    try {
      const { REACT_APP_SERVER_URL } = process.env;
      const { REACT_APP_SERVER_PORT } = process.env;
      let serverPort = REACT_APP_SERVER_PORT
      let userInfo = await this.props.oktaAuth.token.getUserInfo();
      this.setState({ userInfo: userInfo });
      this.setState({saveButtonDisabledLoading: true});

      const response = await fetch(`${REACT_APP_SERVER_URL}/CleaningCommunities/editCleaningCommunities`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + this.props.authState.accessToken.accessToken,
            'data-request': 'read'
        },
        body: JSON.stringify({
            // Validation data coming from a form usually
            editData: editData,
            'user': this.state.userInfo.name
        }) 
      })
      if(response.status === 401){
        throw new Error("Your login time has expired, please log in again");
      }

      if(response.status === 200){
        alert('Data Has Updated');
      }
      if(response.status > 401){
        alert(`error: ${response.statusText}`);
      }
      let test = await response.json();
      this.setState({ messages: test });
      this.setState({saveButtonDisabledLoading: false});
      await this.getCleaningCommunities();
    } catch (error) {
      // handle error as needed
      console.log(error);
      alert(error);
      this.setState({saveButtonDisabledLoading: false});
    }
  }

  async deleteCleaningCommunitiesData(editData){
    try {
      const { REACT_APP_SERVER_URL } = process.env;
      const { REACT_APP_SERVER_PORT } = process.env;
      let serverPort = REACT_APP_SERVER_PORT


      let userInfo = await this.props.oktaAuth.token.getUserInfo();
      this.setState({ userInfo: userInfo });
      this.setState({saveButtonDisabledLoading: true});
      const response = await fetch(`${REACT_APP_SERVER_URL}/CleaningCommunities/deleteCleaningCommunities`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + this.props.authState.accessToken.accessToken,
            'data-request': 'read'
        },
        body: JSON.stringify({
            // Validation data coming from a form usually
            editData: editData,
            'user': this.state.userInfo.name
        }) 
      })
      if(response.status === 401){
        throw new Error("Your login time has expired, please log in again");
      }

      if(response.status === 204){
        alert('Data was Deleted');
      }
      if(response.status > 401){
        alert(`error: ${response.statusText}`);
      }
      this.setState({saveButtonDisabledLoading: false});
      await this.getCleaningCommunities();


    } catch (error) {
      // handle error as needed
      console.log(error);
      alert(error);
      this.setState({saveButtonDisabledLoading: false});
    }
  }


  updateSaveButton(buttonState){
    if(this.userGroups.includes("Admin") ||
      this.userGroups.includes(this.adminCleanGroup)){
      this.setState({saveButtonDisabled:buttonState});
    }

  }

  updateCustomerName(customerName){
    this.setState({customerName: customerName.value});
  }


  updateRowSelected(isRowSelected){
    this.setState({rowSelected:isRowSelected})
  }

  render() {
    let content;
    if (!(typeof this.state.communitiesData === 'undefined') && ((this.state.showCleaningCommunities && !this.state.communitiesDataIsLoading))){ 
        content = 
                   <TableComponent 
                   tableColumns = {cleaningCommunitiesColumns}
                   tableData = {this.state.communitiesData}
                   saveButtonDisabled={this.state.saveButtonDisabled}
                   saveButtonDisabledLoading={this.state.saveButtonDisabledLoading}
                   rowSelected={this.state.rowSelected}
                   editData={this.editCleaningCommunitiesData}
                   deleteData={this.deleteCleaningCommunitiesData}
                   updateSaveButton={this.updateSaveButton}
                   columnsToHide={this.state.columnsToHide}
                   showLegend={false}
                   printFilename={`Communities`}
                 />;
    } 

    return <div>
          <h1>Cleaning Communities </h1>
        { (this.userGroups.includes(this.adminGroup) || 
          this.userGroups.includes(this.adminReadGroup) || 
          this.userGroups.includes(this.adminCleanGroup)) &&
        <div>
          {
            this.state.communitiesDataIsLoading &&
            <div>
              <Container>
                <Row>
                  <Col xs={6}></Col>
                  <Col xs={6}>
                    <h1>Loading...</h1>
                    <Spinner animation="border" variant="success" style={{width: "10rem", height: "10rem"}}/>
                    </Col>
                </Row>
            </Container>
           </div>
          }
      </div>
        }
      { ((this.userGroups.includes(this.adminGroup) || this.userGroups.includes(this.adminCleanGroup)) &&
       (!(typeof this.state.communitiesData === 'undefined') ||  !this.state.communitiesDataIsLoading )) &&
        <AddCleaningCommunitiesModal
          onHide={this.showAddCustomerModel}
          submitCustomerTrackingData = {this.submitCustomerTrackingData}
          updateCustomerName = {this.updateCustomerName}
          getCustomerNames = {this.getCustomerNames}
          show={this.state.showAddCustomerModel}
          userGroups={this.userGroups}
          userInfo={this.state.userInfo}
          customerName = {this.state.customerName}
          communitiesData = {this.state.communitiesData}
          submitButtonDisabled = {this.state.submitButtonDisabled}
          buttonName = {"Add Community"}
          variant = {"secondary"}
          submitCleaningCommunity = {submitCleaningCommunity}
          cleanType =  {"Power Washing"}
        />
    }
        {content}
    </div>  
  }
});