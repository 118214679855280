import React, { Component } from 'react';
import { Button, Spinner, Modal, Row, Col, Form} from 'react-bootstrap';
import { Formik} from 'formik';
import * as Yup from 'yup';
import { withOktaAuth} from '@okta/okta-react';

export default withOktaAuth( class DeleteConfirmModal extends Component {

  constructor(props) {
    super(props);
    this.state = {
      show: false,
      close: false,
      submitButtonDisabled: false,
      saveButtonDisabled: false,
    };

    this.schema = Yup.object().shape({
      deleteConfirm: Yup.string()
                      .required("Please type yes to delete or click cancel")
                      .matches("^yes$", "Please type yes to delete or click cancel" ),
    });
  }

  render() {

    let text = JSON.stringify(this.props.deleteData);

    let numberOfDeltedItems = this.props.deleteData.length;
    let deleteWarning;

    if(numberOfDeltedItems > 0 && numberOfDeltedItems < 10 ){
      deleteWarning = <h2>Warning you are about to delete: 
                        <span style={{
                              color: "red"
                            }}> {numberOfDeltedItems} thing(s)
                        </span> 
                      </h2>
    } else if(numberOfDeltedItems >= 10){
      deleteWarning 
        = <div>
            <h2         
              style={{
              color: "red"
             }}> WARNING You are about to delete a lot of data!</h2>
            <h2>Do you want to delete: 
              <span style={{
                    color: "red"
                    }}> {numberOfDeltedItems} thing(s)
                </span> ?
            </h2>
          </div>

    }


    
    return (
      <div>
        <Modal
          show={this.props.showDeleteModal}
          onHide={() => this.props.handleShowDeleteModal(false)}
          animation={true}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          className=""
          shadow-lg="border">
          <Modal.Header closeButton>
            <Modal.Title 
              data-cy={`delete-confirm-modal`}
              id="delete-confirm-modal"
            >
              Delete Job
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>
              <h1 data-cy={`add-${this.props.cleanType}-header`} >Are You Sure You Want to Delete?</h1>
              {deleteWarning}
              <Formik
                validationSchema={this.schema}
                initialValues={{
                  deleteConfirm: '',
                }}
                validateOnMount={true}
              >
                {({
                  handleSubmit,
                  handleChange,
                  values,
                  touched,
                  errors,
                }) => (
                  <Form 
                  onSubmit={handleSubmit}
                  >
                    <Row className="mb-3">
                      <Form.Group as={Col} md="5" controlId="validationFormik04">
                      <br/>
                        <Form.Control
                          data-cy={"delete-confirm-field"}
                          type="text"
                          placeholder="Type yes to confirm"
                          name="deleteConfirm"
                          value={values.deleteConfirm}
                          onChange={handleChange}
                          isValid={touched.deleteConfirm && !errors.deleteConfirm}
                          isInvalid={touched.deleteConfirm && !!errors.deleteConfirm}
                        />
                        <Form.Control.Feedback 
                          data-cy={"add-clean-job-modal-lot-number-valid"}
                          type="invalid">
                          {errors.deleteConfirm}
                        </Form.Control.Feedback>
                        <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                      </Form.Group>
                    </Row>
                    <Button 
                      data-cy={"delete-modal-cancel-button"}
                      onClick={() => this.props.handleShowDeleteModal(false)}
                    >
                      Cancel
                    </Button>
                    <Button
                      data-cy={"delete-modal-delete-button"}
                      variant="danger"
                      onClick={() =>{
                        if(values.deleteConfirm === 'yes'){
                          this.props.deleteWorkData(this.props.deleteData);
                        }
                      }}
                      disabled={this.state.submitButtonDisabled}
                      type="submit"
                    >
                      {
                        this.state.submitButtonDisabled ?
                          <div>Deleting
                            <Spinner animation="border" size="sm" ></Spinner>
                          </div> :
                          'Delete'
                      }
                    </Button>
                  </Form>
                )}
              </Formik>
            </div>
          </Modal.Body>
        </Modal>
      </div>
    );
  }
})
