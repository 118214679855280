import React, { Component } from 'react';
import { Button, Spinner, Modal, Row, Col, Form, FloatingLabel, InputGroup } from 'react-bootstrap';
import { Formik } from 'formik';
import fetch from 'isomorphic-fetch';
import * as Yup from 'yup';
import Select from 'react-select';

export default class AddCustomerEstimateModal extends Component {

  constructor(props) {
    super(props);
    this.state = {
      show: false,
      close: false,
      saveButtonDisabled: false,
      validated: false,
      setValidated: false,
      addButtonDisabled: false,
      selectedOption: null,
    };

    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChange = this.handleChange.bind(this);
    


    this.schema = Yup.object().shape({
      // name: Yup.string().min(2, 'Name must be longer than 1 character').required(),
      // number: Yup.string().min(12, 'Number must be 12 characters with dashes (111-111-1111)')
      //   .max(12, 'Number must be 12 characters with dashes (111-111-1111)')
      //   .matches(/^\d{3}-\d{3}-\d{4}$/, "Must have numbers and dashes. Ex: (111-111-1111)"),
      // address: Yup.string().required(),
      crew: Yup.string().required("Please Enter a Crew"),
      //date: Yup.string().required(),
      weekType: Yup.string().required("Please Enter a Week Type"),
      dayOfWeek: Yup.string().matches(/^\b((monday|Monday|tuesday|Tuesday|wednesday|Wednesday|thursday|Thursday|friday|Friday|saturday|Saturday|sunday|Sunday)(day)?)\b/, 
      "Must be full name of the day of the week").required("Please Enter a Day of the Week"),
      // monthlyPrice: Yup.number(),
      // notes: Yup.string(),
      // notes: Yup.string().required(),
      // terms: Yup.bool().required().oneOf([true], 'Terms must be accepted'),
    });



  }

  handleChange = (selectedOption) => {
    this.setState({ selectedOption });
    this.props.updateCustomerName(selectedOption)
  };
  

  handleSubmit = (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }

    // setValidated(true);
  };


  


  render() {
    const { selectedOption } = this.state;
    return (
      <div>
        <Button
          variant="success"
          onClick={() => {
            this.setState({ show: true }); 
            this.props.getCustomerNames();
          }}
          //disabled={this.props.submitButtonDisabled}
        >
          Add Customer
        </Button>
        <Modal
          show={this.state.show}
          onHide={() => this.setState({ show: false })}
          animation={true}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          className=""
          shadow-lg="border">
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              Add Customer to Track
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>
              <h1>Add Customer</h1>
              <Formik
                validationSchema={this.schema}
                initialValues={{
                  // name: '',
                  // number: '',
                  // address: '',
                  crew: '',
                  //date: '',
                  weekType: '',
                  dayOfWeek: ''
                  // monthlyPrice: 0,
                  // notes: ''
                  // zip: '',
                  // terms: false,
                }}
                onSubmit={ ///() => {
                  this.props.submitCustomerTrackingData
                  //this.submitCustomerData(values)
                }//}
              >
                {({
                  handleSubmit,
                  handleChange,
                  handleBlur,
                  values,
                  touched,
                  isValid,
                  errors,
                }) => (
                  <Form onSubmit={handleSubmit}>
                    <Row className="mb-3">
                      {/* <Form.Group as={Col} md="4" controlId="validationFormik01">
                        <Form.Label>Name</Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Name"
                          name="name"
                          value={values.name}
                          onChange={handleChange}
                          isValid={touched.name & !errors.name}
                          isInvalid={touched.name && errors.name}
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.name}
                        </Form.Control.Feedback>
                        <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                      </Form.Group> */}

                     <Select
                      value={selectedOption}
                      onChange={this.handleChange}
                      options={this.props.customerData}
                      placeholder={"Customer Name"}
                      />
                      <Form.Group as={Col} md="3" controlId="validationFormik04">
                        <Form.Label>Crew</Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="A, B, etc"
                          name="crew"
                          value={values.crew}
                          onChange={handleChange}
                          isValid={touched.crew && !errors.crew}
                          isInvalid={touched.crew && !!errors.crew}
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.crew}
                        </Form.Control.Feedback>
                        <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                      </Form.Group>
                    </Row>
                    {/* <Row className="mb-3">
                      <Form.Group as={Col} controlId="dob" md="4">
                        <Form.Label>Select Date</Form.Label>
                        <Form.Control 
                        type="date" 
                        name="date" 
                        value={values.date}
                        onChange={handleChange}
                        isValid={touched.date && !errors.date}
                        isInvalid={touched.date && !!errors.date}
                        placeholder="Date of Birth" />
                        <Form.Control.Feedback type="invalid">
                          {errors.date}
                        </Form.Control.Feedback>
                        <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                      </Form.Group>
                    </Row> */}
                    <Row className="mb-3">
                      <Form.Group as={Col} controlId="dob" md="4">
                        <Form.Label>Week Type </Form.Label>
                        <Form.Control 
                        type="text" 
                        name="weekType" 
                        value={values.weekType}
                        onChange={handleChange}
                        isValid={touched.weekType && !errors.weekType}
                        isInvalid={touched.weekType && !!errors.weekType}
                        placeholder="Blue, Red, etc" />
                        <Form.Control.Feedback type="invalid">
                          {errors.weekType}
                        </Form.Control.Feedback>
                        <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                      </Form.Group>
                    </Row>
                    <Row className="mb-3">
                      <Form.Group as={Col} controlId="dob" md="4">
                        <Form.Label>Day of Week</Form.Label>
                        <Form.Control 
                        type="text" 
                        name="dayOfWeek" 
                        value={values.dayOfWeek}
                        onChange={handleChange}
                        isValid={touched.dayOfWeek && !errors.dayOfWeek}
                        isInvalid={touched.dayOfWeek && !!errors.dayOfWeek}
                        placeholder="Monday Tuesday, etc" />
                        <Form.Control.Feedback type="invalid">
                          {errors.dayOfWeek}
                        </Form.Control.Feedback>
                        <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                      </Form.Group>
                    </Row>

                    <Button
                      disabled={this.props.submitButtonDisabled}
                      type="submit"
                    >
                      {
                        this.props.submitButtonDisabled ?
                          <div>Submitting
                            <Spinner animation="border" size="sm" ></Spinner>
                          </div> :
                          'Submit form'
                      }
                    </Button>
                  </Form>
                )}
              </Formik>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={() => this.setState({ show: false })}>Close</Button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}
