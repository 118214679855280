import React from "react";
import styled from "styled-components";
import { Button, Spinner } from 'react-bootstrap'
import {
  useTable,
  usePagination,
  useSortBy,
  useFilters,
  useGroupBy,
  useExpanded,
  useRowSelect
} from "react-table";
import { matchSorter } from "match-sorter";

import {
    SelectColumnFilter
} from '../TableFilters';

import {
    EditableDropDownJobCell,
    EditableDropDownCell,
    EditableCellTime,
    EditableNotesCell

} from '../TableCells';


const estimateColumns = [
      {
        Header: "Customer Information",
        columns: [
          {
            Header: "Customer Name",
            accessor: "customer_name",
            aggregate: "count",
            Aggregated: ({ value }) => `${value} Names`,
            isVisible: false,
            toggleHidden: true
          },
          {
            Header: "Job Status",
            accessor: "job_status",
            Filter: SelectColumnFilter,
            filter: "includes",
            Cell: EditableDropDownJobCell,
          },
          {
            Header: "Job Type",
            accessor: "job_type",
            aggregate: "count",
            Aggregated: ({ value }) => `${value} Names`
          },
          {
            Header: "Estimate Req",
            accessor: "estimate_request",
            Filter: SelectColumnFilter,
            filter: "includes",
            // columnType: "select",
            Cell: EditableDropDownCell,
          },
          {
            Header: "Estimate Req Time",
            accessor: "estimate_request_time",
            aggregate: "count",
            Aggregated: ({ value }) => `${value} Names`,
            Cell: EditableCellTime,
          },
          {
            Header: "Estimate Sent",
            accessor: "estimate_sent_to_customer",
            Filter: SelectColumnFilter,
            filter: "includes",
            Cell: EditableDropDownCell,
          },
          {
            Header: "Estimate Sent Time",
            accessor: "estimate_sent_to_customer_time",
            aggregate: "count",
            Aggregated: ({ value }) => `${value} Names`,
            Cell: EditableCellTime,
          },
          {
            Header: "Estimate Approved",
            accessor: "estimate_approved",
            Filter: SelectColumnFilter,
            filter: "includes",
            Cell: EditableDropDownCell,
          },
          {
            Header: "Estimate approved Time",
            accessor: "estimate_approved_time",
            aggregate: "count",
            Aggregated: ({ value }) => `${value} Names`,
            Cell: EditableCellTime,
          },
          {
            Header: "Work Order Completed",
            accessor: "work_order_completed",
            Filter: SelectColumnFilter,
            filter: "includes",
            Cell: EditableDropDownCell,
          },
          {
            Header: "Work Order Completed Time",
            accessor: "work_order_completed_time",
            aggregate: "count",
            Aggregated: ({ value }) => `${value} Names`,
            Cell: EditableCellTime,
          },
          {
            Header: "Work Scheduled",
            accessor: "work_scheduled",
            Filter: SelectColumnFilter,
            filter: "includes",
            Cell: EditableDropDownCell,
          },
          {
            Header: "Work Scheduled Time",
            accessor: "work_scheduled_time",
            aggregate: "count",
            Aggregated: ({ value }) => `${value} Names`,
            Cell: EditableCellTime,
          },
          {
            Header: "Customer Emailed",
            accessor: "customer_emailed",
            Filter: SelectColumnFilter,
            filter: "includes",
            Cell: EditableDropDownCell,
          },
          {
            Header: "Customer Emailed Time",
            accessor: "customer_emailed_time",
            aggregate: "count",
            Aggregated: ({ value }) => `${value} Names`,
            Cell: EditableCellTime,
          },
          {
            Header: "811 Needed",
            accessor: "811_needed",
            Filter: SelectColumnFilter,
            filter: "includes",
            Cell: EditableDropDownCell,
          },
          {
            Header: "811 Needed Time",
            accessor: "811_needed_time",
            aggregate: "count",
            Aggregated: ({ value }) => `${value} Names`,
            Cell: EditableCellTime,
          },
          {
            Header: "811 Called",
            accessor: "811_called",
            Filter: SelectColumnFilter,
            filter: "includes",
            Cell: EditableDropDownCell,
          },
          {
            Header: "811 Called Time",
            accessor: "811_called_time",
            aggregate: "count",
            Aggregated: ({ value }) => `${value} Names`,
            Cell: EditableCellTime,
          },
          {
            Header: "Notes",
            accessor: "notes",
            aggregate: "count",
            Aggregated: ({ value }) => `${value} Names`,
            Cell: EditableNotesCell,
          },
        ]
      },
      {
        Header: "Estimate Data Info",
        columns: [
              {
                Header: "Last Modified",
                accessor: "last_modified",
                aggregate: "count",
                Aggregated: ({ value }) => `${value} Names`
              },
              {
                Header: "Last Modified By",
                accessor: "last_modified_by",
                aggregate: "count",
                Aggregated: ({ value }) => `${value} Names`
              },
        ]
      }
    ]

export default estimateColumns;
