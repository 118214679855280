// src/App.js

import React, { Component } from 'react';
import { BrowserRouter as Router, Route, withRouter, Switch } from 'react-router-dom';
import { SecureRoute, Security, LoginCallback } from '@okta/okta-react';
import { OktaAuth, toRelativeUrl } from '@okta/okta-auth-js';
import 'bootstrap/dist/css/bootstrap.min.css';
import NewNavBar from './Components/NewNavBar';
import Home from './Home';
import WorkData from './WorkData';
import CleanWorkData from './CleanWorkData';
import TrackWorkData from './TrackWorkData';
import Customers from './Customers';
import Estimates from './Estimates';
import AddCustomers from './AddCustomers';
import CleaningCrew from './CleaningCrew';
import Communitites from './Communities';
import Invoices from './pages/Invoices';
import Tracking from './pages/Tracking';
import NotFound from './NotFound';

class App extends Component {

  state = {
    oktaAuth: null,
    profile: null,
    setProfile: this.setProfile,
    loggedInUser: null,
    someVar: 0,
    count: 0,
    testVar: null
  }
  
  async componentDidMount(){

  }
  
  constructor(props) {
    const { REACT_APP_OKTA_DOMAIN } = process.env;
    const { REACT_APP_CLIENT_ID } = process.env;
  
    super(props);
    try{
      this.oktaAuth = new OktaAuth({
        issuer: `https://${REACT_APP_OKTA_DOMAIN}/oauth2/default`,
        clientId: REACT_APP_CLIENT_ID ,
        redirectUri: window.location.origin + '/login/callback',
      });
      this.restoreOriginalUri = async (_oktaAuth, originalUri) => {
        props.history.replace(toRelativeUrl(originalUri || '/', window.location.origin));
      };

      this.setState({testVar:this.oktaAuth.authStateManager});


    } catch(exception){
        console.log(exception);
        alert(exception);
    }

    this.setState({ loggedInUser: {user:"bam"} });

  }


 
  render() {
    return (
      <Router>
        <Security oktaAuth={this.oktaAuth} restoreOriginalUri={this.restoreOriginalUri}>
          <NewNavBar/>
          <Switch>
            <Route exact path='/' component={Home} />
            <SecureRoute exact path='/work-data' component={WorkData} />
            <SecureRoute exact path='/clean-data' component={CleanWorkData} />
            <SecureRoute exact path='/track-work-data' component={TrackWorkData} />
            <SecureRoute exact path='/track-estimate' component={Estimates} />
            <SecureRoute exact path='/customers' component={Customers} />
            <SecureRoute exact path='/addcustomer' component={AddCustomers} />
            <SecureRoute exact path='/cleaning-crew' component={CleaningCrew} />
            <SecureRoute exact path='/communities' component={Communitites} />
            <SecureRoute exact path='/invoices' component={Invoices} />
            <SecureRoute exact path='/location-tracking' component={Tracking} />
            <Route path='/login/callback' component={LoginCallback} />
            <Route component={NotFound} />
          </Switch>
        </Security>
      </Router>
    );
  }
}

export default App
