const submitPowerwashingJob = async (formData, resetForm, resetState, userName, accessToken) => {

    let addCleaningData ={};

    addCleaningData["userName"] = userName;
    addCleaningData["powerwashingJob"] = formData;

    try {
      const { REACT_APP_SERVER_URL } = process.env;
      const { REACT_APP_SERVER_PORT } = process.env;
      let serverPort = REACT_APP_SERVER_PORT
      const response = await fetch(`${REACT_APP_SERVER_URL}/Powerwashing/addPowerwashingJob`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + accessToken,
            'data-request': 'read'
        },
        body: JSON.stringify({
            // Validation data coming from a form usually
            powerwashingJobData: addCleaningData
        }) 
      })
      if(response.status === 401){
        throw new Error("Your login time has expired, please log in again");
      }
      else if(response.status === 200){
        alert(`Powerwashing Job for ${formData.community} lot ${formData.lotNumber} Clean Number ${formData.cleanNumber} Added`);
        resetForm();
        resetState();
      }
      else if(response.status <= 402){
        alert(`Error adding "${formData.community}" lot:  "${formData.lotNumber}" Clean Number: "${formData.cleanNumber}", error code ${response.status}`)
      }
      else if(response.status === 409){
        alert(`Powerwashing Job for "${formData.community}" lot:  "${formData.lotNumber}" Clean Number: "${formData.cleanNumber}" Already Exists`);
      }

      // window.location.reload(false);
    } catch (error) {
      // handle error as needed
      alert(error);
    } 
  }

  export default submitPowerwashingJob;