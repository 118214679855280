import {
    SelectColumnFilter,
    NumberRangeColumnFilter
} from '../TableFilters';

import {
    EditableDropDownJobCell,
    EditableDropDownCell,
    EditableCellTime,
    EditableNotesCell

} from '../TableCells';


const lawnMaintenanceColumns = [
    {
      Header: "Lawn Maintenace Information",
      columns: [
        {
          Header: "Customer Name",
          accessor: "customer_name",
          // Use a two-stage aggregator here to first
          // count the total rows being aggregated,
          // then sum any of those counts if they are
          // aggregated further
          aggregate: "count",
          Aggregated: ({ value }) => `${value} Names`
        },
        {
          Header: "Notes",
          accessor: "notes",
          // Use a two-stage aggregator here to first
          // count the total rows being aggregated,
          // then sum any of those counts if they are
          // aggregated further
          aggregate: "count",
          Aggregated: ({ value }) => `${value} Names`,
          Cell: EditableNotesCell,
        },
        {
          Header: "Crew",
          accessor: "crew",
          Filter: SelectColumnFilter,
          filter: "includes"
        },
        {
          Header: "mowed",
          accessor: "mowed",
          Filter: SelectColumnFilter,
          filter: "includes"
        },
        {
          Header: "Weeded",
          accessor: "weeded",
          Filter: SelectColumnFilter,
          filter: "includes"
        },
        {
          Header: "Blew",
          accessor: "blew",
          Filter: SelectColumnFilter,
          filter: "includes"
        },
        {
          Header: "Time Worked",
          accessor: "time_worked",
          Filter: NumberRangeColumnFilter,
          filter: "between",
          // Aggregate the sum of all visits
          aggregate: "sum",
          Aggregated: ({ value }) => `${value} (total)`
        },
        {
          Header: "Number of People",
          accessor: "number_of_people",
          Filter: SelectColumnFilter,
          filter: "includes"
        },
        {
          Header: "Expense Type",
          accessor: "expense_type",
          Filter: SelectColumnFilter,
          filter: "includes"
        },
        {
          Header: "Cost of Expense",
          accessor: "cost_of_expense",
          Filter: SelectColumnFilter,
          filter: "includes"
        },
        {
          Header: "Client Checked Job",
          accessor: "client_checked_job",
          Filter: SelectColumnFilter,
          filter: "includes"
        },
        {
          Header: "Personal Rating",
          accessor: "personal_rating",
          Filter: SelectColumnFilter,
          filter: "includes"
        },
        {
          Header: "Doll Rolls Replaced",
          accessor: "number_of_dog_rolls",
          Filter: SelectColumnFilter,
          filter: "includes"
        },
        {
          Header: "Number of Singple Family Homes",
          accessor: "number_of_single_family_homes",
          Filter: SelectColumnFilter,
          filter: "includes"
        },
        {
          Header: "Number of Town Homes",
          accessor: "number_of_town_homes",
          Filter: SelectColumnFilter,
          filter: "includes"
        },
      ]
    },
    {
      Header: "Submission Information",
      columns: [
          {
              Header: "Timestamp",
              accessor: "timestamp",
              // Use a two-stage aggregator here to first
              // count the total rows being aggregated,
              // then sum any of those counts if they are
              // aggregated further
              aggregate: "count",
              Aggregated: ({ value }) => `${value} Names`
            },
            {
              Header: "Job Submitted By",
              accessor: "job_submitted_by",
              // Use a two-stage aggregator here to first
              // count the total rows being aggregated,
              // then sum any of those counts if they are
              // aggregated further
              aggregate: "count",
              Aggregated: ({ value }) => `${value} Names`
            },
            {
              Header: "Last Modified",
              accessor: "last_modified",
              // Use a two-stage aggregator here to first
              // count the total rows being aggregated,
              // then sum any of those counts if they are
              // aggregated further
              aggregate: "count",
              Aggregated: ({ value }) => `${value} Names`
            },
            {
              Header: "Last Modified By",
              accessor: "last_modified_by",
              // Use a two-stage aggregator here to first
              // count the total rows being aggregated,
              // then sum any of those counts if they are
              // aggregated further
              aggregate: "count",
              Aggregated: ({ value }) => `${value} Names`
            },
      ]
    }
  ]

export default lawnMaintenanceColumns;