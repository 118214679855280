import React, { useState, useEffect } from 'react';
import GoogleMapReact from 'google-map-react';
import { FaMapMarkerAlt } from 'react-icons/fa'; // Import marker icon from react-icons library
import { withOktaAuth, useOktaAuth } from '@okta/okta-react';
import { Button } from 'react-bootstrap';

const Marker = ({ text, onClick }) => (
  <div onClick={onClick}>
    <FaMapMarkerAlt size={36} color="red" />
    <div className="popup">
      <div className="title" style={{fontWeight: 'bold', fontSize: '20px'}}>{text}</div>
      {/* <div className="address">
        <div>3831 Brentwood Rd</div>
        <div>Raleigh, NC 27604</div>
      </div> */}
      {/* <div className="view-link">
        <a href="https://maps.google.com/maps?ll=35.833735,-78.594516&amp;z=15&amp;t=m&amp;hl=en-US&amp;gl=US&amp;mapclient=apiv3&amp;cid=877981032203250119" target="_blank" rel="noopener noreferrer">
          View on Google Maps
        </a>
      </div> */}
    </div>
  </div>
);



// const locations = [
//   { id: 1, lat: 35.820243506626723, lng: -78.59013977647454, name: 'Worked One' },
//   { id: 2, lat: 35.830243506626723, lng: -78.60013977647454, name: 'Worker 2' },
//   { id: 3, lat: 35.840243506626723, lng: -78.61013977647454, name: 'Worker 3' },
// ];

export default withOktaAuth( function SimpleMap(props) {
  const [center, setCenter] = useState({ lat: 35.82024350662672, lng: -78.59013977647454 });
  const [zoom, setZoom] = useState(13);
  const [selectedMarker, setSelectedMarker] = useState(null);

  const[locationMarkers,  setLocationsMarkers] =  useState([]);
  const {REACT_APP_GOOGLE_MAPS_API_KEY} = process.env;

  useEffect(()=>{
    getEstimateData();
 }, [])

  const handleLocationClick = (location) => {
    console.log(location);
    setCenter({ lat: location.latitude, lng: location.longitude });
    setZoom(20); // Optionally, adjust the zoom level
  };

  const handleMarkerClick = (location) => {
    setSelectedMarker(location);
  };

  const  getEstimateData = async () =>{
    const { REACT_APP_SERVER_URL } = process.env;

    try{
      const response = await fetch(`${REACT_APP_SERVER_URL}/Location/getLocations`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + props.authState.accessToken.accessToken,
            'data-request': 'read'
        },
      });

      if(response.status === 401){
        throw new Error("Your login time has expired, please log in again");
      }

      let locationData = await response.json();
      locationData.push(
        {
        user_name: 'Four Seasons',
        id: -1,
        latitude: 35.82024350662672,
        longitude: -78.59013977647454,
        submission_timestamp: null }
      )
      setLocationsMarkers(locationData);
    } catch (err) {
      // handle error as needed
      console.log(err);
      alert(err);
    }
  }

  const mapOptions = {
    center: center,
    zoom: zoom,
  };

  return (
    <div style={{ display: 'flex', height: '100vh', width: '100%' }}>
      <ul style={{ listStyleType: 'none', padding: 0, margin: 0, width: '20%' }}>
        {locationMarkers.map((location) => (
      <Button
        key={location.id}
        onClick={() => handleLocationClick(location)}
        variant="link"
        
        style={
          { width: '100%', 
            textAlign: 'left',
        //     marginBottom: '5px',
        //     backgroundColor: '#9b9797', 
        //     boxShadow: '0 2px 5px rgba(0, 0, 0, 0.1)',
            // margin: '10px, 0, 0, -3px',
            // padding: '3px', // Add padding for spacing
        //     transition: 'background-color 0.3s', // Add transition for smooth hover effect
        //     ':hover': { // Define hover effect
        //       backgroundColor: '#000' // Darken the background color when hovered over
            }
          }
      >
        <strong>{location.user_name}</strong> - {new Date(location.submission_timestamp).toString().substring(4, 25)}
      </Button>
        ))}
      </ul>
      <div style={{ width: '80%' }}>
        <GoogleMapReact
          bootstrapURLKeys={{ key: REACT_APP_GOOGLE_MAPS_API_KEY }}
          center={mapOptions.center}
          zoom={mapOptions.zoom}
        >
          {locationMarkers.map((location) => (
            <Marker
              key={location.id}
              lat={location.latitude}
              lng={location.longitude}
              text={location.user_name} // Pass the location name as text to the Marker component
              onClick={() => 
                handleMarkerClick(location)}
            />
          ))}
        </GoogleMapReact>
        {/* {selectedMarker && (
          <div className="popup-container">
            <div className="popup">
              <div className="title">{selectedMarker.name}</div>
              <div className="address">
                <div>3831 Brentwood Rd</div>
                <div>Raleigh, NC 27604</div>
              </div>
              <div className="view-link">
                <a href="https://maps.google.com/maps?ll=35.833735,-78.594516&amp;z=15&amp;t=m&amp;hl=en-US&amp;gl=US&amp;mapclient=apiv3&amp;cid=877981032203250119" target="_blank" rel="noopener noreferrer">
                  View on Google Maps
                </a>
              </div>
            </div>
          </div>
        )} */}
      </div>
    </div>
  );
        
});

// function formatDate(timestamp){
//   const date = new Date(timestamp);
//   const timeZoneDate = new Intl.DateTimeFormat('en-US', {
//     dateStyle: 'full',
//     timeStyle: 'short',
//     timeZone: 'America/New_York',
//   }).format(date);

//   return timeZoneDate;
// }
