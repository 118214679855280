import React, { Component } from 'react';
import { Button, Spinner, Modal, Row, Col, Form, FloatingLabel, InputGroup } from 'react-bootstrap';
import { Formik, Field } from 'formik';
import fetch from 'isomorphic-fetch';
import * as Yup from 'yup';
import Select from 'react-select';
import { withOktaAuth, useOktaAuth } from '@okta/okta-react';

export default withOktaAuth( class AddInvoiceModal extends Component {

  constructor(props) {
    super(props);
    this.state = {
      show: false,
      close: false,
      submitButtonDisabled: false,
      saveButtonDisabled: false,
      validated: false,
      setValidated: false,
      addButtonDisabled: false,
      selectedJobTypeOption: null,
    };

    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleJobTypeSelectChange = this.handleJobTypeSelectChange.bind(this);

    this.schema = Yup.object().shape({
      // date: Yup.string().required("Please enter a date"),
      customer: Yup.string().min(2, 'Name must be longer than 1 character').required("Please enter a customer name"),
      invoiceAmount: Yup.number().typeError('Invoice Amount must be a Number').nullable().required("Please enter an invoice amount"),
      invoiceNumber: Yup.number().typeError('Invoice Number must be a Number').nullable(),
    });

    this.jobTypeOptions = [
      { value: 'Landscaping', label: 'Landscaping' },
      { value: 'Trash', label: 'Trash' },
    ];

  }

  handleJobTypeSelectChange = (selectedJobType) => {
    this.setState({ selectedJobType });
    // this.props.updateCustomerName(selectedOption)
    this.setState({selectedJobTypeOption: selectedJobType.value});
  };

  
  handleSubmit = async (formData, resetForm) => {
    this.setState({submitButtonDisabled: true});

    let userInfo =  await this.props.oktaAuth.token.getUserInfo();

    let userName = userInfo.name;
    formData = this.replaceEmptyStringWithNull(formData);

    let accessToken = this.props.authState.accessToken.accessToken;

    formData["jobType"] = this.state.selectedJobTypeOption;

    // const inputDate = new Date(formData.date);

    // // Format the date as 'M/D/YYYY'
    // const formattedDate = inputDate.toLocaleDateString('en-US', {
    //   month: 'numeric',
    //   day: 'numeric',
    //   year: 'numeric',
    // });
    
    // formData.date = formattedDate;


    // console.log(formData);

    await this.props.submitInvoice(formData, resetForm, this.resetState, userName, accessToken);
    this.setState({submitButtonDisabled: false});
  };

  replaceEmptyStringWithNull(obj) {
    for (const key in obj) {
      if (obj.hasOwnProperty(key) && typeof obj[key] === 'string' && obj[key].trim() === '') {
        obj[key] = null;
      }
    }
    return obj;
  }

  resetState = () => {
    this.setState({selectedJobType: ''})
  }


  render() {
    const { selectedJobType } = this.state;
    
    return (
      <div>
        <Button
          data-cy={"add-invoice-button"}
          variant={this.props.variant} 
          onClick={() => {
            this.setState({ show: true }); 
            // this.props.getCustomerNames();
          }}
          //disabled={this.props.submitButtonDisabled}
        >
          { this.props.buttonName}
        </Button>
        <Modal
          show={this.state.show}
          onHide={() => this.setState({ show: false })}
          animation={true}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          className=""
          shadow-lg="border">
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              Add Invoice
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>
              <h1>Add Invoice</h1>
              <Formik
                validationSchema={this.schema}
                initialValues={{
                  customer: '',
                  invoiceAmount: '',
                  invoiceNumber: '',
                  // date: '',
                  invoiceDate: '',
                  jobType: '',
                  jobDescription: ''
                }}
                onSubmit={(values, { resetForm }) =>{
                  this.handleSubmit(values, resetForm);
                }

                }
              >
                {({
                  handleSubmit,
                  handleChange,
                  handleBlur,
                  resetForm,
                  values,
                  touched,
                  isValid,
                  errors,
                }) => (
                  <Form onSubmit={handleSubmit}>
                  <Row className="mb-3">
                    {/* <Form.Group as={Col} controlId="dob" md="4">
                        <Form.Label>Date</Form.Label>
                        <Form.Control
                          data-cy={"add-invoice-modal-date"}
                          type="date"
                          name="date"
                          placeholder="Start Date"
                          value={values.date}
                          onChange={handleChange}
                          isValid={touched.date && !errors.date}
                          isInvalid={touched.date && !!errors.date}
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.date}
                        </Form.Control.Feedback>
                        <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                    </Form.Group> */}
                      <Form.Group as={Col} controlId="dob" md="4">
                        <Form.Label>Customer</Form.Label>
                        <Form.Control 
                          data-cy={"add-invoice-modal-customer"}
                          type="text" 
                          name="customer" 
                          value={values.customer}
                          onChange={handleChange}
                          isValid={touched.customer && !errors.customer}
                          isInvalid={touched.customer && !!errors.customer}
                          placeholder="Name" 
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.customer}
                        </Form.Control.Feedback>
                        <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                      </Form.Group>
                  </Row>
                    <Row className="mb-3">
                    </Row>
                    <Row className="mb-3">
                      <Form.Group as={Col} md="3" controlId="validationFormik04">
                        <Form.Label>Amount To Invoice</Form.Label>
                        <Form.Control
                          data-cy={"add-invoice-modal-amount-to-invoice"}
                          type="text"
                          placeholder="Invoice Amount"
                          name="invoiceAmount"
                          value={values.invoiceAmount}
                          onChange={handleChange}
                          isValid={touched.invoiceAmount && !errors.invoiceAmount}
                          isInvalid={touched.invoiceAmount && !!errors.invoiceAmount}
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.invoiceAmount}
                        </Form.Control.Feedback>
                        <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                      </Form.Group>
                      <Form.Group as={Col} md="3" controlId="validationFormik04">
                        <Form.Label>Invoice #</Form.Label>
                        <Form.Control
                          data-cy={"add-invoice-modal-invoice-number"}
                          type="text"
                          placeholder="Invoice #"
                          name="invoiceNumber"
                          value={values.invoiceNumber}
                          onChange={handleChange}
                          isValid={touched.invoiceNumber && !errors.invoiceNumber}
                          isInvalid={touched.invoiceNumber && !!errors.invoiceNumber}
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.invoiceNumber}
                        </Form.Control.Feedback>
                        <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                      </Form.Group>
                      <Form.Group as={Col} controlId="dob" md="4">
                        <Form.Label>Invoice Date</Form.Label>
                        <Form.Control
                          data-cy={"add-invoice-modal-invoice-date"}
                          type="date"
                          name="invoiceDate"
                          value={values.invoiceDate}
                          onChange={handleChange}
                          isValid={touched.invoiceDate && !errors.invoiceDate}
                          isInvalid={touched.invoiceDate && !!errors.invoiceDate}
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.invoiceDate}
                        </Form.Control.Feedback>
                        <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                    </Form.Group>
                    </Row>
                    <Row className="mb-3">
                      <Form.Group as={Col} controlId="dob" md="4">
                        <br/>
                          <Form.Label>Job Type </Form.Label>
                          <Select
                            data-cy={`add-invoice-modal-job-type`}
                            inputId={`add-invoice-modal-job-type-field`}
                            name="jobType"
                            value={selectedJobType}
                            onChange={this.handleJobTypeSelectChange}
                            options={this.jobTypeOptions}
                            placeholder={"Job Type"}
                          />
                          {/* <Form.Control 
                          type="text" 
                          name="cleanNumber" 
                          value={values.cleanNumber}
                          onChange={handleChange}
                          isValid={touched.cleanNumber && !errors.cleanNumber}
                          isInvalid={touched.cleanNumber && !!errors.cleanNumber}
                          placeholder="Clean Number" /> */}
                          <Form.Control.Feedback type="invalid">
                            {errors.jobType}
                          </Form.Control.Feedback>
                          <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                        </Form.Group>
                    </Row>
                    <Row className="mb-3">
                      <Form.Group as={Col} md="6" controlId="validationFormik06">
                      <Form.Label>Job Description</Form.Label>
                      <Form.Control
                        data-cy={"add-invoice-modal-jobDescription"}
                        type="textarea"
                        style={{ height: '100px' }}
                        placeholder="Notes Here"
                        name="jobDescription"
                        value={values.jobDescription}
                        onChange={handleChange}
                        isValid={touched.jobDescription && !errors.jobDescription}
                      />
                      <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                  </Form.Group>
                    </Row>
                    <Button
                      data-cy={"invoice-modal-submit-button"}
                      onClick={() =>{
                      }}
                      disabled={this.state.submitButtonDisabled}
                      type="submit"
                    >
                      {
                        this.state.submitButtonDisabled ?
                          <div>Submitting
                            <Spinner animation="border" size="sm" ></Spinner>
                          </div> :
                          'Submit form'
                      }
                    </Button>
                  </Form>
                )}
              </Formik>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button 
              data-cy={"invoice-modal-close-button"}
              onClick={() => this.setState({ show: false })}
            >
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
})
