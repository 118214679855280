import fetch from 'isomorphic-fetch';
import React, { Component } from 'react';
import { withOktaAuth, useOktaAuth } from '@okta/okta-react';
import { Button, Spinner, Container, Row, Col } from 'react-bootstrap'
import TrackTable from './Components/TrackTable';
import AddCleaningCrewModal from './Components/AddCleaningCrewModal';
import {submitCleaningCrew} from './helpers/Cleaning/cleaningCrew';
import {makeData} from './Components/makeData';
import TableComponent from './Components/TableComponent';
import cleaningCrewColumns from './Components/Table/Columns/CleaningCrew';

let page;

export default withOktaAuth(class MessageList extends Component {


  constructor(props) {
    
    super(props)
    this.getCleaningCrews = this.getCleaningCrews.bind(this);
    this.editCleaningCrewData = this.editCleaningCrewData.bind(this);
    this.deleteCleaningCrewData = this.deleteCleaningCrewData.bind(this);
    this.updateSaveButton = this.updateSaveButton.bind(this);
    this.updateRowSelected = this.updateRowSelected.bind(this);
    this.showAddCustomerModel = this.showAddCustomerModel.bind(this);
    this.updateCustomerName = this.updateCustomerName.bind(this);

    const { REACT_APP_SERVER_PORT } = process.env;
    this.serverPort = REACT_APP_SERVER_PORT
    
    this.state = {
      messages: null,
      landscapingData: null,
      userInfo: null,
      startDate: '',
      endDate: '',
      userGroups: null,
      showLandscaping: false,
      showCleaningCrew: false,
      landscapingDataIsLoading: false,
      crewDataIsLoading: false,
      submitButtonDisabled: false,
      saveButtonDisabledLoading: false,
      showCustomerModel: true,
      customerName: '',
      crewData: null
    }

    this.userGroups = this.props.authState.accessToken.claims["Add-Groups"];

    this.adminGroup = "Admin";
    this.adminReadGroup = "Admin-Read";
    this.userGroup = "Users"
    this.adminCleanGroup = "Cleaning-Admin";

    this.showCustomerModel = false;

    this.state = {value: ''};

    this.handleChangeStartDate = this.handleChangeStartDate.bind(this);
    this.handleChangeEndDate = this.handleChangeEndDate.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleChangeStartDate(event) {
  this.setState({startDate: event.target.value});
}

  handleChangeEndDate(event) {
  this.setState({endDate: event.target.value});
  }

  handleSubmit(event) {
    alert(this.state.startDate + ' ' + this.state.endDate);
    event.preventDefault();
  }

  showAddCustomerModel(){
    this.showCustomerModel = !this.showCustomerModel
    this.setState({showAddCustomerModel:this.showCustomerModel});
  }

  async componentDidMount() {
    this.setState({saveButtonDisabled: true});
    this.setState({showAddCustomerModel: false});
    if(this.userGroups.includes("Admin") ||
    this.userGroups.includes(this.adminCleanGroup)){
      this.getCleaningCrews();
    }
  }


  async getCleaningCrews(){
      this.setState({showCleaningCrew: false});
      this.setState({crewDataIsLoading: true});
      try {
          // const { REACT_APP_SERVER_PORT } = process.env;
          // let serverPort = REACT_APP_SERVER_PORT
          
          let userInfo = await this.props.oktaAuth.token.getUserInfo();
          this.setState({ userInfo: userInfo });
          const { REACT_APP_SERVER_URL } = process.env;
          const response = await fetch(`${REACT_APP_SERVER_URL}/CleaningCrew/`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + this.props.authState.accessToken.accessToken,
                'data-request': 'read'
            },
            body: JSON.stringify({
                // Validation data coming from a form usually
                startDate: this.state.startDate,
                endDate: this.state.endDate
            }) 
          })

          let test = await response.json();

          this.setState({ crewData: test });
          this.setState({showCleaningCrew: true});
          this.setState({crewDataIsLoading: false});

        } catch (err) {
          console.log(err);
          alert(err);
          this.setState({crewDataIsLoading: false});
          
        }
  }


  async editCleaningCrewData(editData){
    try {
      const { REACT_APP_SERVER_URL } = process.env;
      const { REACT_APP_SERVER_PORT } = process.env;
      let serverPort = REACT_APP_SERVER_PORT
      let userInfo = await this.props.oktaAuth.token.getUserInfo();
      this.setState({ userInfo: userInfo });
      this.setState({saveButtonDisabledLoading: true});

      const response = await fetch(`${REACT_APP_SERVER_URL}/CleaningCrew/editCleaningCrew`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + this.props.authState.accessToken.accessToken,
            'data-request': 'read'
        },
        body: JSON.stringify({
            // Validation data coming from a form usually
            editData: editData,
            'user': this.state.userInfo.name
        }) 
      })
      if(response.status === 401){
        throw new Error("Your login time has expired, please log in again");
      }

      if(response.status === 200){
        alert('Data Has Updated');
      }
      if(response.status > 401){
        alert(`error: ${response.statusText}`);
      }
      let test = await response.json();
      this.setState({ messages: test });
      this.setState({saveButtonDisabledLoading: false});
      await this.getCleaningCrews();
    } catch (error) {
      // handle error as needed
      console.log(error);
      alert(error);
      this.setState({saveButtonDisabledLoading: false});
    }
  }

  async deleteCleaningCrewData(editData){
    try {
      const { REACT_APP_SERVER_URL } = process.env;
      const { REACT_APP_SERVER_PORT } = process.env;
      let serverPort = REACT_APP_SERVER_PORT

      let userInfo = await this.props.oktaAuth.token.getUserInfo();
      this.setState({ userInfo: userInfo });
      this.setState({saveButtonDisabledLoading: true});
      const response = await fetch(`${REACT_APP_SERVER_URL}/CleaningCrew/deleteCleaningCrew`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + this.props.authState.accessToken.accessToken,
            'data-request': 'read'
        },
        body: JSON.stringify({
            // Validation data coming from a form usually
            editData: editData,
            'user': this.state.userInfo.name
        }) 
      })
      if(response.status === 401){
        throw new Error("Your login time has expired, please log in again");
      }

      if(response.status === 204){
        alert('Data was Deleted');
      }
      if(response.status > 401){
        alert(`error: ${response.statusText}`);
      }
      this.setState({saveButtonDisabledLoading: false});
      await this.getCleaningCrews();

    } catch (error) {
      // handle error as needed
      console.log(error);
      alert(error);
      this.setState({saveButtonDisabledLoading: false});
    }
  }


  updateSaveButton(buttonState){
    let allowedGroups = 
      (this.userGroups.includes("Admin") || 
      this.userGroups.includes(this.adminCleanGroup));
    if(allowedGroups){
      this.setState({saveButtonDisabled:buttonState});
    }

  }

  updateCustomerName(customerName){
    this.setState({customerName: customerName.value});
  }


  updateRowSelected(isRowSelected){
    this.setState({rowSelected:isRowSelected})
  }

  render() {
    let content;
    if (!(typeof this.state.crewData === 'undefined') && (this.state.showCleaningCrew)){ 
        content = 
                   <TableComponent 
                   tableColumns = {cleaningCrewColumns}
                   tableData = {this.state.crewData}
                   saveButtonDisabled={this.state.saveButtonDisabled}
                   saveButtonDisabledLoading={this.state.saveButtonDisabledLoading}
                   rowSelected={this.state.rowSelected}
                   editData={this.editCleaningCrewData}
                   deleteData={this.deleteCleaningCrewData}
                   updateSaveButton={this.updateSaveButton}
                   columnsToHide={this.state.columnsToHide}
                   showLegend={false}
                   printFilename={`Cleaning_Crew_Report`}
                 />;
    } 

    return <div>
          <h1>Cleaning Crews </h1>
        { (this.userGroups.includes(this.adminGroup) || 
          this.userGroups.includes(this.adminReadGroup) || 
          this.userGroups.includes(this.adminCleanGroup)) &&
        <div>
          {
            this.state.crewDataIsLoading &&
            <div>
              <Container>
                <Row>
                  <Col xs={6}></Col>
                  <Col xs={6}>
                    <h1>Loading...</h1>
                    <Spinner animation="border" variant="success" style={{width: "10rem", height: "10rem"}}/>
                    </Col>
                </Row>
            </Container>
           </div>
          }
      </div>
        }
      { ((this.userGroups.includes(this.adminGroup) || this.userGroups.includes(this.adminCleanGroup)) 
          && !(typeof this.state.crewData === 'undefined') ) &&
        <AddCleaningCrewModal
          onHide={this.showAddCustomerModel}
          submitCustomerTrackingData = {this.submitCustomerTrackingData}
          updateCustomerName = {this.updateCustomerName}
          getCustomerNames = {this.getCustomerNames}
          show={this.state.showAddCustomerModel}
          userGroups={this.userGroups}
          userInfo={this.state.userInfo}
          customerName = {this.state.customerName}
          crewData = {this.state.crewData}
          submitButtonDisabled = {this.state.submitButtonDisabled}
          buttonName = {"Add Crew"}
          variant = {"secondary"}
          submitCleaningCrew = {submitCleaningCrew}
          cleanType =  {"Power Washing"}
        />
    }
        {content}
    </div>  
  }
});