import React from "react";
import styled from "styled-components";
import { Button, Spinner } from 'react-bootstrap'
import {
  useTable,
  usePagination,
  useSortBy,
  useFilters,
  useGroupBy,
  useExpanded,
  useRowSelect
} from "react-table";
import { matchSorter } from "match-sorter";

import {
    SelectColumnFilter,
} from '../TableFilters';

import {
    EditableCellTime,
    DropDownInvoiceJobType,
    EditableDateCell,
    EditableCheckboxCell,
    EditableNotesCell


} from '../TableCells';

function toCurrency(numberString) {
  let number = parseFloat(numberString);
  return `$${number.toLocaleString('USD')}`;
}

const invoiceColumns = [
      {
        Header: "Customer Information",
        columns: [
          {
            Header: "Date",
            accessor: "submission_timestamp",
            aggregate: "count",
            Aggregated: ({ value }) => `${value} Names`,
            isVisible: false,
            toggleHidden: true
          },
          {
            Header: "Customer",
            accessor: "customer",
            // Filter: SelectColumnFilter,
            // filter: "includes",
            isVisible: false,
            toggleHidden: true,
            Cell: EditableNotesCell,
          },
          {
            Header: "Job Description",
            accessor: "job_description",
            // Filter: SelectColumnFilter,
            // filter: "includes",
            isVisible: false,
            toggleHidden: true,
            Cell: EditableNotesCell,
          },
          {
            Header: "Amount To Invoice",
            accessor: "invoice",
            aggregate: "count",
            // Filter: SelectColumnFilter,
            // filter: "includes",
            // Cell: EditableCheckboxCell,
            Aggregated: ({ value }) => `${value} Names`,
            isVisible: false,
            toggleHidden: true,
            Cell: ({ value })=> <React.Fragment>{toCurrency(value)}</React.Fragment>
          },
          {
            Header: "Invoice #",
            accessor: "invoice_number",
            aggregate: "count",
            Aggregated: ({ value }) => `${value} Names`,
            isVisible: false,
            toggleHidden: true
          },
          {
            Header: "Date Invoiced",
            accessor: "invoice_date",
            aggregate: "count",
            Aggregated: ({ value }) => `${value} Names`,
            Cell: EditableDateCell
          },
          {
            Header: "Job Type",
            accessor: "job_type",
            Filter: SelectColumnFilter,
            filter: "includes",
            Cell: DropDownInvoiceJobType,
          },
          {
            Header: "Invoice Completed",
            accessor: "invoice_completed",
            Filter: SelectColumnFilter,
            filter: "includes",
            Cell: EditableCheckboxCell,
          },
        ]
      },
      {
        Header: "Submission Info",
        columns: [
              {
                Header: "Submitted By",
                accessor: "submitted_by",
                aggregate: "count",
                Aggregated: ({ value }) => `${value} Names`,
                isVisible: false,
                toggleHidden: true
              },
              // {
              //   Header: "Submission Time",
              //   accessor: "date_submitted",
              //   aggregate: "count",
              //   Aggregated: ({ value }) => `${value} Names`,
              //   Cell: EditableCellTime,
              // },
              {
                Header: "Last Modified",
                accessor: "last_modified",
                aggregate: "count",
                Aggregated: ({ value }) => `${value} Names`,
                Cell: EditableCellTime,
              },
              {
                Header: "Last Modified By",
                accessor: "last_modified_by",
                aggregate: "count",
                Aggregated: ({ value }) => `${value} Names`,
                isVisible: false,
                toggleHidden: true
              },
        ]
      }
    ]

export default invoiceColumns;
