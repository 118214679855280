import {
    SelectColumnFilter,
} from '../TableFilters';

import {
    EditableCellTime,
    EditableCheckboxCell,
    EditableNotesCell

} from '../TableCells';


const turfTreatmentColumns = [
  {
      Header: "Turf Treatment Information",
      columns: [
          {
              Header: "Customer Name",
              accessor: "customer_name",
              aggregate: "count",
              Aggregated: ({ value }) => `${value} Names`
          },
          {
              Header: "Notes",
              accessor: "notes",
              aggregate: "count",
              Aggregated: ({ value }) => `${value} Names`,
              Cell: EditableNotesCell,
          },
          {
              Header: "People Worked",
              accessor: "people_worked",
              Filter: SelectColumnFilter,
              filter: "includes"
          },
          {
              Header: "Crew",
              accessor: "crew",
              Filter: SelectColumnFilter,
              filter: "includes"
          },
          {
              Header: "Turf Type",
              accessor: "turf_type",
              Filter: SelectColumnFilter,
              filter: "includes"
          },
          {
              Header: "Fertilizer",
              accessor: "fertilizer",
              Filter: SelectColumnFilter,
              filter: "includes",
              Cell: EditableCheckboxCell,
          },
          {
              Header: "Post Emergent",
              accessor: "post_emergent",
              Filter: SelectColumnFilter,
              filter: "includes",
              Cell: EditableCheckboxCell,
          },
          {
              Header: "Pre Emergent",
              accessor: "pre_emergent",
              Filter: SelectColumnFilter,
              filter: "includes",
              Cell: EditableCheckboxCell,
          },
          {
              Header: "Aeration",
              accessor: "aeration",
              Filter: SelectColumnFilter,
              filter: "includes",
              Cell: EditableCheckboxCell,
          },
          {
              Header: "Lime",
              accessor: "lime",
              Filter: SelectColumnFilter,
              filter: "includes",
              Cell: EditableCheckboxCell,
          },
          {
              Header: "Overseeding",
              accessor: "overseeding",
              Filter: SelectColumnFilter,
              filter: "includes",
              Cell: EditableCheckboxCell,
          },
          {
              Header: "Time Spent",
              accessor: "time_spent",
              Filter: SelectColumnFilter,
              filter: "includes"
          },
          {
              Header: "Volume of Spray",
              accessor: "volume_of_spray",
              Filter: SelectColumnFilter,
              filter: "includes"
          },
          {
              Header: "Chemicals Used",
              accessor: "chemicals_used",
              Filter: SelectColumnFilter,
              filter: "includes"
          },
      ]
  },
  {
      Header: "Submission Information",
      columns: [
          {
              Header: "Timestamp",
              accessor: "submission_timestamp",
              aggregate: "count",
              Cell: EditableCellTime,
          },
          {
              Header: "Submitted By",
              accessor: "job_submitted_by",
              aggregate: "count",
              Aggregated: ({ value }) => `${value} Names`
          },
          {
              Header: "Last Modified",
              accessor: "last_modified",
              aggregate: "count",
              Cell: EditableCellTime,
          },
          {
              Header: "Last Modified By",
              accessor: "last_modified_by",
              aggregate: "count",
          },
      ]
  }
]

export default turfTreatmentColumns;
