import React from 'react';
import jsPDF from 'jspdf';
import 'jspdf-autotable';

const PdfDownloadButton = ({ filename, data, style, reportName }) => {
  const generatePDF = () => {
    const doc = new jsPDF({
      orientation: 'landscape',
    });

    const pageWidth = doc.internal.pageSize.getWidth();
    const margin = 10; // Adjust margin as needed
    const availableWidth = pageWidth - margin * 2;

    
    doc.text(reportName.replaceAll('_', ' '), 20, 10);

    const tableColumn = Object.keys(data[0]);
    const tableRows = data.map(item => tableColumn.map(col => item[col]));

    const numberOfColumns = tableColumn.length;
    const columnWidth = availableWidth / numberOfColumns;// Adjust based on page size and margins

    const columnStyles = {};
    for (let i = 0; i < numberOfColumns; i++) {
      columnStyles[i] = { cellWidth: columnWidth};
    }

    doc.autoTable({
        head: [tableColumn],
        body: tableRows,
        startY: margin + 4, // Adjust to provide some space after the title
        styles: { fontSize: 8 },
        columnStyles: columnStyles,
        theme: 'grid',
        horizontal: 2,
        margin: { left: 10, right: 10, top: margin, bottom: 10 }, // Set custom margins
      });

    doc.save(`${filename}`);
  };

  return (
    <button
      style={style}
      onClick={generatePDF}
    >
      Download PDF
    </button>
  );
};

export default PdfDownloadButton;
