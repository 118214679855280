import fetch from 'isomorphic-fetch';
import React, { Component } from 'react';
import {Prompt} from 'react-router';
import { withOktaAuth, useOktaAuth } from '@okta/okta-react';
import { Button, Spinner, Container, Row, Col} from 'react-bootstrap'
import DataTable from './Components/Table';
import TableComponent from './Components/TableComponent';
import {makeData} from './Components/makeData';
import AddCustomerEstimateModal from "./Components/AddCustomerEstimateModal";
import estimateColumns from './Components/Table/Columns/Estimates';

let page;

export default withOktaAuth(class Estimates extends Component {


  constructor(props) {
    
    super(props)
    this.editCustomerData = this.editCustomerData.bind(this);
    this.deleteCustomerData = this.deleteCustomerData.bind(this);
    this.updateSaveButton = this.updateSaveButton.bind(this);
    this.updateRowSelected = this.updateRowSelected.bind(this);

    this.state = {
      messages: null,
      userInfo: null,
      startDate: '',
      endDate: '',
      userGroups: null,
      saveButtonDisabled: true,
      rowSelected:false,
      columnsToHide: ['true'],
      test:true
    }

    this.userGroups = this.props.authState.accessToken.claims["Add-Groups"];

    this.adminGroup = "Admin";
    this.adminReadGroup = "Admin-Read";
    this.userGroup = "Users"

    this.state = {value: ''};

    this.handleChangeStartDate = this.handleChangeStartDate.bind(this);
    this.handleChangeEndDate = this.handleChangeEndDate.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);

  }

    handleChangeStartDate(event) {
        this.setState({startDate: event.target.value});
    }

    handleChangeEndDate(event) {
        this.setState({endDate: event.target.value});
    }

    handleSubmit(event) {
    alert(this.state.startDate + ' ' + this.state.endDate);
    event.preventDefault();
    }

  async componentDidMount() {
    //let serverPort = REACT_APP_SERVER_PORT
    const tableName = 'estimate_track_data';
    let columnsToHide = await this.getTableSettings(tableName) || [];
    
    this.setState({columnsToHide: columnsToHide});
    this.setState({test: true});

    await this.getEstimateData();
  }

  async getEstimateData(){
    const { REACT_APP_SERVER_URL } = process.env;
    this.setState({saveButtonDisabled: false});
    console.log(`${REACT_APP_SERVER_URL}/Estimates/getAllCustomers/`);
    try{
      const response = await fetch(`${REACT_APP_SERVER_URL}/Estimates/getAllCustomers/`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + this.props.authState.accessToken.accessToken,
            'data-request': 'read'
        },
      });

      if(response.status === 401){
        throw new Error("Your login time has expired, please log in again");
      }

      let test = await response.json();

      for(let row of test){
        for(let value in row){
          row[value] = String(row[value]);
          if(value === 'estimate_request' || 
              value ===  'estimate_sent_to_customer'||
              value === 'estimate_approved' ||
              value === 'work_order_completed'||
              value === 'work_scheduled'||
              value === 'customer_emailed'||
              value === '811_needed'||
              value === '811_called'||
              value === 'invoice_sent'
              ){
            if(row[value] === "true"){
              row[value] = "Completed";
            } else if(row[value] ==="false"){
              row[value] = "Not Needed";
            } else{
              row[value] = "Incomplete";
            }
          } else if(value === 'job_status'){
            if(row[value] === "true"){
              row[value] = "Completed";
            } else if(row[value] ==="false"){
              row[value] = "Inactive";
            } else{
              row[value] = "Active";
            }

          } else if( value === 'estimate_request_time' ||
                    value === 'estimate_sent_to_customer_time' ||
                    value === 'estimate_approved_time' ||
                    value === 'work_order_completed_time' ||
                    value === 'work_scheduled_time' ||
                    value === 'customer_emailed_time' ||
                    value === '811_needed_time' ||
                    value === '811_called_time' 

          ){

            if((row[value] !== "null" && row[value] !== "undefined")){
              row[value] = new Date(row[value]).toLocaleDateString("en-US", {timeZone: "America/New_York"}) || "";
            } else {
              row[value] = "";
            }
          }
          else if(row[value] === "null"|| row[value] === "undefined"){
              row[value] = "";
          }
        }
      }
      this.setState({ messages: test });
    } catch (err) {
      // handle error as needed
      console.log(err);
      alert(err);
    }
  }



  transformDataForEstimatesRequest(editData){

    for(let object of editData){
      for(let value in object){
        if(value === 'estimate_request' || 
              value ===  'estimate_sent_to_customer'||
              value === 'estimate_approved' ||
              value === 'work_order_completed'||
              value === 'work_scheduled'||
              value === 'customer_emailed'||
              value === '811_needed'||
              value === '811_called'||
              value === 'invoice_sent'
          ){
            if(object[value] === "Completed"){
              object[value] = true;
            } else if(object[value] ==="Not Needed"){
              object[value] = false;
            } else{
              object[value] = null;
            }
          } else if(value === 'job_status'){
            if(object[value] === "Completed"){
              object[value] = true;
            } else if(object[value] ==="Inactive"){
              object[value] = false;
            } else{
              object[value] =null;
            }

          } else if(object[value] === "null"|| object[value] === "undefined" || object[value] === ""  ){
              object[value] = null;
          } else if(value === 'estimate_request_time' ||
              value === 'estimate_sent_to_customer_time' ||
              value === 'estimate_approved_time' ||
              value === 'work_order_completed_time' ||
              value === 'work_scheduled_time' ||
              value === 'customer_emailed_time' ||
              value === '811_needed_time' ||
              value === '811_called_time' 
          ) {
            if(object[value] === "null"|| object[value] === "undefined" || object[value] === "" ){
              object[value] = object[value].toUTCString();
            }

          }
      }
    }
  }

  async editCustomerData(editData){
    try {
      const { REACT_APP_SERVER_URL } = process.env;
      const { REACT_APP_SERVER_PORT } = process.env;
      let serverPort = REACT_APP_SERVER_PORT
      let userInfo = await this.props.oktaAuth.token.getUserInfo();
      this.setState({ userInfo: userInfo });
      this.setState({saveButtonDisabledLoading: true});

      this.transformDataForEstimatesRequest(editData);

      const response = await fetch(`${REACT_APP_SERVER_URL}/Estimates/editEstimates`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + this.props.authState.accessToken.accessToken,
            'data-request': 'read'
        },
        body: JSON.stringify({
            // Validation data coming from a form usually
            editData: editData,
            'user': this.state.userInfo.name

        }) 
      })
      if(response.status === 401){
        throw new Error("Your login time has expired, please log in again");
      }

      if(response.status === 200){
        alert('Data Has Updated');
        this.setState({messages: undefined})
      }

      if(response.status > 401){
        console.log(response);
        alert(`error: ${response.statusText}`);
      }
      
      await this.getEstimateData();
      this.setState({saveButtonDisabledLoading: false});
    } catch (error) {
      // handle error as needed
      console.log(error);
      alert(error);
      this.setState({saveButtonDisabledLoading: false});
    }
  }

  updateSaveButton(buttonState){
    if(this.userGroups.includes("Admin")){
      this.setState({saveButtonDisabled:buttonState});
    }
  }

  updateRowSelected(isRowSelected){
    this.setState({rowSelected:isRowSelected});
  }

  async deleteCustomerData(editData){
    try {
      const { REACT_APP_SERVER_URL } = process.env;
      const { REACT_APP_SERVER_PORT } = process.env;
      let serverPort = REACT_APP_SERVER_PORT


        let userInfo = await this.props.oktaAuth.token.getUserInfo();
        this.setState({ userInfo: userInfo });
        this.setState({saveButtonDisabledLoading: true});
        const response = await fetch(`${REACT_APP_SERVER_URL}/Estimates/deleteEstimates`, {
          method: 'POST',
          headers: {
              'Content-Type': 'application/json',
              Authorization: 'Bearer ' + this.props.authState.accessToken.accessToken,
              'data-request': 'read'
          },
          body: JSON.stringify({
              // Validation data coming from a form usually
              editData: editData,
              'user': this.state.userInfo.name
          }) 
        })
        if(response.status === 401){
          throw new Error("Your login time has expired, please log in again");
        }

        if(response.status === 200){
          alert('Data was Deleted');
          this.setState({messages: undefined});
        }
        if(response.status > 401){
          console.log(response);
          alert(`error: ${response.statusText}`);
        }
        await this.getEstimateData();
        this.setState({saveButtonDisabledLoading: false});

    } catch (error) {
      // handle error as needed
      console.log(error);
      alert(error);
      this.setState({saveButtonDisabledLoading: false});
    }
  }

  async getTableSettings(tableName){
    try {
        const { REACT_APP_SERVER_URL } = process.env;
        const { REACT_APP_SERVER_PORT } = process.env;
        let serverPort = REACT_APP_SERVER_PORT
        let userInfo = await this.props.oktaAuth.token.getUserInfo();
        this.setState({ userInfo: userInfo });



        const response = await fetch(`${REACT_APP_SERVER_URL}/TableSetup/getTableSetup/`, {
          method: 'POST',
          headers: {
              'Content-Type': 'application/json',
              Authorization: 'Bearer ' + this.props.authState.accessToken.accessToken,
              'data-request': 'read'
          },
          body: JSON.stringify({
              // Validation data coming from a form usually
              userName: this.state.userInfo.name,
              tableName: tableName
          }) 
        })
        if(response.status === 401){
          throw new Error("Your login time has expired, please log in again");
        }
        let columnsToHideResponse = await response.json();
        columnsToHideResponse = columnsToHideResponse[0].columns_to_hide;
        let columnsToHide = [];

        for(let column of columnsToHideResponse){
          columnsToHide.push(column.replaceAll("\'", ""));
        }
        return columnsToHide;
      } catch (error) {
        // handle error as needed
        console.log(error);
        // alert(error);
      }
  }

  render() {
    let content;
    if ((!(typeof this.state.messages === 'undefined'))){ 
          if (this.userGroups.includes("Admin") || this.userGroups.includes("Admin-Read")){
      
          content = <div>
            <h1
              data-cy="estimates-reports-title"
            >
            Estimates Tracking
            </h1>
            <Prompt
              when={!this.state.saveButtonDisabled}
              message='Are you sure you want to leave page?'
            />
            <AddCustomerEstimateModal
              onHide={this.showAddCustomerModel}
              submitCustomerTrackingData={this.submitCustomerTrackingData}
              updateCustomerName={this.updateCustomerName}
              getCustomerNames={this.getCustomerNames}
              show={this.state.showAddCustomerModel}
              userGroups={this.userGroups}
              userInfo={this.state.userInfo}
              customerName={this.state.customerName}
              customerData={this.state.customerData}
              submitButtonDisabled={this.state.submitButtonDisabled}
            />

            <TableComponent
              tableColumns = {estimateColumns}
              tableData={this.state.messages} 
              saveButtonDisabled={this.state.saveButtonDisabled}
              saveButtonDisabledLoading={this.state.saveButtonDisabledLoading}
              rowSelected={this.state.rowSelected}
              editData={this.editCustomerData}
              updateSaveButton={this.updateSaveButton}
              deleteData={this.deleteCustomerData}
              columnsToHide={this.state.columnsToHide}
              test={this.state.test}
              cumulativeRow={false}
              printFilename={`Estimate_Report_${this.state.startDate}_${this.state.endDate}`}
            />

            </div>
          }
          else{
            content = <h1>Don't know how you got here... but you don't have permission to view this page</h1>
          }
    } 

    
    else{
      content = <div>
        <Container>
          <Row>
            <Col xs={6}></Col>
            <Col xs={6}>
              <h1>Loading...</h1>
              <Spinner animation="border" variant="success" style={{width: "10rem", height: "10rem"}}/>
              </Col>
          </Row>
      </Container>
               </div>
    }
    return <div>
              {content}
          </div>  
  }
});