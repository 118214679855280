import fetch from 'isomorphic-fetch';
import React, { Component } from 'react';
import { withOktaAuth, useOktaAuth } from '@okta/okta-react';
import { Button, Spinner, Container, Row, Col} from 'react-bootstrap'
import DataTable from './Components/Table';
import CustomerTable from './Components/EditCustomerTable';
import {makeData} from './Components/makeData';

let page;

export default withOktaAuth(class Customers extends Component {


  constructor(props) {
    
    super(props)
    this.getAllData = this.getAllData.bind(this);
    this.getAllDataByUser = this.getAllDataByUser.bind(this);
    this.editCustomerData = this.editCustomerData.bind(this);
    this.deleteCustomerData = this.deleteCustomerData.bind(this);
    this.updateSaveButton = this.updateSaveButton.bind(this);
    this.updateRowSelected = this.updateRowSelected.bind(this);
    this.state = {
      messages: null,
      userInfo: null,
      startDate: '',
      endDate: '',
      userGroups: null,
      saveButtonDisabled: true,
      rowSelected:false
    }

    this.userGroups = this.props.authState.accessToken.claims["Add-Groups"];

    this.adminGroup = "Admin";
    this.adminReadGroup = "Admin-Read";
    this.userGroup = "Users"

    this.state = {value: ''};

    this.handleChangeStartDate = this.handleChangeStartDate.bind(this);
    this.handleChangeEndDate = this.handleChangeEndDate.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);

  }

    handleChangeStartDate(event) {
        this.setState({startDate: event.target.value});
    }

    handleChangeEndDate(event) {
        this.setState({endDate: event.target.value});
    }

    handleSubmit(event) {
    alert(this.state.startDate + ' ' + this.state.endDate);
    event.preventDefault();
    }

  async componentDidMount() {
    const { REACT_APP_SERVER_URL } = process.env;
    //let serverPort = REACT_APP_SERVER_PORT
    this.setState({saveButtonDisabled: true});

    console.log(`${REACT_APP_SERVER_URL}/Customers/getAllCustomers/`);

    if (this.userGroups.includes("Admin") || this.userGroups.includes("Admin-Read")){
      try{
        const response = await fetch(`${REACT_APP_SERVER_URL}/Customers/getAllCustomers/`, {
          method: 'GET',
          headers: {
              'Content-Type': 'application/json',
              Authorization: 'Bearer ' + this.props.authState.accessToken.accessToken,
              'data-request': 'read'
          },
        });

        if(response.status === 401){
          throw new Error("Your login time has expired, please log in again");
        }

        let test = await response.json();
        for(let row of test){
          row.active = String(row.active);
        }
        this.setState({ messages: test });

      } catch (err) {
        // handle error as needed
        console.log(err);
        alert(err);
      }
  }
  }


  async getAllData(){
    try {
        const { REACT_APP_SERVER_PORT } = process.env;
        let serverPort = REACT_APP_SERVER_PORT
        let userInfo = await this.props.oktaAuth.token.getUserInfo();
        this.setState({ userInfo: userInfo });

        const response = await fetch(`http://localhost:${serverPort}/TurfMaintenance/`, {
          method: 'POST',
          headers: {
              'Content-Type': 'application/json',
              Authorization: 'Bearer ' + this.props.authState.accessToken.accessToken,
              'data-request': 'read'
          },
          body: JSON.stringify({
              // Validation data coming from a form usually
              startDate: this.state.startDate,
              endDate: this.state.endDate
          }) 
        })
        if(response.status === 401){
          throw new Error("Your login time has expired, please log in again");
        }
        let test = await response.json();
        this.setState({ messages: test });
      } catch (error) {
        // handle error as needed
        console.log(error);
        alert(error);
      }
  }

  async editCustomerData(editData){
    try {
      const { REACT_APP_SERVER_URL } = process.env;
      const { REACT_APP_SERVER_PORT } = process.env;
      let serverPort = REACT_APP_SERVER_PORT
      let userInfo = await this.props.oktaAuth.token.getUserInfo();
      this.setState({ userInfo: userInfo });
      this.setState({saveButtonDisabledLoading: true});

      const response = await fetch(`${REACT_APP_SERVER_URL}/Customers/editCustomers`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + this.props.authState.accessToken.accessToken,
            'data-request': 'read'
        },
        body: JSON.stringify({
            // Validation data coming from a form usually
            editData: editData,
            'user': this.state.userInfo.name

        }) 
      })
      if(response.status === 401){
        throw new Error("Your login time has expired, please log in again");
      }

      if(response.status === 200){
        alert('Data Has Updated');
      }

      if(response.status > 401){
        console.log(response);
        alert(`error: ${response.statusText}`);
      }
      
      let test = await response.json();
      this.setState({ messages: test });
      this.setState({saveButtonDisabledLoading: false});
    } catch (error) {
      // handle error as needed
      console.log(error);
      alert(error);
      this.setState({saveButtonDisabledLoading: false});
    }
  }

  updateSaveButton(buttonState){
    if(this.userGroups.includes("Admin")){
      this.setState({saveButtonDisabled:buttonState});
    }
  }

  updateRowSelected(isRowSelected){
    console.log("Called");
    this.setState({rowSelected:isRowSelected});
  }


  async getAllDataByUser(){
    try {
        const { REACT_APP_SERVER_PORT } = process.env;
        let serverPort = REACT_APP_SERVER_PORT
        let userInfo = await this.props.oktaAuth.token.getUserInfo();
        this.setState({ userInfo: userInfo });

        const response = await fetch(`http://localhost:${serverPort}/TurfMaintenance/byUser`, {
          method: 'POST',
          headers: {
              'Content-Type': 'application/json',
              Authorization: 'Bearer ' + this.props.authState.accessToken.accessToken,
              'data-request': 'read',
          },
          body: JSON.stringify({
              // Validation data coming from a form usually
              startDate: this.state.startDate,
              endDate: this.state.endDate,
              'user': this.state.userInfo.name
          }) 
        })
        if(response.status === 401){
          throw new Error("Your login time has expired, please log in again");
        }

        if(response.status === 200){
          alert('Data Has Updated');
        }

        let test = await response.json();
        this.setState({ messages: test });
      //   this.setState({ messages: data.messages });
      } catch (err) {
        // handle error as needed
        console.log(err);
        alert(err);
      }
  }

  async deleteCustomerData(editData){
    try {
      const { REACT_APP_SERVER_URL } = process.env;
      const { REACT_APP_SERVER_PORT } = process.env;
      let serverPort = REACT_APP_SERVER_PORT


      let response = prompt("To remove customer from app list without deleting, set customer active status to false  \n\nType \"yes\" to permanently delete customer information.", "");
      if(response === 'yes'){
        let userInfo = await this.props.oktaAuth.token.getUserInfo();
        this.setState({ userInfo: userInfo });
        this.setState({saveButtonDisabledLoading: true});
        const response = await fetch(`${REACT_APP_SERVER_URL}/Customers/deleteCustomers`, {
          method: 'POST',
          headers: {
              'Content-Type': 'application/json',
              Authorization: 'Bearer ' + this.props.authState.accessToken.accessToken,
              'data-request': 'read'
          },
          body: JSON.stringify({
              // Validation data coming from a form usually
              editData: editData,
              'user': this.state.userInfo.name
          }) 
        })
        if(response.status === 401){
          throw new Error("Your login time has expired, please log in again");
        }

        if(response.status === 200){
          alert('Data was Deleted');
        }
        if(response.status > 401){
          console.log(response);
          alert(`error: ${response.statusText}`);
        }
        let test = await response.json();
        this.setState({ messages: test });
        this.setState({saveButtonDisabledLoading: false});
      } else{
        alert("Reponse was not yes, canceling delete");
      }

    } catch (error) {
      // handle error as needed
      console.log(error);
      alert(error);
      this.setState({saveButtonDisabledLoading: false});
    }
  }

  render() {
    //return page;
    // console.log("Save button disbaled is: " + this.state.saveButtonDisabled);
    let content;
    if ((!(typeof this.state.messages === 'undefined'))){ 
          if (this.userGroups.includes("Admin")){
      
          content = <div>
            <CustomerTable 
            tableData={this.state.messages} 
            saveButtonDisabled={this.state.saveButtonDisabled}
            saveButtonDisabledLoading={this.state.saveButtonDisabledLoading}
            rowSelected={this.state.rowSelected}
            editCustomerData={this.editCustomerData}
            updateSaveButton={this.updateSaveButton}
            deleteCustomerData={this.deleteCustomerData}
            />
            </div>
          }
          else{
            content = <div>
                        <h1>This Page doesn't exist</h1> 
                        <h3>Click home or use the back arrows to go to the previous page</h3>
                      </div>
          }

            
    } 

    
    else{
      content = <div>
        <Container>
          <Row>
            <Col xs={6}></Col>
            <Col xs={6}>
              <h1>Loading...</h1>
              <Spinner animation="border" variant="success" style={{width: "10rem", height: "10rem"}}/>
              </Col>
          </Row>
      </Container>
               </div>
    }
    return <div>
              {content}
          </div>  
  }
});