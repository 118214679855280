import React from "react";
import styled from "styled-components";
import { Button, Spinner } from 'react-bootstrap'
import ToggleButton from 'react-bootstrap/ToggleButton';
import ToggleButtonGroup from 'react-bootstrap/ToggleButtonGroup';
import ConfirmDeleteModal from './Table/confirmDeleteModal';
import {
  useTable,
  usePagination,
  useSortBy,
  useFilters,
  useGroupBy,
  useExpanded,
  useRowSelect, 
} from "react-table";
import { matchSorter } from "match-sorter";

import CsvDownloadButton from 'react-json-to-csv'

import PdfDownload from './PdfDownload'


import makeData from "./makeData";

const buttonStyles = {
  display: 'inline-block',
  fontWeight: 400,
  textAlign: 'center',
  whiteSpace: 'nowrap',
  verticalAlign: 'middle',
  userSelect: 'none',
  border: '1px solid transparent',
  padding: '0.375rem 0.75rem',
  fontSize: '1rem',
  lineHeight: '1.5',
  borderRadius: '0.25rem',
  transition: 'color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out',
  cursor: 'pointer',
  marginRight: "20px",
  marginBottom: "20px"
};

const primaryButtonStyles = {
  ...buttonStyles,
  color: '#fff',
  backgroundColor: '#007bff',
  borderColor: '#007bff',
};

const primaryButtonHoverStyles = {
  ...primaryButtonStyles,
  backgroundColor: '#0056b3',
  borderColor: '#0056b3',
};


const Styles = styled.div`
  padding: 1rem;

  table {
    border-spacing: 0;
    border: 1px solid black;

    tr {
      :last-child {
        td {
          border-bottom: 0;
        }
      }
    }

    th,
    td {
      margin: 0;
      padding: 0.5rem;
      border-bottom: 1px solid black;
      border-right: 1px solid black;

      :last-child {
        border-right: 0;
      }
    }

    td {
      input {
        font-size: 1rem;
        padding: 0;
        margin: 0;
        border: 0;
      }
    }
  }

  .pagination {
    padding: 0.5rem;
  }

  #customers {
    font-family: Arial, Helvetica, sans-serif;
    border-collapse: collapse;
    width: 100%;
  }
  
  #customers td, #customers th {
    border: 1px solid #ddd;
    padding: 8px;
  }
  
  #customers tr:nth-child(even){background-color: #f2f2f2;}
  
  #customers tr:hover {background-color: #ddd;}
  
  #customers th {
    padding-top: 12px;
    padding-bottom: 12px;
    text-align: left;
    background-color: #212529ba;
    color: white;
  }

  .legend {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
  }
  
  .box {
    width: 20px;
    height: 20px;
    margin-right: 10px;
  }
  
  .text {
    font-size: 14px;
  }

  .border {
    border: 2px solid #000000!important;
    width: 40%;
  }
`;

// Create an editable cell renderer
const EditableCell = ({
  value: initialValue,
  row: row,
  column: { id },
  updateMyData, // This is a custom function that we supplied to our table instance
  editable,
  toggleRowSelected,
  setChangedRows,
  changedRows
}) => {
  // We need to keep and update the state of the cell normally

  if(id == 'active'){
    initialValue = initialValue || false;
  } else{
    initialValue = initialValue || '';
  }

  const [value, setValue] = React.useState(initialValue);

  const onChange = (e) => {
    setValue(e.target.value);

    if(!row.isSelected){
      toggleRowSelected(row.index);
      changedRows.add(row.index);
      setChangedRows(changedRows);
    } else{
      
    }

  };

  // We'll only update the external data when the input is blurred
  const onBlur = () => {
    updateMyData(row.index, id, value);
  };

  // If the initialValue is changed externall, sync it up with our state
  React.useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  if (!editable) {
    return `${initialValue}`;
  }
  // font-weight: bold;
  return <input value={value} onChange={onChange} onBlur={onBlur}   style={{fontWeight: 'bold'}}/>;
};


// Define a default UI for filtering
function DefaultColumnFilter({
  column: { filterValue, preFilteredRows, setFilter }
}) {
  const count = preFilteredRows.length;   

  return (
    <input
      value={filterValue || ""}
      onChange={(e) => {
        setFilter(e.target.value || undefined); // Set undefined to remove the filter entirely
      }}
      placeholder={`Search ${count} records...`}
    />
  );
}


function fuzzyTextFilterFn(rows, id, filterValue) {
  return matchSorter(rows, filterValue, { keys: [(row) => row.values[id]] });
}

// Let the table remove the filter if the string is empty
fuzzyTextFilterFn.autoRemove = (val) => !val;

// Be sure to pass our updateMyData and the skipReset option
function Table({ columns, data, updateMyData, skipReset, tableData, setChangedRows, changedRows, setCurrentRowState, currentRowState, props}) {
  const filterTypes = React.useMemo(
    () => ({
      // Add a new fuzzyTextFilterFn filter type.
      fuzzyText: fuzzyTextFilterFn,
      // Or, override the default text filter to use
      // "startWith"
      text: (rows, id, filterValue) => {
        return rows?.filter((row) => {
          const rowValue = row.values[id];
          return rowValue !== undefined
            ? String(rowValue)
                .toLowerCase()
                .startsWith(String(filterValue).toLowerCase())
            : true;
        });
      }
    }),
    []
  );

  const defaultColumn = React.useMemo(
    () => ({
      // Let's set up our default Filter UI
      Filter: DefaultColumnFilter,
      // And also our default editable cell
      Cell: EditableCell //EditableDropDownCell //EditableCell
    }),
    []
  );

  let hiddenColumnsData = props.columnsToHide || [];

  let initialTableState = {
    hiddenColumns: hiddenColumnsData,
    pageSize: 50,
    filters: [
      {
        id: "job_status",
        value: 'Active',
      },
  ],
  }

  // Use the state and functions returned from useTable to build your UI
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page, // Instead of using 'rows', we'll use page,
    // which has only the rows for the active page
    getToggleHideAllColumnsProps,
    getToggleRowSelectedProps,
    getToggleHiddenProps,
    toggleRowSelected,
    allColumns,
    visibleColumns,
    // The rest of these things are super handy, too ;)
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    rows,
    flatRows,
    rowsById,
    state: {
      pageIndex,
      pageSize,
      sortBy,
      groupBy,
      expanded,
      filters,
      selectedRowIds,
    }
  } = useTable(
    {
      columns,
      data,
      // rows,
      defaultColumn,
      filterTypes,
      initialState: initialTableState,
      // updateMyData isn't part of the API, but
      // anything we put into these options will
      // automatically be available on the instance.
      // That way we can call this function from our
      // cell renderer!
      updateMyData,
      setChangedRows,
      changedRows,
      // We also need to pass this so the page doesn't change
      // when we edit the data.
      autoResetPage: !skipReset,
      autoResetSelectedRows: !skipReset,
      disableMultiSort: false,
      enableMultiSort: true,
      autoResetPage: false,
      autoResetFilters: false,
      autoResetSortBy: false,
    },
    useFilters,
    useGroupBy,
    useSortBy,
    useExpanded,
    usePagination,
    useRowSelect,
    // Here we will use a plugin to add our selection column
    (hooks) => {
      hooks.visibleColumns.push((columns) => {
        return [
          {
            id: "selection",
            // Make this column a groupByBoundary. This ensures that groupBy columns
            // are placed after it
            groupByBoundary: true,
            // The header can use the table's getToggleAllRowsSelectedProps method
            // to render a checkbox
            Header: ({ getToggleAllRowsSelectedProps, rows }) => (
              <div>
                <IndeterminateCheckbox 
                  selectedRowIds = {selectedRowIds}
                  changedRows = {changedRows}
                  setChangedRows = {setChangedRows}
                  printData = {printData}
                  selectRow = {false}
                  selectAllRows = {true}
                  row = {rows[0]}
                  toggleAllRows = {getToggleAllRowsSelectedProps}

                  {...getToggleAllRowsSelectedProps()}
                />
              </div>
            ),
            // The cell can use the individual row's getToggleRowSelectedProps method
            // to the render a checkbox
            Cell: ({ row }) => (
              <div>
                <IndeterminateCheckbox 
                    selectedRowIds = {selectedRowIds}
                    changedRows = {changedRows}
                    printData = {printData}
                    toggleRowSelected = {toggleRowSelected}
                    row = {row}
                    selectRow = {true}
                    {...row.getToggleRowSelectedProps()} 
                />
              </div>
            )
          },
          ...columns
        ];
      });
    }
  );

  function formatKeysForTitle(obj) {
    const formattedObj = {};
    for (const key in obj) {
        if (Object.prototype.hasOwnProperty.call(obj, key)) {
            const formattedKey = key.replace(/_/g, ' ').replace(/\b\w/g, firstChar => firstChar.toUpperCase());
            formattedObj[formattedKey] = obj[key];
        }
    }
    return formattedObj;
}

  let allColumnNames = [];

  for(let column of allColumns){
    allColumnNames.push(column);
  }


  let hiddenColumns = [];
  for(let column of allColumnNames){
    if(!visibleColumns.includes(column))
    hiddenColumns.push(column.id);
  }

  hiddenColumns.push('selection');
  hiddenColumns.push('id');
  hiddenColumns.push('timestamp');
  hiddenColumns.push('submission_timestamp');

  let coolData = JSON.parse(JSON.stringify(data));


  let rowData = [];

  for(let row of rows){
    rowData.push(coolData[row.id]);
  }

  for(let row of rowData){
    for(let columnKey in row){
      if(hiddenColumns.includes(columnKey)){
        delete row[columnKey];
      }
    }
  }

  let printData = rowData;
  
  printData = printData.map(item => formatKeysForTitle(item));

  const [showDeleteModal, setShowDeleteModal] = React.useState(false);

  const [confirmDelete, handleConfirmDelete] = React.useState(false);

  const [rowsToDelete, setRowsToDelete] = React.useState([]);

  const calculateCumulativeTotals = (data) => {
    // Define the columns you want to sum
    const columnsToSum = [
      "revenue",
      "job_cost",
      "inspected_cost",
      "profit"
      // Add more column accessors as needed
    ];

     // Initialize an object to store the sum for each column
  const cumulativeTotals = {};
  columnsToSum.forEach((column) => {
    cumulativeTotals[column] = 0;
  });

  // Calculate the sum for each column
  data.forEach((row) => {
    try{
      columnsToSum.forEach((column) => {
        cumulativeTotals[column] += parseFloat(row?.values[column]?.substring(1,row.values[column].length)) || 0;
      });
    } catch (exception){
    }

  });

  columnsToSum.forEach((column) => {
    cumulativeTotals[column] = cumulativeTotals[column].toFixed(2);
  });

  return cumulativeTotals;
};

let cumulativeRow;
  
// Calculate cumulative totals outside the mapping of data
if(props.cumulativeRow){
  const cumulativeTotals = calculateCumulativeTotals(rows);


  // Render the cumulative row separately
  let visibleColumnsToUse = visibleColumns;

  cumulativeRow = (
    <tr
      style={{
        position: 'sticky',
        top: 140,
        backgroundColor: '#5e6063',
        color: 'white',
        fontWeight: 'bold',
      }}
    >
      {


      visibleColumnsToUse?.map((column)=>{

        if(column.id === "community_name"){
          return (
            <td 
              data-cy={"totals-cell"}
              key={column.id}>
              {"Cumulative Totals"}
            </td>
          )
        }
        else if(column.id === "revenue"){
            return (
              <td 
                data-cy={"revenue-cell"}
                key={column.id}>
                {`$${cumulativeTotals["revenue"]}`}
              </td>
            )
        }
        else if(column.id === "job_cost"){
          return (
            <td 
              data-cy={"job_cost-cell"}
              key={column.id}>
              {`$${cumulativeTotals["job_cost"]}`}
            </td>
          )
        }
        else if(column.id === "inspected_cost"){
          return (
            <td 
              data-cy={"inspected_cost-cell"}
              key={column.id}>
              {`$${cumulativeTotals["inspected_cost"]}`}
            </td>
          )
        }
        else if(column.id === "profit"){
          return (
            <td 
              data-cy={"profit-cell"}
              key={column.id}>
              {`$${cumulativeTotals["profit"]}`}
            </td>
          )
        }
          return (
            <td key={column.id}>
              {/* Display cumulative totals for each numerical column */}
              {/* {cumulativeTotals[column.accessor]} */}
            </td>
          )

        })
      }
    </tr>
  );
} 


  // Render the UI for your table
  return (
    <>
      <div>
        <div>
          <IndeterminateCheckbox 
            data-cy={"toogle-hide-all-columns"}
            {...getToggleHideAllColumnsProps()} 
            toggleHideAllColumns = {getToggleHideAllColumnsProps}
            columnHide = {true}
          /> 
          Toggle All
        </div>
        {/* <ToggleButtonGroup type="checkbox" value={value} >
      <ToggleButton id="tbg-btn-1" value={1}>
        Option 1
      </ToggleButton>
      <ToggleButton id="tbg-btn-2" value={2}>
        Option 2
      </ToggleButton>
      <ToggleButton id="tbg-btn-3" value={3}>
        Option 3
      </ToggleButton>
     </ToggleButtonGroup> */}
        {allColumns.map((column, index) => {
          if(index %6 ===0){
            return <div
                      data-cy={`table-row-${column.id}`} 
                      key={column.id} 
                      style={{display: 'inline-block', padding: '0.2% 0.2% 0.2% 0%', width: '30%'}}
                    >
              <label>
                <input type="checkbox" {...column.getToggleHiddenProps()} />{' '}
                {column.id}
              </label>
              {/* <br /> */}
            </div>
          }
          return <div 
                    data-cy={`table-row-${column.id}`} 
                    key={column.id} 
                    style={{display: 'inline-block', padding: '0.2% 0.2% 0.2% 0%',  width: '30%'}}
                  >
            <label>
              <input type="checkbox" {...column.getToggleHiddenProps()} />{' '}
              {column.id}
            </label>
            {/* <ToggleButton
              id="toggle-check"
              type="checkbox"
              variant="secondary"
              checked={column.isVisible}
              value={column.id}
             onChange={(e) => column.toggleHidden()}
            >
              {column.id}
            </ToggleButton> */}
          </div>
        })}
        <br />
      </div>
      { props.showLegend &&
        <div className ="border">
          <h4>Legend</h4>
          <div className="legend">
            <div className="box" style={{backgroundColor: '#FF0000'}}></div>
            <div className="text">Work order completed but not scheduled for more than a month</div>
          </div>

          <div className="legend">
            <div className="box" style={{backgroundColor: 'yellow'}} ></div>
            <div className="text">Estimate request but no estimate given for 2 weeks</div>
          </div>
        </div>
      }
      <table id="customers" {...getTableProps()}>
        <thead  style={{ 
          position: 'sticky',   
          top: 0,
          color: 'white'
          // zIndex: 10,
          }}>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th 
                  data-cy={`table-header-${column.id}`}
                  {...column.getHeaderProps()}
                >
                  <div>
                    {column.canGroupBy ? (
                      // If the column can be grouped, let's add a toggle
                      <span {...column.getGroupByToggleProps()}>
                        {column.isGrouped ? "🛑 " : "👊 "}
                      </span>
                    ) : null}
                    <span {...column.getSortByToggleProps()}>
                      {column.render("Header")}
                      {/* Add a sort direction indicator */}
                      {column.isSorted
                        ? column.isSortedDesc
                          ? " 🔽"
                          : " 🔼"
                        : ""}
                    </span>
                  </div>
                  {/* Render the columns filter UI */}
                  <div>{column.canFilter ? column.render("Filter") : null}</div>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
        {props.cumulativeRow ? cumulativeRow: null}
          {page.map((row) => {
            prepareRow(row);
            let todaysDate = new Date();
            let estimateDate = new Date(row.values.estimate_request_time);
            let estimateOverdue =  ((todaysDate.getTime() - estimateDate.getTime()) / (1000 * 3600 * 24)) >= 14;

            let workOrderDate = new Date(row.values.work_order_completed_time);
            let workOverdue = ((todaysDate.getTime() - workOrderDate.getTime()) / (1000 * 3600 * 24)) >= 28;

            if(row.values.estimate_sent_to_customer !== 'Completed' && estimateOverdue){
              return (
                <tr {...row.getRowProps()} style={{ backgroundColor: '#fcff00a6' }}>
                  {row.cells.map((cell) => {
                    return (
                      <td {...cell.getCellProps()}>
                        {cell.isGrouped ? (
                          // If it's a grouped cell, add an expander and row count
                          <>
                            <span {...row.getToggleRowExpandedProps()}>
                              {row.isExpanded ? "👇" : "👉"}
                            </span>{" "}
                            {cell.render("Cell", { editable: false })} (
                            {row.subRows.length})
                          </>
                        ) : cell.isAggregated ? (
                          // If the cell is aggregated, use the Aggregated
                          // renderer for cell
                          cell.render("Aggregated")
                        ) : cell.isPlaceholder ? null : ( // For cells with repeated values, render null
                          // Otherwise, just render the regular cell
                          cell.render("Cell", { editable: true })
                        )}
                      </td>
                    );
                  })}
                </tr>
              );

            } else if(row.values.work_scheduled !== 'Completed' && workOverdue){
              return (
                <tr {...row.getRowProps()} style={{ backgroundColor: '#ff0000a1'}}>
                  {row.cells.map((cell) => {
                    return (
                      <td {...cell.getCellProps()}>
                        {cell.isGrouped ? (
                          // If it's a grouped cell, add an expander and row count
                          <>
                            <span {...row.getToggleRowExpandedProps()}>
                              {row.isExpanded ? "👇" : "👉"}
                            </span>{" "}
                            {cell.render("Cell", { editable: false })} (
                            {row.subRows.length})
                          </>
                        ) : cell.isAggregated ? (
                          // If the cell is aggregated, use the Aggregated
                          // renderer for cell
                          cell.render("Aggregated")
                        ) : cell.isPlaceholder ? null : ( // For cells with repeated values, render null
                          // Otherwise, just render the regular cell
                          cell.render("Cell", { editable: true })
                        )}
                      </td>
                    );
                  })}
                </tr>
              );
            } else {
              if(row.values.community_name == 'Cumulative Totals'){
                //save this row and keep it at the top always?
                return (
                  <tr 
                    // class="success"
                    style = {{ 
                      position: 'sticky',   
                      top: 140,
                      backgroundColor: '#5e6063',
                      color: 'white',
                      fontWeight: 'bold'
                      // zIndex: 10,
                      }}
                    {...row.getRowProps()} 
                  >
                    {row.cells.map((cell) => {
                      return (
                        <td {...cell.getCellProps()}>
                          {cell.isGrouped ? (
                            // If it's a grouped cell, add an expander and row count
                            <>
                              <span {...row.getToggleRowExpandedProps()}>
                                {row.isExpanded ? "👇" : "👉"}
                              </span>{" "}
                              {cell.render("Cell", { editable: false })} (
                              {row.subRows.length})
                            </>
                          ) : cell.isAggregated ? (
                            // If the cell is aggregated, use the Aggregated
                            // renderer for cell
                            cell.render("Aggregated")
                          ) : cell.isPlaceholder ? null : ( // For cells with repeated values, render null
                            // Otherwise, just render the regular cell
                            cell.render("Cell", { editable: false })
                          )}
                        </td>
                      );
                    })}
                  </tr>
                );

              } else {
                return (
                  <tr {...row.getRowProps()} >
                    {row.cells.map((cell) => {                      
                      return (
                        <td 
                          data-cy={`row-${cell.row.id}-column-${cell.column.id}-value-${cell.value}`}
                          {...cell.getCellProps()
                        }>
                          {cell.isGrouped ? (
                            // If it's a grouped cell, add an expander and row count
                            <>
                              <span {...row.getToggleRowExpandedProps()}>
                                {row.isExpanded ? "👇" : "👉"}
                              </span>{" "}
                              {cell.render("Cell", { editable: false })} (
                              {row.subRows.length})
                            </>
                          ) : cell.isAggregated ? (
                            // If the cell is aggregated, use the Aggregated
                            // renderer for cell
                            cell.render("Aggregated")
                          ) : cell.isPlaceholder ? null : ( // For cells with repeated values, render null
                            // Otherwise, just render the regular cell
                            cell.render("Cell", { editable: true })
                          )}
                        </td>
                      );
                    })}
                  </tr>
                );

              }
            }

          })}
        </tbody>
      </table>
      {/*
        Pagination can be built however you'd like.
        This is just a very basic UI implementation:
      */}
      <div className="pagination">
        <button onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
          {"<<"}
        </button>{" "}
        <button onClick={() => previousPage()} disabled={!canPreviousPage}>
          {"<"}
        </button>{" "}
        <button onClick={() => nextPage()} disabled={!canNextPage}>
          {">"}
        </button>{" "}
        <button onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>
          {">>"}
        </button>{" "}
        <span>
          Page{" "}
          <strong>
            {pageIndex + 1} of {pageOptions.length}
          </strong>{" "}
        </span>
        <span>
          | Go to page:{" "}
          <input
            type="number"
            defaultValue={pageIndex + 1}
            onChange={(e) => {
              const page = e.target.value ? Number(e.target.value) - 1 : 0;
              gotoPage(page);
            }}
            style={{ width: "100px" }}
          />
        </span>{" "}
        <select
          value={pageSize}
          onChange={(e) => {
            setPageSize(Number(e.target.value));
          }}
        >
          {[10, 20, 30, 40, 50].map((pageSize) => (
            <option key={pageSize} value={pageSize}>
              Show {pageSize}
            </option>
          ))}
        </select>
      </div>
      <Button 
        data-cy={"table-save-button"}
        variant="success" 
        onClick={()=>{
        
        let rowsToEdit = [];
        let originalRows = [];
        let dataObject;
        let rowCheck = true;
        if( !(Object.keys(selectedRowIds).length === 0)){
          let rowsAdded = 0;
          for(let row in selectedRowIds){
            if(JSON.stringify(data[row]) === JSON.stringify(props.tableData[row])){
              let rowInformation = "";
              for(let item in data[row]){
                rowInformation+= `${item}:  ${data[row][item]} \n`
              }
              alert(`data for ${parseInt(row) +1} has not changed, please select a row with changes or unselct row ${parseInt(row) +1} 
              \nRow Information:\n${rowInformation}
              `);
              rowCheck = false;
            }
            else{

              dataObject = JSON.parse(JSON.stringify(data[row]));

              rowsToEdit.push(JSON.parse(JSON.stringify(dataObject)));
            }
            rowsAdded++;
          }
          rowsAdded = 0;
        }
        else{
          alert("Please select the checkbox next to the row(s) you would like to edit");
          rowCheck = false;    
        }

        if(rowCheck ===true){
          props.editData(rowsToEdit);
        }

      }}  
      style = {{"marginRight": "20px", "marginBottom": "20px"}}
      disabled={props.saveButtonDisabled || props.saveButtonDisabledLoading}>
        {
          props.saveButtonDisabledLoading ? 
          <div>Saving
          <Spinner animation="border" size="sm" ></Spinner>
          </div> : 
          'Save'
        }
      </Button>
      <Button 
        data-cy={"table-delete-button"}
        variant="danger" 
        onClick={()=>{
        let rowsToEdit = [];
        let originalRows = [];
        let rowCheck = true;
        if( !(Object.keys(selectedRowIds).length === 0)){
          let rowsAdded = 0;
          for(let row in selectedRowIds){
            rowsToEdit.push(data[row]);
            rowsAdded++;
          }
          rowsAdded = 0;
        }
        else{
          alert("Please select the checkbox next to the row(s) you would like to edit");
          rowCheck = false;        
        }
        if(rowCheck ===true){
          setRowsToDelete(rowsToEdit);
          setShowDeleteModal(true);
        }

      }}  
      style = {{"marginRight": "20px", "marginBottom": "20px"}}
      disabled={props.saveButtonDisabled || props.saveButtonDisabledLoading}>
        {
          props.saveButtonDisabledLoading ? 
          <div>Deleting
          <Spinner animation="border" size="sm" ></Spinner>
          </div> : 
          'Delete'
        }
      </Button>


      <CsvDownloadButton
        data-cy={"table-print-button"}
        delimiter=","
        filename={`${props.printFilename}.csv`}
        data={printData}
        style={buttonStyles} 

        // onMouseOver={() => {}} 
        // onMouseOut={() => {}}
        >
          Download CSV
      </CsvDownloadButton>

      <PdfDownload
        filename={`${props.printFilename}.pdf`}
        data={printData}
        style={buttonStyles}
        reportName={props.printFilename}
      />

      <ConfirmDeleteModal
        showDeleteModal={showDeleteModal}
        handleShowDeleteModal={setShowDeleteModal}
        deleteWorkData={props.deleteData}
        handleConfirmDelete={handleConfirmDelete}
        deleteData={rowsToDelete}
      />
    </>
  );
}

// Define a custom filter filter function!
function filterGreaterThan(rows, id, filterValue) {
  return rows?.filter((row) => {
    const rowValue = row.values[id];
    return rowValue >= filterValue;
  });
}

// This is an autoRemove method on the filter function that
// when given the new filter value and returns true, the filter
// will be automatically removed. Normally this is just an undefined
// check, but here, we want to remove the filter if it's not a number
filterGreaterThan.autoRemove = (val) => typeof val !== "number";

// This is a custom aggregator that
// takes in an array of leaf values and
// returns the rounded median
function roundedMedian(leafValues) {
  let min = leafValues[0] || 0;
  let max = leafValues[0] || 0;

  leafValues.forEach((value) => {
    min = Math.min(min, value);
    max = Math.max(max, value);
  });

  return Math.round((min + max) / 2);
}

const IndeterminateCheckbox = React.forwardRef(
  ({ 
    indeterminate, 
    selectedRowIds,
    changedRows,
    toggleRowSelected,
    row, 
    selectRow,
    columnHide,
    selectAllRows,
    printData,
    toggleHideAllColumns,
    toggleAllRows,
    setChangedRows,
    ...rest }, ref) => {
    const defaultRef = React.useRef();
    const resolvedRef = ref || defaultRef;
    React.useEffect(() => {
      resolvedRef.current.indeterminate = indeterminate;
    }, [resolvedRef, indeterminate]);

    return (
      <>
        <input type="checkbox" ref={resolvedRef} {...rest}

            onClick={()=>{
              if(selectRow){
                toggleRowSelected(row.index);
                if(row.isSelected){
                  if(Object.keys(selectedRowIds).length < changedRows.size){
                    for(let key of changedRows){
                      if(selectedRowIds[key] == null){
                        alert(`Row ${key} has changed data and has been unselected \n
                        It has the following data: \n
                        ${JSON.stringify(printData[key])}`);
                      }
                    }
              }}
              }
               else if(columnHide){
                toggleHideAllColumns();
              } 
              else if(selectAllRows){
                toggleAllRows();
                if(row.isSelected){
                  setChangedRows([]);
                }
              }

            }}
        />
      </>
    );
  }
);

function TableComponent(props) {
  const columns = React.useMemo(
    () => 
    props.tableColumns,
    []
  );

  const [data, setData] = React.useState(props.tableData);
  const [originalData] = React.useState(data);

  const [changedRows, setChangedRows] = React.useState(new Set());

  const [currentRowState, setCurrentRowState] =React.useState(data);

  // We need to keep the table from resetting the pageIndex when we
  // Update data. So we can keep track of that flag with a ref.
  const skipResetRef = React.useRef(false);

  // When our cell renderer calls updateMyData, we'll use
  // the rowIndex, columnId and new value to update the
  // original data
  const updateMyData = (rowIndex, columnId, value) => {
    // We also turn on the flag to not reset the page
    if(props.saveButtonDisabled === true){
      props.updateSaveButton(false);
    }

    skipResetRef.current = true;
    setData((old) =>
      old.map((row, index) => {
        if (index === rowIndex) {
          return {
            ...row,
            [columnId]: value
          };
        }
        return row;
      })
    );
  };

  // After data changes, we turn the flag back off
  // so that if data actually changes when we're not
  // editing it, the page is reset
  // React.useEffect(() => {
  //   skipResetRef.current = false;
  // }, [data]);

  // Let's add a data resetter/randomizer to help
  // illustrate that flow...
  const resetData = () => {
    if(props.saveButtonDisabled === false){
      props.updateSaveButton(true);
    }
    // Don't reset the page when we do this
    // skipResetRef.current = true;
    setData(originalData);
  };



  return (
    <Styles>
      <Button variant="outline-primary" onClick={resetData}>Undo Table Changes</Button>
      <Table
        columns={columns}
        data={data}
        updateMyData={updateMyData}
        skipReset={skipResetRef.current}
        changedRows={changedRows}
        setChangedRows={setChangedRows}
        currentRowState={currentRowState}
        setCurrentRowState={setCurrentRowState}
        props={props}
      />
    </Styles>
  );
}

export default TableComponent;
