import {
    SelectColumnFilter,
    NumberRangeColumnFilter
} from '../TableFilters';

import {
    EditableDropDownJobCell,
    EditableDropDownCell,
    EditableCellTime,
    EditableNotesCell

} from '../TableCells';

const landscapingColumns = [
  {
    Header: "Work Information",
    columns: [
      {
        Header: "Customer Name",
        accessor: "customer_name",
        // Use a two-stage aggregator here to first
        // count the total rows being aggregated,
        // then sum any of those counts if they are
        // aggregated further
        aggregate: "count",
        Aggregated: ({ value }) => `${value} Names`
      },    
      {
        Header: "Notes",
        accessor: "notes",
        // Use a two-stage aggregator here to first
        // count the total rows being aggregated,
        // then sum any of those counts if they are
        // aggregated further
        aggregate: "count",
        Aggregated: ({ value }) => `${value} Names`,
        Cell: EditableNotesCell,
      },
      {
        Header: "Number Of People on Job",
        accessor: "number_of_people_on_job",
        Filter: SelectColumnFilter,
        filter: "includes"
      },
      {
        Header: "Price Of Job",
        accessor: "price_of_job",
        Filter: NumberRangeColumnFilter,
        filter: "between",
        // Aggregate the sum of all visits
        aggregate: "sum",
        Aggregated: ({ value }) => `${value} (total)`
      },
      {
        Header: "Time Worked",
        accessor: "time_worked",
        Filter: NumberRangeColumnFilter,
        filter: "between",
        // Aggregate the sum of all visits
        aggregate: "sum",
        Aggregated: ({ value }) => `${value} (total)`
      },
      {
        Header: "Personal Rating",
        accessor: "personal_rating",
        Filter: SelectColumnFilter,
        filter: "includes"
      },
      {
        Header: "Client Checked Job",
        accessor: "client_checked_job",
        Filter: SelectColumnFilter,
        filter: "includes"
      },
    ]
  },
  {
    Header: "Submission Information",
    columns: [
        {
            Header: "Timestamp",
            accessor: "timestamp",
            // Use a two-stage aggregator here to first
            // count the total rows being aggregated,
            // then sum any of those counts if they are
            // aggregated further
            aggregate: "count",
            Aggregated: ({ value }) => `${value} Names`
          },
          {
            Header: "Job Submitted By",
            accessor: "job_submitted_by",
            // Use a two-stage aggregator here to first
            // count the total rows being aggregated,
            // then sum any of those counts if they are
            // aggregated further
            aggregate: "count",
            Aggregated: ({ value }) => `${value} Names`
          },
          {
            Header: "Last Modified",
            accessor: "last_modified",
            // Use a two-stage aggregator here to first
            // count the total rows being aggregated,
            // then sum any of those counts if they are
            // aggregated further
            aggregate: "count",
            Aggregated: ({ value }) => `${value} Names`
          },
          {
            Header: "Last Modified By",
            accessor: "last_modified_by",
            // Use a two-stage aggregator here to first
            // count the total rows being aggregated,
            // then sum any of those counts if they are
            // aggregated further
            aggregate: "count",
            Aggregated: ({ value }) => `${value} Names`
          },
    ]
  }
]

export default landscapingColumns;