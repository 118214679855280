import React from "react";
import styled from "styled-components";
import { Button, Spinner, } from 'react-bootstrap'
import {
  useTable,
  usePagination,
  useSortBy,
  useFilters,
  useGroupBy,
  useExpanded,
  useRowSelect
} from "react-table";
import { matchSorter } from "match-sorter";


import makeData from "./makeData";

const Styles = styled.div`
  padding: 1rem;

  table {
    border-spacing: 0;
    border: 1px solid black;

    tr {
      :last-child {
        td {
          border-bottom: 0;
        }
      }
    }

    th,
    td {
      margin: 0;
      padding: 0.5rem;
      border-bottom: 1px solid black;
      border-right: 1px solid black;

      :last-child {
        border-right: 0;
      }
    }

    td {
      input {
        font-size: 1rem;
        padding: 0;
        margin: 0;
        border: 0;
      }
    }
  }

  .pagination {
    padding: 0.5rem;
  }
`;

// Create an editable cell renderer
const EditableCell = ({
  value: initialValue,
  row: { index },
  column: { id },
  updateMyData, // This is a custom function that we supplied to our table instance
  editable
}) => {
  // We need to keep and update the state of the cell normally
  const [value, setValue] = React.useState(initialValue);

  const onChange = (e) => {
    setValue(e.target.value);
  };

  // We'll only update the external data when the input is blurred
  const onBlur = () => {
    updateMyData(index, id, value);
  };

  // If the initialValue is changed externall, sync it up with our state
  React.useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  if (!editable) {
    return `${initialValue}`;
  }

  return <input value={value} onChange={onChange} onBlur={onBlur} />;
};

// Define a default UI for filtering
function DefaultColumnFilter({
  column: { filterValue, preFilteredRows, setFilter }
}) {
  const count = preFilteredRows.length;

  return (
    <input
      value={filterValue || ""}
      onChange={(e) => {
        setFilter(e.target.value || undefined); // Set undefined to remove the filter entirely
      }}
      placeholder={`Search ${count} records...`}
    />
  );
}

// This is a custom filter UI for selecting
// a unique option from a list
function SelectColumnFilter({
  column: { filterValue, setFilter, preFilteredRows, id }
}) {
  // Calculate the options for filtering
  // using the preFilteredRows
  const options = React.useMemo(() => {
    const options = new Set();
    preFilteredRows.forEach((row) => {
      options.add(row.values[id]);
    });
    return [...options.values()];
  }, [id, preFilteredRows]);

  // Render a multi-select box
  return (
    <select
      value={filterValue}
      onChange={(e) => {
        setFilter(e.target.value || undefined);
      }}
    >
      <option value="">All</option>
      {options.map((option, i) => (
        <option key={i} value={option}>
          {option}
        </option>
      ))}
    </select>
  );
}

// This is a custom filter UI that uses a
// slider to set the filter value between a column's
// min and max values
function SliderColumnFilter({
  column: { filterValue, setFilter, preFilteredRows, id }
}) {
  // Calculate the min and max
  // using the preFilteredRows

  const [min, max] = React.useMemo(() => {
    let min = preFilteredRows.length ? preFilteredRows[0].values[id] : 0;
    let max = preFilteredRows.length ? preFilteredRows[0].values[id] : 0;
    preFilteredRows.forEach((row) => {
      min = Math.min(row.values[id], min);
      max = Math.max(row.values[id], max);
    });
    return [min, max];
  }, [id, preFilteredRows]);

  return (
    <>
      <input
        type="range"
        min={min}
        max={max}
        value={filterValue || min}
        onChange={(e) => {
          setFilter(parseInt(e.target.value, 10));
        }}
      />
      <button onClick={() => setFilter(undefined)}>Off</button>
    </>
  );
}

// This is a custom UI for our 'between' or number range
// filter. It uses two number boxes and filters rows to
// ones that have values between the two
function NumberRangeColumnFilter({
  column: { filterValue = [], preFilteredRows, setFilter, id }
}) {
  const [min, max] = React.useMemo(() => {
    let min = preFilteredRows.length ? preFilteredRows[0].values[id] : 0;
    let max = preFilteredRows.length ? preFilteredRows[0].values[id] : 0;
    preFilteredRows.forEach((row) => {
      min = Math.min(row.values[id], min);
      max = Math.max(row.values[id], max);
    });
    return [min, max];
  }, [id, preFilteredRows]);

  return (
    <div
      style={{
        display: "flex"
      }}
    >
      <input
        value={filterValue[0] || ""}
        type="number"
        onChange={(e) => {
          const val = e.target.value;
          setFilter((old = []) => [
            val ? parseInt(val, 10) : undefined,
            old[1]
          ]);
        }}
        placeholder={`Min (${min})`}
        style={{
          width: "70px",
          marginRight: "0.5rem"
        }}
      />
      to
      <input
        value={filterValue[1] || ""}
        type="number"
        onChange={(e) => {
          const val = e.target.value;
          setFilter((old = []) => [
            old[0],
            val ? parseInt(val, 10) : undefined
          ]);
        }}
        placeholder={`Max (${max})`}
        style={{
          width: "70px",
          marginLeft: "0.5rem"
        }}
      />
    </div>
  );
}

function fuzzyTextFilterFn(rows, id, filterValue) {
  return matchSorter(rows, filterValue, { keys: [(row) => row.values[id]] });
}

// Let the table remove the filter if the string is empty
fuzzyTextFilterFn.autoRemove = (val) => !val;

// Be sure to pass our updateMyData and the skipReset option
function Table({ columns, data, updateMyData, skipReset, tableData, props}) {
  const filterTypes = React.useMemo(
    () => ({
      // Add a new fuzzyTextFilterFn filter type.
      fuzzyText: fuzzyTextFilterFn,
      // Or, override the default text filter to use
      // "startWith"
      text: (rows, id, filterValue) => {
        return rows.filter((row) => {
          const rowValue = row.values[id];
          return rowValue !== undefined
            ? String(rowValue)
                .toLowerCase()
                .startsWith(String(filterValue).toLowerCase())
            : true;
        });
      }
    }),
    []
  );

  const defaultColumn = React.useMemo(
    () => ({
      // Let's set up our default Filter UI
      Filter: DefaultColumnFilter,
      // And also our default editable cell
      Cell: EditableCell
    }),
    []
  );

  // Use the state and functions returned from useTable to build your UI
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page, // Instead of using 'rows', we'll use page,
    // which has only the rows for the active page

    // The rest of these things are super handy, too ;)
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: {
      pageIndex,
      pageSize,
      sortBy,
      groupBy,
      expanded,
      filters,
      selectedRowIds
    }
  } = useTable(
    {
      columns,
      data,
      defaultColumn,
      filterTypes,
      // updateMyData isn't part of the API, but
      // anything we put into these options will
      // automatically be available on the instance.
      // That way we can call this function from our
      // cell renderer!
      updateMyData,
      // We also need to pass this so the page doesn't change
      // when we edit the data.
      autoResetPage: !skipReset,
      autoResetSelectedRows: !skipReset,
      disableMultiSort: true
    },
    useFilters,
    useGroupBy,
    useSortBy,
    useExpanded,
    usePagination,
    useRowSelect,
    // Here we will use a plugin to add our selection column
    (hooks) => {
      hooks.visibleColumns.push((columns) => {
        return [
          {
            id: "selection",
            // Make this column a groupByBoundary. This ensures that groupBy columns
            // are placed after it
            groupByBoundary: true,
            // The header can use the table's getToggleAllRowsSelectedProps method
            // to render a checkbox
            Header: ({ getToggleAllRowsSelectedProps }) => (
              <div>
                <IndeterminateCheckbox {...getToggleAllRowsSelectedProps()} />
              </div>
            ),
            // The cell can use the individual row's getToggleRowSelectedProps method
            // to the render a checkbox
            Cell: ({ row }) => (
              <div>
                <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />
              </div>
            )
          },
          ...columns
        ];
      });
    }
  );

  // Render the UI for your table
  return (
    <>
      <table {...getTableProps()}>
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th {...column.getHeaderProps()}>
                  <div>
                    {column.canGroupBy ? (
                      // If the column can be grouped, let's add a toggle
                      <span {...column.getGroupByToggleProps()}>
                        {column.isGrouped ? "🛑 " : "👊 "}
                      </span>
                    ) : null}
                    <span {...column.getSortByToggleProps()}>
                      {column.render("Header")}
                      {/* Add a sort direction indicator */}
                      {column.isSorted
                        ? column.isSortedDesc
                          ? " 🔽"
                          : " 🔼"
                        : ""}
                    </span>
                  </div>
                  {/* Render the columns filter UI */}
                  <div>{column.canFilter ? column.render("Filter") : null}</div>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {page.map((row) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map((cell) => {
                  return (
                    <td {...cell.getCellProps()}>
                      {cell.isGrouped ? (
                        // If it's a grouped cell, add an expander and row count
                        <>
                          <span {...row.getToggleRowExpandedProps()}>
                            {row.isExpanded ? "👇" : "👉"}
                          </span>{" "}
                          {cell.render("Cell", { editable: false })} (
                          {row.subRows.length})
                        </>
                      ) : cell.isAggregated ? (
                        // If the cell is aggregated, use the Aggregated
                        // renderer for cell
                        cell.render("Aggregated")
                      ) : cell.isPlaceholder ? null : ( // For cells with repeated values, render null
                        // Otherwise, just render the regular cell
                        cell.render("Cell", { editable: true })
                      )}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
      {/*
        Pagination can be built however you'd like.
        This is just a very basic UI implementation:
      */}
      <div className="pagination">
        <button onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
          {"<<"}
        </button>{" "}
        <button onClick={() => previousPage()} disabled={!canPreviousPage}>
          {"<"}
        </button>{" "}
        <button onClick={() => nextPage()} disabled={!canNextPage}>
          {">"}
        </button>{" "}
        <button onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>
          {">>"}
        </button>{" "}
        <span>
          Page{" "}
          <strong>
            {pageIndex + 1} of {pageOptions.length}
          </strong>{" "}
        </span>
        <span>
          | Go to page:{" "}
          <input
            type="number"
            defaultValue={pageIndex + 1}
            onChange={(e) => {
              const page = e.target.value ? Number(e.target.value) - 1 : 0;
              gotoPage(page);
            }}
            style={{ width: "100px" }}
          />
        </span>{" "}
        <select
          value={pageSize}
          onChange={(e) => {
            setPageSize(Number(e.target.value));
          }}
        >
          {[10, 20, 30, 40, 50].map((pageSize) => (
            <option key={pageSize} value={pageSize}>
              Show {pageSize}
            </option>
          ))}
        </select>
      </div>
      <Button variant="success" onClick={()=>{
        
        let rowsToEdit = [];
        let originalRows = [];
        let rowCheck = true;
        if( !(Object.keys(selectedRowIds).length === 0)){
          let rowsAdded = 0;
          for(let row in selectedRowIds){
            if(JSON.stringify(data[row]) === JSON.stringify(props.tableData[row])){
              alert(`data in row ${parseInt(row) +1} has not changed, please select a row with changes or unselct row ${parseInt(row) +1}`);
              rowCheck = false;
            }
            else{
              rowsToEdit.push(data[row]);
            }
            rowsAdded++;
            if(data[row].last_modified !== props.tableData[row].last_modified){
              props.tableData[row].last_modified = data[row].last_modified;
            }
            if(data[row].last_modified_by !== props.tableData[row].last_modified_by){
              props.tableData[row].last_modified_by = data[row].last_modified_by;
            }
          }
          rowsAdded = 0;
          // console.log(rowsToEdit);
          // console.log(originalRows);
        }
        else{
          alert("Please select the checkbox next to the row(s) you would like to edit");
          rowCheck = false;    
        }

        if(rowCheck ===true){
          props.editLandscapingData(rowsToEdit);
        }

      }}  
      style = {{"marginRight": "20px", "marginBottom": "20px"}}
      disabled={props.saveButtonDisabled || props.saveButtonDisabledLoading}>
        {
          props.saveButtonDisabledLoading ? 
          <div>Saving
          <Spinner animation="border" size="sm" ></Spinner>
          </div> : 
          'Save'
        }
      </Button>
      <Button variant="danger" onClick={()=>{
        let rowsToEdit = [];
        let originalRows = [];
        let rowCheck = true;
        if( !(Object.keys(selectedRowIds).length === 0)){
          let rowsAdded = 0;
          for(let row in selectedRowIds){
            rowsToEdit.push(data[row]);
            rowsAdded++;
          }
          rowsAdded = 0;
        }
        else{
          alert("Please select the checkbox next to the row(s) you would like to edit");
          rowCheck = false;        
        }
        if(rowCheck ===true){
          props.deleteLandscapingData(rowsToEdit);
        }

      }}  
      style = {{"marginRight": "20px", "marginBottom": "20px"}}
      disabled={props.saveButtonDisabled || props.saveButtonDisabledLoading}>
        {
          props.saveButtonDisabledLoading ? 
          <div>Deleting
          <Spinner animation="border" size="sm" ></Spinner>
          </div> : 
          'Delete'
        }
      </Button>
      <pre>
        <code>
          {JSON.stringify(
            {
              pageIndex,
              pageSize,
              pageCount,
              canNextPage,
              canPreviousPage,
              sortBy,
              groupBy,
              expanded: expanded,
              filters,
              selectedRowIds: selectedRowIds
            },
            null,
            2
          )}
        </code>
      </pre>

    </>
  );
}

// Define a custom filter filter function!
function filterGreaterThan(rows, id, filterValue) {
  return rows.filter((row) => {
    const rowValue = row.values[id];
    return rowValue >= filterValue;
  });
}

// This is an autoRemove method on the filter function that
// when given the new filter value and returns true, the filter
// will be automatically removed. Normally this is just an undefined
// check, but here, we want to remove the filter if it's not a number
filterGreaterThan.autoRemove = (val) => typeof val !== "number";

// This is a custom aggregator that
// takes in an array of leaf values and
// returns the rounded median
function roundedMedian(leafValues) {
  let min = leafValues[0] || 0;
  let max = leafValues[0] || 0;

  leafValues.forEach((value) => {
    min = Math.min(min, value);
    max = Math.max(max, value);
  });

  return Math.round((min + max) / 2);
}

const IndeterminateCheckbox = React.forwardRef(
  ({ indeterminate, ...rest }, ref) => {
    const defaultRef = React.useRef();
    const resolvedRef = ref || defaultRef;

    React.useEffect(() => {
      resolvedRef.current.indeterminate = indeterminate;
    }, [resolvedRef, indeterminate]);

    return (
      <>
        <input type="checkbox" ref={resolvedRef} {...rest} />
      </>
    );
  }
);

function DataTable(props) {
  const columns = React.useMemo(
    () => [
      {
        Header: "Work Information",
        columns: [
          {
            Header: "Customer Name",
            accessor: "customer_name",
            // Use a two-stage aggregator here to first
            // count the total rows being aggregated,
            // then sum any of those counts if they are
            // aggregated further
            aggregate: "count",
            Aggregated: ({ value }) => `${value} Names`
          },
          {
            Header: "Notes",
            accessor: "notes",
            // Use a two-stage aggregator here to first
            // count the total rows being aggregated,
            // then sum any of those counts if they are
            // aggregated further
            aggregate: "count",
            Aggregated: ({ value }) => `${value} Names`
          },
          // {
          //   Header: "Expense Type",
          //   accessor: "expense_type",
          //   Filter: SelectColumnFilter,
          //   filter: "includes"
          // },
          {
            Header: "Crew",
            accessor: "crew",
            Filter: SelectColumnFilter,
            filter: "includes"
          },
          // {
          //   Header: "mowed",
          //   accessor: "mowed",
          //   Filter: SelectColumnFilter,
          //   filter: "includes"
          // },
          // {
          //   Header: "Weeded",
          //   accessor: "weeded",
          //   Filter: SelectColumnFilter,
          //   filter: "includes"
          // },
          {
            Header: "Number Of People on Job",
            accessor: "number_of_people_on_job",
            Filter: SelectColumnFilter,
            filter: "includes"
          },
          {
            Header: "Price Of Job",
            accessor: "price_of_job",
            Filter: NumberRangeColumnFilter,
            filter: "between",
            // Aggregate the sum of all visits
            aggregate: "sum",
            Aggregated: ({ value }) => `${value} (total)`
          },
          {
            Header: "Time Worked",
            accessor: "time_worked",
            Filter: NumberRangeColumnFilter,
            filter: "between",
            // Aggregate the sum of all visits
            aggregate: "sum",
            Aggregated: ({ value }) => `${value} (total)`
          },
          {
            Header: "Personal Rating",
            accessor: "personal_rating",
            Filter: SelectColumnFilter,
            filter: "includes"
          },
          {
            Header: "Client Checked Job",
            accessor: "client_checked_job",
            Filter: SelectColumnFilter,
            filter: "includes"
          },
        //   {
        //     Header: "Last Name",
        //     accessor: "lastName",
        //     // Use our custom `fuzzyText` filter on this column
        //     filter: "fuzzyText",
        //     // Use another two-stage aggregator here to
        //     // first count the UNIQUE values from the rows
        //     // being aggregated, then sum those counts if
        //     // they are aggregated further
        //     aggregate: "uniqueCount",
        //     Aggregated: ({ value }) => `${value} Unique Names`
        //   },
        //   {
        //     Header: "Crew",
        //     accessor: "status1",
        //     Filter: SelectColumnFilter,
        //     filter: "includes"
        //   },
        ]
      },
      {
        Header: "Work Data Info",
        columns: [
            {
                Header: "Timestamp",
                accessor: "timestamp",
                // Use a two-stage aggregator here to first
                // count the total rows being aggregated,
                // then sum any of those counts if they are
                // aggregated further
                aggregate: "count",
                Aggregated: ({ value }) => `${value} Names`
              },
              {
                Header: "Job Submitted By",
                accessor: "job_submitted_by",
                // Use a two-stage aggregator here to first
                // count the total rows being aggregated,
                // then sum any of those counts if they are
                // aggregated further
                aggregate: "count",
                Aggregated: ({ value }) => `${value} Names`
              },
              {
                Header: "Last Modified",
                accessor: "last_modified",
                // Use a two-stage aggregator here to first
                // count the total rows being aggregated,
                // then sum any of those counts if they are
                // aggregated further
                aggregate: "count",
                Aggregated: ({ value }) => `${value} Names`
              },
              {
                Header: "Last Modified By",
                accessor: "last_modified_by",
                // Use a two-stage aggregator here to first
                // count the total rows being aggregated,
                // then sum any of those counts if they are
                // aggregated further
                aggregate: "count",
                Aggregated: ({ value }) => `${value} Names`
              },
        //   {
        //     Header: "Age",
        //     accessor: "age",
        //     Filter: SliderColumnFilter,
        //     filter: "equals",
        //     // Aggregate the average age of visitors
        //     aggregate: "average",
        //     Aggregated: ({ value }) => `${value} (avg)`
        //   },
        //   {
        //     Header: "Visits",
        //     accessor: "visits",
        //     Filter: NumberRangeColumnFilter,
        //     filter: "between",
        //     // Aggregate the sum of all visits
        //     aggregate: "sum",
        //     Aggregated: ({ value }) => `${value} (total)`
        //   },
        //   {
        //     Header: "Status",
        //     accessor: "status",
        //     Filter: SelectColumnFilter,
        //     filter: "includes"
        //   },
        //   {
        //     Header: "Profile Progress",
        //     accessor: "progress",
        //     Filter: SliderColumnFilter,
        //     filter: filterGreaterThan,
        //     // Use our custom roundedMedian aggregator
        //     aggregate: roundedMedian,
        //     Aggregated: ({ value }) => `${value} (med)`
        //   }
        ]
      }
    ],
    []
  );

  const [data, setData] = React.useState(props.tableData);
  const [originalData] = React.useState(data);

  // We need to keep the table from resetting the pageIndex when we
  // Update data. So we can keep track of that flag with a ref.
  const skipResetRef = React.useRef(false);

  // When our cell renderer calls updateMyData, we'll use
  // the rowIndex, columnId and new value to update the
  // original data
  const updateMyData = (rowIndex, columnId, value) => {
    // We also turn on the flag to not reset the page
    if(props.saveButtonDisabled === true){
      props.updateSaveButton(false);
    }
    skipResetRef.current = true;
    setData((old) =>
      old.map((row, index) => {
        if (index === rowIndex) {
          return {
            ...row,
            [columnId]: value
          };
        }
        return row;
      })
    );
  };

  // After data changes, we turn the flag back off
  // so that if data actually changes when we're not
  // editing it, the page is reset
  React.useEffect(() => {
    skipResetRef.current = false;
  }, [data]);

  // Let's add a data resetter/randomizer to help
  // illustrate that flow...
  const resetData = () => {
    if(props.saveButtonDisabled === false){
      props.updateSaveButton(true);
    }
    // Don't reset the page when we do this
    skipResetRef.current = true;
    setData(originalData);
  };

  return (
    <Styles>
      <Button variant="outline-primary" onClick={resetData}>Undo Table Changes</Button>
      <Table
        columns={columns}
        data={data}
        updateMyData={updateMyData}
        skipReset={skipResetRef.current}
        props={props}
      />
    </Styles>
  );
}

export default DataTable;
