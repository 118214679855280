const getTableSettings = async (tableName, userName, accessToken) =>{
    try {
        const { REACT_APP_SERVER_URL } = process.env;
        const { REACT_APP_SERVER_PORT } = process.env;
        let serverPort = REACT_APP_SERVER_PORT
        // let userInfo = await this.props.oktaAuth.token.getUserInfo();
        // this.setState({ userInfo: userInfo });

        const response = await fetch(`${REACT_APP_SERVER_URL}/TableSetup/getTableSetup/`, {
          method: 'POST',
          headers: {
              'Content-Type': 'application/json',
              Authorization: 'Bearer ' + accessToken,
              'data-request': 'read'
          },
          body: JSON.stringify({
              // Validation data coming from a form usually
              userName: userName,
              tableName: tableName
          }) 
        })
        if(response.status === 401){
          throw new Error("Your login time has expired, please log in again");
        }
        let columnsToHideResponse = await response.json();
        columnsToHideResponse = columnsToHideResponse[0].columns_to_hide;
        let columnsToHide = [];

        for(let column of columnsToHideResponse){
          columnsToHide.push(column.replaceAll("\'", ""));
        }
        return columnsToHide;
      } catch (error) {
        // handle error as needed
        console.log(error);
      }
  }
  export default getTableSettings;