// src/Home.js

import React, { Component } from 'react';
import { withOktaAuth } from '@okta/okta-react';
import {Button} from 'react-bootstrap'

export default withOktaAuth(class Home extends Component {




constructor(props) {
    super(props);
    this.login = this.login.bind(this);
    this.logout = this.logout.bind(this);
    this.state = { data: [] };
  }

  async login() {
    this.props.oktaAuth.signInWithRedirect();
  }

  async logout() {     
    this.props.oktaAuth.signOut('/');
  }

  async componentDidMount() {
        let userInfo = await this.props.oktaAuth.token.getUserInfo();
        this.setState({ data: userInfo });
  }

  render() {
    if (!this.props.authState) return <div>Loading...</div>;

    if(this.props.authState.isAuthenticated){  

    }
    return <div>
            <h1>This Page doesn't exist</h1> 
            <h3>Click home or use the back arrows to go to the previous page</h3>
          </div>
    //this.props.authState.isAuthenticated ?
    //     <div>
    //         <h1>hello {this.state.data.name}</h1>
    //         <button onClick={this.logout}>Logout</button>
    //     </div> :
    //     <div>
    //         <h1>Please Login</h1>
    //         <Button variant="success" onClick={this.login} size="lg">Login</Button>        
    //     </div>
        
      
  }
});
